import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, provide } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionComponent',
  props: {
    radio: { type: Boolean, required: true },
    defaultIndex: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const count = ref(0);
const activeRow = ref(props.defaultIndex);

const isRadio = computed(() => props.radio || false)

provide('AccordionComponent', {
  count,
  isRadio,
  activeRow,
})


const __returned__ = { props, count, activeRow, isRadio }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})