import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, Ref, inject, onUnmounted, onMounted } from 'vue'
import DropdownComponent, { type OptionProps } from './DropdownComponent.vue';
import FormItem from './FormItem.vue'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputDropdown',
  props: {
    modelValue: { type: null, required: true },
    options: { type: Array, required: true },
    required: { type: Boolean, required: false },
    placeholder: { type: String, required: false },
    searchPlaceholder: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    color: { type: String, required: false },
    backgroundColor: { type: String, required: false },
    selectedColor: { type: String, required: false }
  },
  emits: ["update:modelValue", "change", "focus"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const { addFormItem, removeFormItem } = inject('FormComponent', () => {}) as any

const props = __props;

const visited = ref(false)
const forcedValidation = ref(false)
const errorMessage = ref('')
const focused = ref(false)
const itemId = ref(null)
const dropdownComponentRef: Ref<HTMLInputElement | null> = ref(null)

// define emits
const emit = __emit;

onMounted(() => {
  if (addFormItem) {
    itemId.value = addFormItem({
      validate: () => {
        forcedValidation.value = true
        return validate()
      }
    })
  }
})

onUnmounted(() => {
  if (removeFormItem) {
    removeFormItem(itemId.value)
  }
})

// define proxyValue for modelValue to emit update:modelValue
const value: ComputedRef<T | null> = computed(() => props.modelValue);
const isRequired: ComputedRef<boolean> = computed(() => props.required);

const options = computed(() => {
    return props.options
})
const color: ComputedRef<string> = computed(() => props.color || '#333');
const backgroundColor: ComputedRef<string> = computed(() => props.backgroundColor || '#fff');
const selectedColor: ComputedRef<string> = computed(() => props.selectedColor || '#00867d');
const searchPlaceholder: ComputedRef<string> = computed(() => props.searchPlaceholder || '');
const placeholder: ComputedRef<string> = computed(() => props.placeholder || '');
const hasValue: ComputedRef<boolean> = computed(() => !(value.value == undefined || value.value == null));

function onChange(option: T | null) {
    emit("update:modelValue", option);
    emit("change", option);
    setTimeout(validate, 0)
}

function validate () {
  errorMessage.value = ''
  if (!value.value && isRequired.value) {
    errorMessage.value += t('ThisFieldIsRequired')
  }
  return !errorMessage.value
}

function onBlur() {
  visited.value = true
  focused.value = false
  validate()
}

function onFocus(event: FocusEvent) {
  focused.value = true
  emit("focus", event);
}

__expose({
  validate: () => {
    forcedValidation.value = true
    validate()
  }
})

function handleFormItemClick() {
  focus()
}

function focus() {
  emit("focus", new FocusEvent('focus'));
  dropdownComponentRef.value?.focus()
}


const __returned__ = { t, addFormItem, removeFormItem, props, visited, forcedValidation, errorMessage, focused, itemId, dropdownComponentRef, emit, value, isRequired, options, color, backgroundColor, selectedColor, searchPlaceholder, placeholder, hasValue, onChange, validate, onBlur, onFocus, handleFormItemClick, focus, DropdownComponent, FormItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})