import { defineComponent as _defineComponent } from 'vue'
import { ComputedRef, computed, ref, Ref } from 'vue';
import { useToggle } from '@vueuse/core'

export type ColorStyle = {
  primaryColor: string;
  secondaryColor: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogComponent',
  props: {
    dialogColorStyle: { type: Object, required: false },
    buttonColorStyle: { type: Object, required: false },
    title: { type: String, required: false },
    closeButtonLabel: { type: String, required: false },
    hideHeader: { type: Boolean, required: false },
    hideFooter: { type: Boolean, required: false },
    width: { type: String, required: false }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const [value, toggle] = useToggle()

const dialogId = ref('')
const numberOfOpenDialogs = ref(0)

// define emits
const emit = __emit;

const props = __props;

const defaultColorStyle = {
  primaryColor: '#333',
  secondaryColor: '#eee',
}

const closeButtonLabel: ComputedRef<string> = computed(() => props.closeButtonLabel || 'Close');
const title: ComputedRef<string> = computed(() => props.title || '');
const dialogColorStyle: ComputedRef<ColorStyle> = computed(() => props.dialogColorStyle || defaultColorStyle);
const buttonColorStyle: ComputedRef<ColorStyle> = computed(() => props.buttonColorStyle || defaultColorStyle);
const hideHeader: ComputedRef<boolean> = computed(() => props.hideHeader );
const hideFooter: ComputedRef<boolean> = computed(() => props.hideFooter );
const width: ComputedRef<string> = computed(() => props.width || '400px');

function onClose () {
    toggle(false)
    emit('close', dialogId.value)
}


__expose({
  open: (id: string) => {
    dialogId.value = id
    numberOfOpenDialogs.value = document.querySelectorAll('.dialog-component-popup-glass').length
    console.log('Dialogs: ', numberOfOpenDialogs.value)
    toggle(true)
  },
  close: () => {
    toggle(false)
  }
})

const __returned__ = { value, toggle, dialogId, numberOfOpenDialogs, emit, props, defaultColorStyle, closeButtonLabel, title, dialogColorStyle, buttonColorStyle, hideHeader, hideFooter, width, onClose }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})