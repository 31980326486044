<script setup leng="ts">
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import PotsLoader from '@/design-system/pots-loader.vue';
import { useEventStore } from '@/store/event-store';
import { useEventBookingStore } from '@/store/booking-store';
import { useUserStore } from '@/store/user-store';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const parseMessage = (code) => {
  try {
    const message = atob(code); // decrypt message
    return message.replace(/<br>/g, '');
  } catch (error) {
    console.log(error);
    return 'Failed to decode the message';
  }
};

const loading = ref(false);
const eventStore = useEventStore();
const eventBookingStore = useEventBookingStore();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();

const isSuccess = computed(() => {
  return route.query?.m || eventBookingStore.getPaymentResponse?.responseCode == 200;
});

const successMessage = computed(() => {
  return route.query?.m ? parseMessage(route.query?.m) : '';
});

const errorMessage = computed(() => {
  return route.query?.e ? parseMessage(route.query?.e) : '';
});

const qrCode = computed(() => {
  return eventStore.event?.extendedSchedule?.ticket?.ticketQRCode || null;
});

const styleObject = computed(() => {
  return {
    border: `1px solid ${eventStore.eventStyle.selectedTXT}`,
  };
});

const messageLogoUrl = computed(() => {
  if (isSuccess.value) {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2FAccepted.png?alt=media&token=f1879927-3735-4d09-87b8-3871d3c6a695';
  } else {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2FRejected.png?alt=media&token=eaf6beab-1328-416f-9d41-ce1a3ad5c5bb';
  }
});

const PIC_URL = computed(() => {
  if (isSuccess.value) {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2Fsuccess_payment.png?alt=media&token=456aaa9b-c762-4592-a679-a5301510e051';
  } else {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2Ffail_payment.png?alt=media&token=7774170a-e597-43c8-a5fd-89bbe2467ee6';
  }
});

const ICON_URL = computed(() => {
  if (isSuccess.value) {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2Fapproval-256.png?alt=media&token=0888997a-7b65-4585-ac6e-fea649f3718c';
  } else {
    return 'https://firebasestorage.googleapis.com/v0/b/points-of-tango.appspot.com/o/resources%2Fcancel-256.png?alt=media&token=604b2e7a-4f3f-4332-a12b-d2417905bfee';
  }
});

const isEmbeddedEventPage = computed(() => {
  return router.currentRoute.value.name?.toLowerCase().startsWith('embedded');
});

const isEmbeddedMode = computed(() => {
  return isEmbeddedEventPage.value && eventStore.isEmbeddedEventMode;
});

const responseCodeStyle = computed(() => {
  return [
    eventBookingStore.getPaymentResponse?.responseCode == 200
      ? { color: '#47b4aa', 'text-align': 'center', 'font-size': '170%' }
      : '',
    eventBookingStore.getPaymentResponse?.responseCode == 400
      ? { color: '#cc0000', 'text-align': 'center', 'font-size': '100%' }
      : '',
  ];
});

const continueButtonHandler = () => {
  if (!isEmbeddedMode.value) {
    router.push({ path: '/event/' + eventStore.eventUrlId });
  }
};

onMounted(async () => {
  window.scrollTo(0, 0);
  loading.value = true;
  const response = await eventStore.reloadEvent();
  console.log('Reloaded event: ', response);
  if (isSuccess.value) {
    eventBookingStore.endSession();
    if (userStore.isGuestUser) {
      userStore.logout();
    }
  }
  loading.value = false;
});
</script>
<template>
    <PotsLoader v-if="loading" />
    <div v-else>
      <div class="product-details-section" :style="styleObject">
        <div>
          <img class="successOrErrorclass" :src="messageLogoUrl" alt="Icon" width="45" height="45" />
          <span :style="responseCodeStyle">
            <span v-if="eventBookingStore.getPaymentResponse?.responseCode == 200 && ['DIRECT', 'WU', 'LINK', 'BANK'].includes(eventBookingStore.selectedPaymentType)" :style="{ color: 'green' }">
              {{ t('payment.your_payment_status_is_pending') }}
            </span>
            <span v-if="successMessage" :style="{ color: eventStore.eventStyle.bodyTXT }">{{ successMessage }}</span>
            <span v-if="isSuccess && !successMessage" :style="{ color: eventStore.eventStyle.bodyTXT }">
              {{ t('payment.thank_you_see_you_at_the_event') }}
            </span>
            <span v-if="errorMessage" :style="{ color: eventStore.eventStyle.bodyTXT }">{{ errorMessage }}</span>
          </span>
          <div class="image-container">
            <img style="display: block;" :src="PIC_URL" alt="Success" width="260" height="300" />
            <qrcode-vue v-if="qrCode" :value="qrCode" :size="260" level="H" />
          </div>
          <div class="user-payment-btn" :style="{ background: eventStore.eventStyle.buttonBG, 'margin-top': '1rem' }">
            <img :src="ICON_URL" alt="Icon" width="25" height="25" />
            <button v-if="!isEmbeddedMode" @click="continueButtonHandler" class="payment-continue-btn">
              {{ t('checkout.continue') }}
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
.image-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    align-items: center;
}

.successOrErrorclass {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.payment-continue-btn {
  display: inline-block;
  /* background: #4db6ac; */
  background: transparent;
  color: #ffffff;
  font-family: helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 120%;
  margin: 0;
  text-decoration: none;
  text-transform: none;
  padding: 12px 10px 12px 5px;
  border-radius: 0px;
  border: transparent;
}

.user-payment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  margin: 0 auto;
  border: 2px solid #fff;
  border-radius: 6px;
}

.product-details-section {
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-details-section span {
  text-align: center;
  display: block;
  padding: 10px 0 10px 0;
}

@media (max-width: 575px) {
  .product-details-section {
    padding: 1rem;
    margin: 1rem 0px;
  }
}

@media (max-width: 900px) {
    .image-container {
        flex-direction: column;
    }
}
</style>