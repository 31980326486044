import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app-px-5" }
const _hoisted_2 = { class: "navbar navbar-expand-lg" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "navbar-nav ms-auto mb-2 mb-lg-0 profile-dropdown" }
const _hoisted_6 = { class: "nav-item dropdown hide-on-mobile" }
const _hoisted_7 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  class: "dropdown-item",
  "aria-current": "page",
  href: "https://points-of-tango.web.app/register-page",
  target: "_blank"
}
const _hoisted_10 = {
  class: "dropdown-item",
  "aria-current": "page",
  href: "https://points-of-tango.web.app/register-store",
  target: "_blank"
}
const _hoisted_11 = { class: "h-stack" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "text-white" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["src"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["src"]
const _hoisted_18 = { key: 2 }
const _hoisted_19 = ["src"]
const _hoisted_20 = { key: 3 }
const _hoisted_21 = ["src"]
const _hoisted_22 = { key: 4 }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 0,
  class: "language-list-box languageClass"
}
const _hoisted_25 = ["src"]
const _hoisted_26 = ["src"]
const _hoisted_27 = ["src"]
const _hoisted_28 = ["src"]
const _hoisted_29 = {
  key: 1,
  class: "mr-2"
}
const _hoisted_30 = { class: "mr-2" }
const _hoisted_31 = { class: "textSignup" }
const _hoisted_32 = {
  key: 2,
  class: "dropdown me-1 profile-dropdown"
}
const _hoisted_33 = { key: 0 }
const _hoisted_34 = ["src"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = {
  key: 0,
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuOffset"
}
const _hoisted_37 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "header-one",
      style: _normalizeStyle({
        position: $setup.isEventPageOpened ? 'relative' : 'sticky',
        top: '0px',
        zIndex: 999,
        background: $setup.isEventPageOpened ? '#fff' : '#fffe',
        backdropFilter: 'blur(5px)'
      })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createCommentVNode(" LOGO "),
            _createVNode(_component_router_link, {
              class: "navbar-brand",
              to: "/"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "logo-css",
                  src: require('@/assets/logos/main_logo.png'),
                  alt: "Main logo",
                  sizes: "(max-width: 768px) 100vw, 400px",
                  loading: "lazy"
                }, null, 8 /* PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" end "),
            _createCommentVNode(" create drop-down "),
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("a", {
                  class: "create-button dropdown-toggle",
                  href: "#",
                  role: "button",
                  "data-bs-toggle": "dropdown",
                  "aria-expanded": "false",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleCreateEventDropdown()))
                }, _toDisplayString($setup.t('Create')), 1 /* TEXT */),
                ($setup.createEventMenuOpen)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item",
                          "aria-current": "page",
                          href: $setup.mainStore.getRegistrationLink,
                          target: "_blank"
                        }, _toDisplayString($setup.t('createEvent')), 9 /* TEXT, PROPS */, _hoisted_8)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", _hoisted_9, _toDisplayString($setup.t('createProfProfile')), 1 /* TEXT */)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", _hoisted_10, _toDisplayString($setup.t('createStore')), 1 /* TEXT */)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _createCommentVNode("end"),
            _createElementVNode("div", _hoisted_11, [
              ($setup.cartStore.getItems.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "cart-number",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.cartDialog?.open()))
                  }, [
                    _cache[11] || (_cache[11] = _createElementVNode("button", { class: "checkout-upper-btn" }, [
                      _createElementVNode("i", { class: "fa-solid fa-cart-shopping" })
                    ], -1 /* HOISTED */)),
                    ($setup.cartStore.getItems.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString($setup.cartStore.getItems.length), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode("lang code"),
              _createElementVNode("div", {
                class: "language-btn cursor-pointer",
                onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.toggleLanguageDropdown()))
              }, [
                _createCommentVNode(" <i class=\"fa-solid fa-language\"></i> "),
                _createElementVNode("div", null, [
                  (($setup.mainStore.languageSelected === 'en') || ($setup.mainStore.languageSelected === 'en-US'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("img", {
                          src: require(`@/assets/flags/flag_united_kingdom.png`),
                          alt: "",
                          class: "langauge-btn-img"
                        }, null, 8 /* PROPS */, _hoisted_15)
                      ]))
                    : (($setup.mainStore.languageSelected === 'it') || ($setup.mainStore.languageSelected === 'it-CH'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("img", {
                            src: require(`@/assets/flags/flag_italy.png`),
                            alt: "",
                            class: "langauge-btn-img"
                          }, null, 8 /* PROPS */, _hoisted_17)
                        ]))
                      : ($setup.mainStore.languageSelected === 'es')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createElementVNode("img", {
                              src: require(`@/assets/flags/flag_argentina.png`),
                              alt: "",
                              class: "langauge-btn-img"
                            }, null, 8 /* PROPS */, _hoisted_19)
                          ]))
                        : ($setup.mainStore.languageSelected === 'ru')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                              _createElementVNode("img", {
                                src: require(`@/assets/flags/flag_russia.png`),
                                alt: "",
                                class: "langauge-btn-img"
                              }, null, 8 /* PROPS */, _hoisted_21)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                              _createCommentVNode(" <i class=\"fa-solid fa-language\"></i> "),
                              _createElementVNode("img", {
                                src: require(`@/assets/flags/flag_united_kingdom.png`),
                                alt: "",
                                class: "langauge-btn-img"
                              }, null, 8 /* PROPS */, _hoisted_23)
                            ]))
                ]),
                ($setup.languageOptionsOpen)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_24, [
                      _createElementVNode("li", {
                        style: _normalizeStyle($setup.i18nLocale == 'en' ? $setup.activeClassObjectLandingPage : ''),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.mainStore.changeLanguage('en')))
                      }, [
                        _createElementVNode("img", {
                          src: require(`@/assets/flags/flag_united_kingdom.png`),
                          alt: "",
                          style: {"height":"30px","width":"30px"}
                        }, null, 8 /* PROPS */, _hoisted_25),
                        _cache[12] || (_cache[12] = _createElementVNode("a", {
                          style: {"color":"#000"},
                          href: "#",
                          value: "en"
                        }, " English", -1 /* HOISTED */))
                      ], 4 /* STYLE */),
                      _createElementVNode("li", {
                        style: _normalizeStyle($setup.i18nLocale == 'it' ? $setup.activeClassObjectLandingPage : ''),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.mainStore.changeLanguage('it')))
                      }, [
                        _createElementVNode("img", {
                          src: require(`@/assets/flags/flag_italy.png`),
                          alt: "",
                          style: {"height":"30px","width":"30px"}
                        }, null, 8 /* PROPS */, _hoisted_26),
                        _cache[13] || (_cache[13] = _createElementVNode("a", {
                          style: {"color":"#000"},
                          href: "#",
                          value: "it"
                        }, " Italiano", -1 /* HOISTED */))
                      ], 4 /* STYLE */),
                      _createElementVNode("li", {
                        style: _normalizeStyle($setup.i18nLocale == 'es' ? $setup.activeClassObjectLandingPage : ''),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.mainStore.changeLanguage('es')))
                      }, [
                        _createElementVNode("img", {
                          src: require(`@/assets/flags/flag_argentina.png`),
                          alt: "",
                          style: {"height":"30px","width":"30px"}
                        }, null, 8 /* PROPS */, _hoisted_27),
                        _cache[14] || (_cache[14] = _createElementVNode("a", {
                          style: {"color":"#000"},
                          href: "#",
                          value: "it"
                        }, " Español", -1 /* HOISTED */))
                      ], 4 /* STYLE */),
                      _createElementVNode("li", {
                        style: _normalizeStyle($setup.i18nLocale == 'ru' ? $setup.activeClassObjectLandingPage : ''),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.mainStore.changeLanguage('ru')))
                      }, [
                        _createElementVNode("img", {
                          src: require(`@/assets/flags/flag_russia.png`),
                          alt: "",
                          style: {"height":"30px","width":"30px"}
                        }, null, 8 /* PROPS */, _hoisted_28),
                        _cache[15] || (_cache[15] = _createElementVNode("a", {
                          style: {"color":"#000"},
                          href: "#",
                          value: "ru"
                        }, " Русский", -1 /* HOISTED */))
                      ], 4 /* STYLE */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _createCommentVNode("end"),
              _createCommentVNode("sing up code"),
              (!$setup.userStore.isLoggedIn || $setup.userStore.isGuestUser)
                ? (_openBlock(), _createElementBlock("span", _hoisted_29, [
                    _createElementVNode("span", {
                      class: "cursor-pointer signup-button",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.openSignUp()))
                    }, _toDisplayString($setup.t('sign_up')), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode("sing up code"),
              _createElementVNode("span", _hoisted_30, [
                (!$setup.userStore.isLoggedIn)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-sign-in navigation-btn-sign-in sign-up-border-class bg-primary",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.openSignIn()))
                    }, [
                      _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fa-solid fa-arrow-right-to-bracket" }, null, -1 /* HOISTED */)),
                      _createElementVNode("span", _hoisted_31, _toDisplayString($setup.t('sign_in')), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              ($setup.userStore.isLoggedIn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("button", {
                      id: "dropdownMenuOffset",
                      type: "button",
                      class: "select-content btn btn-secondary dropdown-toggle",
                      "aria-expanded": "false",
                      "aria-label": "profile-drop-down",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.toogleProfileMenu()))
                    }, [
                      ($setup.userStore.user.photoUrl)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                            _createElementVNode("img", {
                              class: "profilePic",
                              src: $setup.userStore.user.photoUrl
                            }, null, 8 /* PROPS */, _hoisted_34)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_35, _cache[17] || (_cache[17] = [
                            _createElementVNode("i", {
                              class: "fa-solid fa-user",
                              style: {"font-size":"24px"}
                            }, null, -1 /* HOISTED */)
                          ])))
                    ]),
                    ($setup.profileMenuOpen)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_36, [
                          _createElementVNode("li", null, [
                            ($setup.userStore.isLoggedIn)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                                  _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fa-solid fa-user" }, null, -1 /* HOISTED */)),
                                  _createTextVNode(" " + _toDisplayString($setup.userStore.user.fullName || $setup.userStore.user.email), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _createElementVNode("li", null, [
                            _createElementVNode("span", null, [
                              _cache[19] || (_cache[19] = _createElementVNode("i", {
                                class: "fa fa-heart",
                                "aria-hidden": "true"
                              }, null, -1 /* HOISTED */)),
                              _createCommentVNode(" <router-link :to=\"{ path: '/favorites' }\" target=\"_blank\">{{ t('favorites')}}</router-link> "),
                              _createTextVNode(" " + _toDisplayString($setup.t('favorites')), 1 /* TEXT */)
                            ])
                          ]),
                          _createElementVNode("li", null, [
                            _createElementVNode("span", null, [
                              _createElementVNode("button", {
                                onClick: _cache[10] || (_cache[10] = ($event: any) => ($setup.logout()))
                              }, [
                                _cache[20] || (_cache[20] = _createElementVNode("i", {
                                  class: "fa fa-sign-out",
                                  "aria-hidden": "true"
                                }, null, -1 /* HOISTED */)),
                                _createTextVNode(" " + _toDisplayString($setup.t('user_profile.sign_out')), 1 /* TEXT */)
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true),
              _cache[21] || (_cache[21] = _createElementVNode("button", {
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": "#navbarContent",
                "aria-controls": "navbarContent",
                "aria-expanded": "false",
                "aria-label": "Toggle navigation",
                style: {"background-color":"transparent","border":"none"}
              }, [
                _createElementVNode("span", { class: "navbar-toggler-icon" })
              ], -1 /* HOISTED */))
            ])
          ])
        ])
      ])
    ], 4 /* STYLE */),
    _createVNode($setup["DialogComponent"], {
      ref: "loginModalDialog",
      "hide-footer": true,
      "hide-header": true,
      "dialog-color-style": {
          primaryColor: $setup.colours.colours.bodyTXT,
          secondaryColor: $setup.colours.colours.background,
      },
      "button-color-style": {
          primaryColor: $setup.colours.colours.buttonTXT,
          secondaryColor: $setup.colours.colours.buttonBG,
      },
      width: '800px'
    }, {
      default: _withCtx(() => [
        _createVNode($setup["SignUpLoginForm"], {
          "is-popup": true,
          "show-sign-up": $setup.showSignUpInModal,
          onClose: $setup.modalToggleClose
        }, null, 8 /* PROPS */, ["show-sign-up"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["dialog-color-style", "button-color-style"]),
    _createVNode($setup["DialogComponent"], {
      ref: "cartDialog",
      title: $setup.t('checkout.cart'),
      "hide-footer": true,
      "dialog-color-style": {
          primaryColor: $setup.eventStore.eventStyle.bodyTXT,
          secondaryColor: $setup.eventStore.eventStyle.bodyBG,
      },
      "button-color-style": {
          primaryColor: $setup.eventStore.eventStyle.buttonTXT,
          secondaryColor: $setup.eventStore.eventStyle.buttonBG,
      }
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ShoppingCart"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "dialog-color-style", "button-color-style"])
  ], 64 /* STABLE_FRAGMENT */))
}