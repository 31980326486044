<script setup>
    import VLazyImage from 'v-lazy-image'
    import { computed, onMounted, ref } from 'vue';

    const props = defineProps({
        image: {
            type: String,
            required: false,
            default: () => {
              return 'https://as1.ftcdn.net/v2/jpg/04/34/72/82/1000_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg'
            }
        },
        title: {
            type: String,
            required: false,
            default: () => {
              return 'Card image'
            }
        },
    });

    const image = computed(() => props.image)
    const title = computed(() => props.title)

    const showImage = ref(false)

    onMounted(() => {
      setTimeout(() => {
        showImage.value = true
      }, Math.random() * (300 - 100) + 100)
    })
</script>

<template>
  <Transition>
    <div v-show="showImage" class="image-container">
      <v-lazy-image :srcset="`${image} 1x, ${image} 2x`" :src="image" :alt="title" class="fade show v-lazyImg" />
      <v-lazy-image :srcset="`${image} 1x, ${image} 2x`" :src="image" :alt="title" class="v-lazyImg-back" />
      <div class="blur-filter"></div>
    </div>
  </Transition>
</template>
<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  height: 200px;
  overflow: hidden;
}

.v-lazyImg {
    aspect-ratio: calc(16/9); 
    width: 100%; 
    object-fit: scale-down;
    z-index: 4;
    background-color: transparent;
    height: 200px;
}


.v-lazyImg-back {
    aspect-ratio: calc(16/9);
    width: 100%;
    object-fit: cover;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 2;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    height: 200px;
}

.blur-filter {
    backdrop-filter: blur(10px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    overflow: hidden;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from, .v-leave-to {
  opacity: 0
}
</style>