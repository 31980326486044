import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.eventBookingStore.isLoading)
    ? (_openBlock(), _createBlock($setup["PotsLoader"], { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.eventStore.isBookingEnabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["register-stepper", { 'register-stepper-embedded': $setup.eventStore.isEmbeddedEventMode }]),
              style: _normalizeStyle({ '--line-color': $setup.eventStore.eventStyle.headerBG })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventBookingStore.getBookingSteps, (step, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "step-container"
                }, [
                  _createElementVNode("div", {
                    class: "step-number",
                    style: _normalizeStyle([index <= $setup.eventBookingStore.currentBookingStep ? $setup.activeStepStyle : $setup.stepStyle])
                  }, _toDisplayString(index + 1), 5 /* TEXT, STYLE */),
                  _createElementVNode("div", {
                    class: "step-label",
                    style: _normalizeStyle([index <= $setup.eventBookingStore.currentBookingStep ? $setup.activeStepLabelTextStyle : $setup.stepLabelTextStyle])
                  }, _toDisplayString($setup.t(step.label)), 5 /* TEXT, STYLE */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 6 /* CLASS, STYLE */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          ($setup.eventBookingStore.getBookingSteps.length)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.eventBookingStore.getBookingSteps[$setup.eventBookingStore.currentBookingStep].component), { key: 0 }))
            : _createCommentVNode("v-if", true)
        ])
      ]))
}