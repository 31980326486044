import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "event-packs-container" }
const _hoisted_2 = { class: "event-pack-item" }
const _hoisted_3 = { class: "event-pack-item-info" }
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = { class: "packed-class-section" }
const _hoisted_7 = { class: "product-pack-information-container" }
const _hoisted_8 = { class: "product-pack-title" }
const _hoisted_9 = {
  key: 0,
  class: "sold_badge"
}
const _hoisted_10 = {
  key: 1,
  class: "packed_class"
}
const _hoisted_11 = { class: "product-pack-description product-pack-description--fixed-height" }
const _hoisted_12 = { class: "description-footer" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "product-pack-description" }
const _hoisted_15 = { class: "event-pack-item-price" }
const _hoisted_16 = { class: "price" }
const _hoisted_17 = {
  key: 0,
  class: "btn-show"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 0,
  class: "quantity-selector"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["value"]
const _hoisted_22 = ["disabled", "onClick"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  key: 0,
  class: "event-pack-potentials"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = {
  key: 0,
  class: "error"
}
const _hoisted_28 = {
  class: "modal-body potentials-container",
  style: {"height":"calc(70vh)","overflow":"auto"}
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["src", "onClick"]
const _hoisted_31 = { class: "potential-item-content" }
const _hoisted_32 = { class: "potential-item-title" }
const _hoisted_33 = { class: "potential-item-date" }
const _hoisted_34 = {
  class: "modal-footer",
  style: {"flex-direction":"column"}
}
const _hoisted_35 = {
  key: 0,
  class: "limit-badge-container"
}
const _hoisted_36 = { class: "limit-badge-container" }
const _hoisted_37 = {
  key: 1,
  class: "limit-badge-container"
}
const _hoisted_38 = { style: {"display":"flex","justify-content":"space-between","width":"100%"} }
const _hoisted_39 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createCommentVNode("new design code"),
      _createElementVNode("div", _hoisted_1, [
        _createCommentVNode(" main booking item show "),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from($setup.eventBookingStore.getEventGroupBySection.keys()), (section, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "event-packs-section-container"
          }, [
            section
              ? (_openBlock(), _createElementBlock("h4", {
                  key: 0,
                  class: "section-name",
                  style: _normalizeStyle($setup.activityStyle)
                }, _toDisplayString(section), 5 /* TEXT, STYLE */))
              : _createCommentVNode("v-if", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventBookingStore.getEventGroupBySection.get(section), (eventpack) => {
              return (_openBlock(), _createElementBlock("div", {
                key: eventpack.id,
                class: "event-pack-container",
                style: _normalizeStyle($setup.cartStore.isActivityInCart(eventpack) ? $setup.selectedActivityStyle : $setup.activityStyle)
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (eventpack.pictureUrl)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: eventpack.pictureUrl,
                          alt: "",
                          class: "booking-profile cursor-pointer hide-on-mobile",
                          onClick: ($event: any) => ($setup.toggleEventItemImageDialog(eventpack.pictureUrl))
                        }, null, 8 /* PROPS */, _hoisted_4))
                      : _createCommentVNode("v-if", true),
                    (eventpack.pictureUrl)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: eventpack.pictureUrl,
                          alt: "",
                          class: "booking-profile cursor-pointer show-on-mobile",
                          onClick: ($event: any) => ($setup.cartStore.cartItemsById[eventpack.id]?.count !== eventpack.maxQty ? $setup.add_to_cart(eventpack) : () => {})
                        }, null, 8 /* PROPS */, _hoisted_5))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h1", _hoisted_8, _toDisplayString(($setup.eventStore.showDescriptionInEnglish && eventpack.titleENG) ? eventpack.titleENG : eventpack.title), 1 /* TEXT */),
                        ($setup.userStore.user?.id && eventpack.label)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(eventpack.label), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        ($setup.cartStore.isPotentialItemInCart(eventpack.id))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($setup.t("packed")), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("pre", _hoisted_11, " " + _toDisplayString(($setup.eventStore.showDescriptionInEnglish && eventpack.descriptionENG) ? eventpack.descriptionENG : eventpack.description), 1 /* TEXT */)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        (eventpack.description)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "product-pack-more-info",
                              onClick: ($event: any) => ($setup.openShowMoreModal(eventpack.id))
                            }, _toDisplayString($setup.t("button.read_more")), 9 /* TEXT, PROPS */, _hoisted_13))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _createVNode($setup["DialogComponent"], {
                        ref_for: true,
                        ref: (el) => ($setup.showMoreDialogRef[eventpack.id] = el),
                        "close-button-label": $setup.t('OK'),
                        title: ($setup.eventStore.showDescriptionInEnglish && eventpack.titleENG) ? eventpack.titleENG : eventpack.title,
                        width: '800px',
                        "dialog-color-style": {
											primaryColor: $setup.eventStore.eventStyle.bodyTXT,
											secondaryColor: $setup.eventStore.eventStyle.bodyBG,
									},
                        "button-color-style": {
											primaryColor: $setup.eventStore.eventStyle.buttonTXT,
											secondaryColor: $setup.eventStore.eventStyle.buttonBG,
									}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("pre", _hoisted_14, _toDisplayString(($setup.eventStore.showDescriptionInEnglish && eventpack.descriptionENG) ? eventpack.descriptionENG : eventpack.description), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["close-button-label", "title", "dialog-color-style", "button-color-style"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(eventpack.showPrices[$setup.cartStore.getCurrency]), 1 /* TEXT */),
                    ($setup.cartStore.cartItemsById[eventpack.id])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("button", {
                            title: "Remove From Cart",
                            style: _normalizeStyle($setup.eventButtonStyle),
                            class: "rounded-button",
                            onClick: ($event: any) => ($setup.cartStore.removeFromCart(eventpack))
                          }, [...(_cache[2] || (_cache[2] = [
                            _createElementVNode("i", {
                              class: "fa fa-trash",
                              "aria-hidden": "true"
                            }, null, -1 /* HOISTED */)
                          ]))], 12 /* STYLE, PROPS */, _hoisted_18),
                          (eventpack.maxQty > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                ($setup.cartStore.cartItemsById[eventpack.id].count > 1 && !$setup.eventBookingStore.isPartnerSelected)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      style: _normalizeStyle($setup.eventButtonStyle),
                                      class: "rounded-button",
                                      onClick: ($event: any) => ($setup.cartStore.decrementCount(eventpack))
                                    }, [...(_cache[3] || (_cache[3] = [
                                      _createElementVNode("i", {
                                        class: "fa fa-minus",
                                        "aria-hidden": "true"
                                      }, null, -1 /* HOISTED */)
                                    ]))], 12 /* STYLE, PROPS */, _hoisted_20))
                                  : _createCommentVNode("v-if", true),
                                (!$setup.eventBookingStore.isPartnerSelected)
                                  ? (_openBlock(), _createElementBlock("input", {
                                      key: 1,
                                      type: "text",
                                      value: $setup.cartStore.cartItemsById[eventpack.id].count,
                                      readonly: "",
                                      style: _normalizeStyle({ border: `1px solid ${$setup.eventStore.eventStyle.bodyBG}`})
                                    }, null, 12 /* STYLE, PROPS */, _hoisted_21))
                                  : _createCommentVNode("v-if", true),
                                (!$setup.eventBookingStore.isPartnerSelected)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 2,
                                      style: _normalizeStyle($setup.eventButtonStyle),
                                      class: "rounded-button",
                                      disabled: $setup.cartStore.cartItemsById[eventpack.id].count == eventpack.maxQty,
                                      onClick: ($event: any) => ($setup.cartStore.incrementCount(eventpack))
                                    }, [...(_cache[4] || (_cache[4] = [
                                      _createElementVNode("i", {
                                        class: "fa fa-plus",
                                        "aria-hidden": "true"
                                      }, null, -1 /* HOISTED */)
                                    ]))], 12 /* STYLE, PROPS */, _hoisted_22))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("button", {
                            style: _normalizeStyle($setup.eventButtonStyle),
                            class: "rounded-button",
                            title: "Add To Cart",
                            onClick: ($event: any) => ($setup.add_to_cart(eventpack))
                          }, [...(_cache[5] || (_cache[5] = [
                            _createElementVNode("i", { class: "fa-solid fa-cart-shopping" }, null, -1 /* HOISTED */)
                          ]))], 12 /* STYLE, PROPS */, _hoisted_24)
                        ]))
                  ])
                ]),
                (eventpack.potentials?.length && $setup.cartStore.cartItemsById[eventpack.id])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("button", {
                        title: "Update Cart",
                        style: _normalizeStyle($setup.eventButtonStyle),
                        class: "rounded-button",
                        onClick: ($event: any) => ($setup.openPotentialItemModal(eventpack))
                      }, [...(_cache[6] || (_cache[6] = [
                        _createElementVNode("i", { class: "fas fa-cart-plus" }, null, -1 /* HOISTED */)
                      ]))], 12 /* STYLE, PROPS */, _hoisted_26),
                      _createElementVNode("span", null, _toDisplayString(`${$setup.cartStore.cartItemsById[eventpack.id].selected_potentional_items?.length }/${eventpack.workshopLimit + eventpack.milongaLimit + eventpack.activityLimit}`), 1 /* TEXT */),
                      (!$setup.cartStore.cartItemsById[eventpack.id].selected_potentional_items?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _cache[7] || (_cache[7] = _createElementVNode("i", {
                              class: "fa fa-warning",
                              style: {"margin-right":".5rem"}
                            }, null, -1 /* HOISTED */)),
                            _createElementVNode("span", null, _toDisplayString($setup.t('PotentialItemRequired')), 1 /* TEXT */)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 4 /* STYLE */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.eventBookingStore.getFilteredEventPacksByCurrency.length)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "event-packs-container",
              style: _normalizeStyle($setup.borderStyle)
            }, [
              _createElementVNode("span", {
                style: _normalizeStyle($setup.bodyTextColorStyle)
              }, "There are No Items in this Event", 4 /* STYLE */)
            ], 4 /* STYLE */))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" end "),
        _createElementVNode("div", {
          class: "mt-3 footer-sticky",
          style: _normalizeStyle([{"width":"100%"}, {'--backgroundColor': $setup.eventStore.eventStyle.background}])
        }, [
          _createVNode($setup["BookingNavbar"], {
            "prev-navigation-hidden": $setup.eventStore.event?.process == 'BOOKING',
            "next-navigation-disabled": !(!$setup.cartStore.isEmpty && $setup.eventStore.isBookingEnabled),
            onPrev: _cache[0] || (_cache[0] = ($event: any) => ($setup.prev())),
            onNext: _cache[1] || (_cache[1] = ($event: any) => ($setup.next()))
          }, null, 8 /* PROPS */, ["prev-navigation-hidden", "next-navigation-disabled"])
        ], 4 /* STYLE */)
      ]),
      _createCommentVNode("end")
    ]),
    _createCommentVNode("Potentials modal "),
    _createVNode($setup["DialogComponent"], {
      ref: "potentialsDialog",
      title: $setup.t('select_activities'),
      width: '80vw',
      "close-button-label": $setup.t('Accept'),
      "dialog-color-style": {
				primaryColor: $setup.eventStore.eventStyle.bodyTXT,
				secondaryColor: $setup.eventStore.eventStyle.bodyBG,
		},
      "button-color-style": {
				primaryColor: $setup.eventStore.eventStyle.buttonTXT,
				secondaryColor: $setup.eventStore.eventStyle.buttonBG,
		}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_28, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeEventPackPotentials, (event_potential) => {
            return (_openBlock(), _createElementBlock("div", {
              key: event_potential.activityId,
              class: _normalizeClass(["potential-item-container", {
					'cursor-pointer': !event_potential.disabled && !$setup.isPotentialItemInOtherEventPack(event_potential, $setup.activeEventPack.id),
					'potential-item--disabled': event_potential.disabled || $setup.isPotentialItemInOtherEventPack(event_potential, $setup.activeEventPack.id)
				}]),
              style: _normalizeStyle($setup.isSelectedInActiveEventPack(event_potential) ? $setup.selectedActivityStyle : $setup.activityStyle),
              onClick: ($event: any) => ($setup.togglePotentialToCart(event_potential))
            }, [
              _createElementVNode("img", {
                class: "potential-booking-profile",
                src: event_potential.pictureUrl,
                onClick: _withModifiers(($event: any) => ($setup.toggleEventItemImageDialog(event_potential.pictureUrl)), ["stop"])
              }, null, 8 /* PROPS */, _hoisted_30),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("p", _hoisted_32, _toDisplayString(($setup.eventStore.showDescriptionInEnglish && event_potential.titleENG) ? event_potential.titleENG : event_potential.title), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_33, [
                  _createElementVNode("span", null, _toDisplayString($setup.t(event_potential.weekDay)), 1 /* TEXT */),
                  _cache[8] || (_cache[8] = _createElementVNode("span", null, " ", -1 /* HOISTED */)),
                  _createElementVNode("span", null, _toDisplayString(event_potential.startTime.hour) + ":" + _toDisplayString(event_potential.startTime.minutes), 1 /* TEXT */),
                  _cache[9] || (_cache[9] = _createElementVNode("span", null, " - ", -1 /* HOISTED */)),
                  _createElementVNode("span", null, _toDisplayString(event_potential.endTime.hour) + ":" + _toDisplayString(event_potential.endTime.minutes), 1 /* TEXT */),
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, " ", -1 /* HOISTED */)),
                  (!$setup.cartStore.isPotentialItemInCart(event_potential.activityId) && $setup.overlapsTime(event_potential))
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        style: _normalizeStyle([{ color: $setup.eventStore.eventStyle.selectedTXT, border: `1px solid ${$setup.eventStore.eventStyle.selectedTXT}` }, {"padding":"0.2rem","border-radius":"0.2rem","font-size":"0.8rem","text-align":"center"}])
                      }, _toDisplayString($setup.t('TimeOverlap')), 5 /* TEXT, STYLE */))
                    : _createCommentVNode("v-if", true)
                ]),
                ($setup.isPotentialItemInOtherEventPack(event_potential, $setup.activeEventPack.id))
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      style: _normalizeStyle([{ color: $setup.eventStore.eventStyle.selectedTXT, border: `1px solid ${$setup.eventStore.eventStyle.selectedTXT}` }, {"padding":"0.2rem","border-radius":"0.2rem","font-size":"0.8rem","text-align":"center"}])
                    }, _toDisplayString($setup.t('PotentialSelectedInOtherPack')), 5 /* TEXT, STYLE */))
                  : _createCommentVNode("v-if", true)
              ])
            ], 14 /* CLASS, STYLE, PROPS */, _hoisted_29))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", {
            style: _normalizeStyle({ ...$setup.bodyTextColorStyle, borderColor: $setup.eventStore.eventStyle.bodyTXT }),
            class: "limits-container"
          }, [
            ($setup.activeEventPack?.milongaLimit > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", null, "Milonga", -1 /* HOISTED */)),
                  _createElementVNode("span", {
                    class: "limit-badge",
                    style: _normalizeStyle({ color: $setup.eventStore.eventStyle.bodyTXT,  borderColor: $setup.eventStore.eventStyle.buttonBG })
                  }, _toDisplayString($setup.selectedMilongaItems.length - $setup.extraAddedMilonga) + "/" + _toDisplayString($setup.activeEventPack?.milongaLimit), 5 /* TEXT, STYLE */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_36, [
              _cache[12] || (_cache[12] = _createElementVNode("span", null, "Workshop", -1 /* HOISTED */)),
              _createElementVNode("span", {
                class: "limit-badge",
                style: _normalizeStyle({ color: $setup.eventStore.eventStyle.bodyTXT,  borderColor: $setup.eventStore.eventStyle.buttonBG })
              }, _toDisplayString($setup.selectedWorkshopItems.length - $setup.extraAddedWorkshop) + "/" + _toDisplayString($setup.activeEventPack?.workshopLimit), 5 /* TEXT, STYLE */)
            ]),
            ($setup.activeEventPack?.activityLimit > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _cache[13] || (_cache[13] = _createElementVNode("span", null, "Extra activities", -1 /* HOISTED */)),
                  _createElementVNode("span", {
                    class: "limit-badge",
                    style: _normalizeStyle({ color: $setup.eventStore.eventStyle.bodyTXT,  borderColor: $setup.eventStore.eventStyle.buttonBG })
                  }, _toDisplayString($setup.selectedExtraItems) + "/" + _toDisplayString($setup.activeEventPack?.activityLimit), 5 /* TEXT, STYLE */)
                ]))
              : _createCommentVNode("v-if", true)
          ], 4 /* STYLE */),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", null, [
              ($setup.cartStore.cartItemsById[$setup.activeEventPack.id] && !$setup.cartStore.cartItemsById[$setup.activeEventPack.id]['selected_potentional_items']?.length)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    style: _normalizeStyle($setup.bodyTextColorStyle)
                  }, _toDisplayString($setup.t('MakeYourChoices')), 5 /* TEXT, STYLE */))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "close-button-label", "dialog-color-style", "button-color-style"]),
    _createCommentVNode("end"),
    _createVNode($setup["DialogComponent"], {
      ref: "imageDialog",
      "close-button-label": $setup.t('OK'),
      "dialog-color-style": {
				primaryColor: $setup.eventStore.eventStyle.bodyTXT,
				secondaryColor: $setup.eventStore.eventStyle.bodyBG,
		},
      "button-color-style": {
				primaryColor: $setup.eventStore.eventStyle.buttonTXT,
				secondaryColor: $setup.eventStore.eventStyle.buttonBG,
		}
    }, {
      default: _withCtx(() => [
        ($setup.ModalImage)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $setup.ModalImage
            }, null, 8 /* PROPS */, _hoisted_39))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["close-button-label", "dialog-color-style", "button-color-style"])
  ], 64 /* STABLE_FRAGMENT */))
}