import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref, provide } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormComponent',
  props: {
    name: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const name = computed(() => props.name) 

const formItems: Ref<any[]> = ref([])

let formItemsIds = 0

function addFormItem(item: any) {
  item.id = formItemsIds++
  console.log('Adding item ', item.id)
  formItems.value.push(item)
  return item.id
}

function removeFormItem(id: number) {
  console.log('Removing item ', id)
  const index = formItems.value.findIndex((item: any) => item.id === id);
  if (index !== -1) {
    formItems.value.splice(index, 1);
  }
}

provide('FormComponent', {
  addFormItem,
  removeFormItem
})

function validate (): boolean {
  console.log('Validating Form!!!')
  let isValid = true
  formItems.value.forEach((formItem: any) => {
    console.log(' -> Validating Form item : ', formItem.id)
    const result = formItem.validate()
    console.log(' -> Validation Result: ', result)
    isValid = result && isValid
  })
  return isValid
}

__expose({
  validate: () => validate()
})


const __returned__ = { props, name, formItems, get formItemsIds() { return formItemsIds }, set formItemsIds(v) { formItemsIds = v }, addFormItem, removeFormItem, validate }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})