import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, watch, ref, Ref, onMounted, nextTick, StyleValue } from 'vue';
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router'
import InputDropdown from '@/design-system/InputDropdown.vue';
import FormComponent from '@/design-system/FormComponent.vue'
import FormItemRadioButton from '@/design-system/FormItemRadioButton.vue';
import FormItemInput from '@/design-system/FormItemInput.vue';
import { useUserStore } from '@/store/user-store'
import { useCartStore } from '@/store/cart-store'
import QuestionFormItem from '../registration-form/QuestionFormItem.vue'
import { useEventStore } from "@/store/event-store";
import BookingNavbar from '../BookingNavbar.vue'
import { useEventBookingStore } from "@/store/booking-store";
import SignUpLoginForm from '@/components/SignUpLoginForm.vue';
import LoginForm from '@/components/LoginForm.vue';
import ConfirmDialog from '@/design-system/ConfirmDialog.vue';
import { useCountriesOptions } from "@/composable/useCountriesOptions";
import { useI18n } from 'vue-i18n';
import DialogComponent from '@/design-system/DialogComponent.vue';
import HorizontalLine from '@/design-system/HorizontalLine.vue';
import colours from '@/json/defaultColor.json'



export default /*@__PURE__*/_defineComponent({
  __name: 'stepRegistration',
  setup(__props, { expose: __expose }) {
  __expose();

const eventBookingStore = useEventBookingStore()
const userStore = useUserStore()
const eventStore = useEventStore()
const cartStore = useCartStore()
const router = useRouter()
const { t } = useI18n();

const mode = ref('');
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const confirm_email = ref('');
const selectedCountryOption = ref(null);
const selectedResidencyOption = ref(null)
const formRef: Ref<any> = ref(null)
const confirmDialogRef: Ref<any> = ref(null)
const loginModalDialog: Ref<typeof DialogComponent | null>  = ref(null)
const showSignUpInModal = ref(false)

const headerTXTObj = computed(() => ({
    'color': eventStore.eventStyle.headerTXT
}));

const inputStyle = computed(() => ({
    "color": eventStore.eventStyle.bodyTXT,
    "background-color": eventStore.eventStyle.bodyBG,
    "border": `1px solid ${eventStore.eventStyle.bodyTXT} !important`,
    '--a-color': eventStore.eventStyle.bodyTXT,
    "primaryColor": eventStore.eventStyle.bodyTXT,
    "secondaryColor": eventStore.eventStyle.bodyBG,
}));

const countriesDropdownOptions = computed(() => useCountriesOptions().countriesDropdownOptions);

const isLoggedIn = computed(() => userStore.isLoggedIn);
const secondaryButtonStyle = computed(() => ({
  color: eventStore.eventStyle.bodyTXT,
  backgroundColor: eventStore.eventStyle.background,
}))

watch(isLoggedIn, () => {
    fillFormWithLoggedInUserData()
    nextTick(() => formRef.value?.validate()); //use nextTick to allow formInputs update their values
    mode.value = userStore.isLoggedIn ? 'LOGIN' : 'NOLOGIN'
})

onMounted(() => {
    window.scrollTo(0,0);
    fillFormWithLoggedInUserData()
    if (!userStore.isLoggedIn) {
        selectedResidencyOption.value = useCountriesOptions().getCountryOptionById(eventStore.event.country)
        selectedCountryOption.value = useCountriesOptions().getCountryOptionById(eventStore.event.country)
    }
    mode.value = userStore.isLoggedIn ? 'LOGIN' : 'NOLOGIN'
})

// Function for prev method
function prev() {
  eventBookingStore.goToPrevBookingStep();
}

// Function for fillFormWithLoggedInUserData method
function fillFormWithLoggedInUserData() {
  console.log({loggedInUser: userStore.user});
  if (userStore.isLoggedIn) {
    firstName.value = userStore.user.firstName;
    lastName.value = userStore.user.lastName;
    email.value = userStore.user.email;
    selectedCountryOption.value = useCountriesOptions().getCountryOptionById(userStore.user?.nationality);
    selectedResidencyOption.value = useCountriesOptions().getCountryOptionById(userStore.user?.residency);
  }
}

// Function for next method
async function next() {
  const Toast = useToast();
  const isValid =  formRef.value.validate();
  console.log('Form is valid? ', isValid);
  if (!isValid) {
    Toast.error(t('toast_translation.fill_the_data'));
    return;
  }

  console.log('Missing Required Questions: ', eventBookingStore.missingRequiredQuestionsList.length);
  if (!eventBookingStore.defaultBookingItem && eventBookingStore.missingRequiredQuestionsList.length) {
    Toast.error(t('toast_translation.fill_the_data'));
    return;
  }

  let userId = null;
  let user = null;
  if (userStore.isLoggedIn) {
    userId = userStore.user.id;
  } else {
    user = {
      fullName: firstName.value + ' ' + lastName.value,
      name: firstName.value,
      lastName: lastName.value,
      email: email.value,
      pictureUrl: null,
      nationality: selectedCountryOption.value?.id || selectedResidencyOption.value?.id,
      residency: selectedResidencyOption.value?.id
    };
  }

  let UpsessionData = {
    userId,
    eventId: eventStore.getEventIdRequestBody,
    domain: eventStore.event.domain || null,
    partnerId: null,
    partner: null,
    form: eventBookingStore.registrationFormAnswersList,
    platform: eventStore.isEmbeddedEventMode ? 'EMBEDDED' : 'VUE',
    user,
    locale: localStorage.getItem('language') || navigator.language || 'en'
  };

  UpsessionData.role = eventBookingStore.selectedRole || 'NONE';
  if (eventBookingStore.isPartnerSelected) {
    UpsessionData.partner = {
      fullName: eventBookingStore.partnerForm.fullname,
      email: eventBookingStore.partnerForm.email,
      residency: eventBookingStore.partnerForm.residency.id,
      nationality: eventBookingStore.partnerForm.residency.id
    };
  }

  try {
    console.log('Processs = ', eventStore.event?.process);
    console.log('SessionId = ', eventBookingStore.sessionId);
    if (!eventBookingStore.sessionId) {
      console.log('Will create session');
      await eventBookingStore.createSession(UpsessionData);
      if (eventStore.event?.process == 'BOOKING') {
        console.log('Will update session');
        await eventBookingStore.updateSession();
      }
      if (eventBookingStore.defaultBookingItem && !eventBookingStore.isValidDefaultBookingItem) {
        throw {
          message: 'Bad Request',
          code: 'BookingItemNotAvailable'
        }
      }
    } else {
      console.log('Will update session');
      await eventBookingStore.updateSession();
    }
    console.log('Go to next booking step');
    eventBookingStore.goToNextBookingStep();
  } catch (error: any) {
    console.log('Error after create session: ', error);
    if (error.code == 101) {
      Toast.error(t('toast_translation.error_email_exists'));
    } else if(error.code == 'BookingItemNotAvailable') {
      Toast.error(t('BookingItemNotAvailable'))
      eventBookingStore.reset()
      router.push( `/event/${eventStore.eventUrlId}`)
      // TODO kill session in server
    } else {
      Toast.error(t('toast_translation.error_mesg'));
    }
  }
}

// Function for handleLogout method
function handleLogout() {
  if (eventBookingStore.sessionId) {
    // Assuming `confirmDialogRef` is accessible here
    confirmDialogRef.value?.open('logoutConfirm');
  } else {
    clearUserFormAndLogout();
  }
}

// Function for handleConfirmation method
function handleConfirmation(dialogId: string) {
  if (dialogId == 'logoutConfirm') {
    clearUserFormAndLogout();
    clearCurrentSession();
  }
  if (dialogId == 'roleChangeConfirm' || dialogId == 'registerPartnerConfirm') {
    clearCurrentSession();
  }
}

// Function for clearUserFormAndLogout method
function clearUserFormAndLogout() {
  firstName.value = '';
  lastName.value = '';
  email.value = '';
  userStore.logout();
}

// Function for clearCurrentSession method
function clearCurrentSession() {
  // TODO: Implement API.cancelSession(this.eventBookingStore.sessionId)
  eventBookingStore.setSessionId('');
  cartStore.clear();
}

// Function for handleLoginPopupClose method
function handleLoginPopupClose() {
  loginModalDialog.value?.close()
  fillFormWithLoggedInUserData();
}

// Function for handleRoleChange method
function handleRoleChange() {
  if (eventBookingStore.sessionId) {
    confirmDialogRef.value.open('roleChangeConfirm', { hideCancel: true });
  }
}

// Function for handleRegisterPartnerChange method
function handleRegisterPartnerChange() {
  if (eventBookingStore.sessionId) {
    confirmDialogRef.value.open('registerPartnerConfirm', { hideCancel: true });
  }
}

const openSignUp = () => {
  showSignUpInModal.value = true;
  loginModalDialog.value?.open()
};

const openSignIn = () => {
  showSignUpInModal.value = false;
  loginModalDialog.value?.open()
};

function selectSignIn() {
  openSignIn()
}

function selectGuestUser() {
  mode.value = 'GUEST'
}

function onLogin() {
  if (userStore.incompleteSignUp) {
    openSignUp()
  }
}


const __returned__ = { eventBookingStore, userStore, eventStore, cartStore, router, t, mode, firstName, lastName, email, confirm_email, selectedCountryOption, selectedResidencyOption, formRef, confirmDialogRef, loginModalDialog, showSignUpInModal, headerTXTObj, inputStyle, countriesDropdownOptions, isLoggedIn, secondaryButtonStyle, prev, fillFormWithLoggedInUserData, next, handleLogout, handleConfirmation, clearUserFormAndLogout, clearCurrentSession, handleLoginPopupClose, handleRoleChange, handleRegisterPartnerChange, openSignUp, openSignIn, selectSignIn, selectGuestUser, onLogin, InputDropdown, FormComponent, FormItemRadioButton, FormItemInput, QuestionFormItem, BookingNavbar, SignUpLoginForm, LoginForm, ConfirmDialog, DialogComponent, HorizontalLine, get colours() { return colours } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})