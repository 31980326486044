import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode($setup["DialogComponent"], {
      ref: "dialogRef",
      title: $setup.title,
      "dialog-color-style": $setup.dialogColorStyle,
      "button-color-style": $setup.buttonColorStyle
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($setup.message), 1 /* TEXT */)
      ]),
      footer: _withCtx(() => [
        (!$setup.hideCancel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              style: _normalizeStyle({
                        color: `${$setup.buttonColorStyle.primaryColor}`,
                        backgroundColor: `${$setup.buttonColorStyle.secondaryColor}`,
                        border: `1px solid ${$setup.buttonColorStyle.primaryColor}`}),
              class: "dialog-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onCancel()))
            }, _toDisplayString($setup.cancelButtonLabel), 5 /* TEXT, STYLE */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("button", {
          style: _normalizeStyle({
                        color: `${$setup.buttonColorStyle.primaryColor}`,
                        backgroundColor: `${$setup.buttonColorStyle.secondaryColor}`,
                        border: `1px solid ${$setup.buttonColorStyle.primaryColor}`}),
          class: "dialog-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onConfirm()))
        }, _toDisplayString($setup.confirmButtonLabel), 5 /* TEXT, STYLE */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "dialog-color-style", "button-color-style"])
  ]))
}