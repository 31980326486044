import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, computed, Ref } from 'vue'
import PotsLoader from '@/design-system/pots-loader.vue';
import { useI18n } from 'vue-i18n'
import { useSumUp } from '@/composable/useSumUp';

interface LogType {
  time: string,
  type: 'INFO' | 'ERROR',
  message: string,
  value: any | undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'sumup',
  props: {
    checkoutId: { type: String, required: true },
    sessionId: { type: String, required: true },
    close: { type: Boolean, required: true },
    merchantName: { type: String, required: true },
    debug: { type: Boolean, required: true },
    locale: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t, locale: i18nLocale } = useI18n()

const props = __props

const checkoutId = computed <string> (() => props.checkoutId)
const sessionId = computed <string> (() => props.sessionId)
const close = computed <boolean> (() => props.close)
const merchantName = computed <string> (() => props.merchantName || 'Points of Tango')
const locale = computed <'en' | 'es' | 'it' | 'ru'> (() => props.locale || localStorage.getItem('language') || navigator.language || 'en')

const debug = computed <boolean> (() => props.debug)

const errorMessage = ref('')
const paymentInProgress = ref(true)

const log: Ref<Array<string>> = ref([])

const { sumUpLoaded, sumUpIsLoading, loadSumUpScript, setSumUpLocale, setSumUpMerchantName, openSumUpFormAndProcessPayment } = useSumUp()

const infoLog: Ref<Array<LogType>> = ref([])

const Logger = {
  info: (message: string, value?: any) => {
    infoLog.value.push({
      time: new Date().toISOString(),
      type: 'INFO',
      message,
      value,
    })
  },
}

onMounted(async () => {
  try {
      i18nLocale.value = locale.value;
      if (!checkoutId.value) {
        errorMessage.value = 'Invalid payment link - Missing checkoutId'
      } else {
        log.value.push('Page mounted, sessionId = ' + sessionId.value)
        setSumUpMerchantName(merchantName.value)
        setSumUpLocale(locale.value)
        await loadSumUpScript();
        await startPaymentProcess();
      }
  } catch (e: any) {
      console.log('Error loading SumUp:', e)
      errorMessage.value = e.message
  }
})

function completePayment (confirmationId: string, entryMode: string, sessionId: string) {
  // redirect to http
  log.value.push('Redirect to confirm payment page with confirmationId = ' + confirmationId)
  window.location.replace(`${process.env.VUE_APP_BASE_URL}payments/completePayment/${sessionId}/r?checkout_id=${confirmationId}&entry_mode=${entryMode}`);

  // await axios.get(`${process.env.VUE_APP_BASE_URL}payments/completePayment/${sessionId}/r?checkout_id=${confirmationId}`)
}

/* async function cancelPayment (paymentId: string) {
  await axios.post(`${process.env.VUE_APP_BASE_URL}interaction/cancelPayment`, {
    paymentId,
    confirmationId: null,
  })
} */

async function startPaymentProcess() {
    try {
        const {transactionId, entryMode} = await openSumUpFormAndProcessPayment(checkoutId.value);
        sumUpIsLoading.value = true
        completePayment(transactionId, entryMode, sessionId.value)
    } catch (e: any) {
        // await cancelPayment(paymentId.value)
        log.value.push('Error catched: ' + e.message)
        errorMessage.value = e.message
    }
    if (close.value) {
      window.close()
    }
}




const __returned__ = { t, i18nLocale, props, checkoutId, sessionId, close, merchantName, locale, debug, errorMessage, paymentInProgress, log, sumUpLoaded, sumUpIsLoading, loadSumUpScript, setSumUpLocale, setSumUpMerchantName, openSumUpFormAndProcessPayment, infoLog, Logger, completePayment, startPaymentProcess, PotsLoader }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})