import { defineComponent as _defineComponent } from 'vue'
import { computed} from 'vue'
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import MobileFloatingFooter from '@/design-system/MobileFloatingFooter.vue';
import BookButton from '../event/BookButton.vue';
import { useEventStore } from '@/store/event-store';
import { useUserStore } from '@/store/user-store';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventHeader',
  props: {
    eventUrlId: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const eventStore = useEventStore();
const userStore = useUserStore();
const router = useRouter();
const { t } = useI18n();

const props = __props;

const eventUrlId = computed(() => props.eventUrlId);
const event = computed(() => eventStore.event)
const getUserPaymentStatus = computed(() => eventStore.userPaymentStatus);
const isBookingPage = computed(() => router.currentRoute.value.name === 'booking');
const paymentuserBtnobj = computed(() => ({
    color: eventStore.eventStyle.buttonTXT,
    backgroundColor: eventStore.eventStyle.bodyBG,
    border: `1px solid ${eventStore.eventStyle.buttonBG}`
}));

const shortDate = computed(() => {
    const timestamp = event.value?.from?.seconds;
    if (!timestamp) return '';
    const time = moment.unix(timestamp).utc();
    const date = time.format('D');
    const month = t(time.format('MMMM'));
    const year = t(time.format('YYYY'));
    return `${date} ${month} ${year}`;
});

const shortTime = computed(() => {
    const timestamp = event.value?.from?.seconds;
    if (!timestamp) return '';
    const time = moment.unix(timestamp).utc();
    return time.format('HH:mm A');
});

const shouldDisplayTicketsSection = computed(() => {
    return !isBookingPage.value && eventStore.isBookingEnabled;
});

const linkUrl = computed(() => {
  const link = eventStore.event.link;
  if (!link) {
    return null;
  } else {
    return link.includes('https://') ? link : `https://${link}`;
  }
});

const linkLabel = computed(() => {
  return linkUrl.value?.split('https://')[1] || '';
});


const __returned__ = { eventStore, userStore, router, t, props, eventUrlId, event, getUserPaymentStatus, isBookingPage, paymentuserBtnobj, shortDate, shortTime, shouldDisplayTicketsSection, linkUrl, linkLabel, MobileFloatingFooter, BookButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})