import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, Ref, StyleValue, inject, onUnmounted, onMounted } from 'vue'
import FormItem from './FormItem.vue'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormItemInput',
  props: {
    modelValue: { type: [String, null], required: true },
    placeholder: { type: String, required: true },
    required: { type: Boolean, required: false },
    email: { type: Boolean, required: false },
    phone: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true },
    equalTo: { type: String, required: false },
    pattern: { type: null, required: false }
  },
  emits: ["update:modelValue", "change", "focus"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const { addFormItem, removeFormItem } = inject('FormComponent', () => {}) as any

const props = __props;

const visited = ref(false)
const forcedValidation = ref(false)
const errorMessage = ref('')
const focused = ref(false)
const itemId = ref(null)
const inputRef: Ref<HTMLInputElement | null> = ref(null)

// define emits
const emit = __emit;

onMounted(() => {
  if (addFormItem) {
    itemId.value = addFormItem({
      validate: () => {
        forcedValidation.value = true
        return validate()
      }
    })
  }
})

onUnmounted(() => {
  if (removeFormItem) {
    removeFormItem(itemId.value)
  }
})

// define proxyValue for modelValue to emit update:modelValue
const value: ComputedRef<string | null> = computed(() => props.modelValue);
const isEmailType: ComputedRef<boolean> = computed(() => props.email);
const isPhoneType: ComputedRef<boolean> = computed(() => props.phone);
const isRequired: ComputedRef<boolean> = computed(() => props.required);
const isDisabled: ComputedRef<boolean> = computed(() => props.disabled);
const equalTo: ComputedRef<string | undefined> = computed(() => props.equalTo);
const hasValue: ComputedRef<boolean> = computed(() => !(value.value == undefined || value.value == null || value.value == ''));
const pattern: ComputedRef<RegExp | undefined> = computed(() => props.pattern);

function handleInputChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement)?.value)
  emit("change", (event.target as HTMLInputElement)?.value);
}

function validate (): boolean {
  errorMessage.value = ''
  const messages = []
  if (!value.value?.trim() && isRequired.value && !isDisabled.value) {
    messages.push(t('ThisFieldIsRequired'))
  }
  if (equalTo.value !== undefined && equalTo.value !== value.value) {
    messages.push(t(`ValueDoesNotMatch`))
  }
  if (pattern.value !== undefined && !pattern.value?.test(value.value || '')) {
    if (isPhoneType.value) {
      messages.push(t(`WrongPhoneNumberFormat`))
    } else {
      messages.push(t(`WrongFormat`))
    }
  }
  errorMessage.value = messages.length ? messages.join(' ') : ''
  return !errorMessage.value
}

function onBlur() {
  visited.value = true
  focused.value = false
  validate()
}

function onFocus(event: FocusEvent) {
  focused.value = true
  emit("focus", event)
}

function onSpaceKeyHandler (event: Event) {
  if (isEmailType.value) event.preventDefault()
}

function handleFormItemClick() {
  focus()
}

function focus() {
  inputRef.value?.focus()
}

__expose({
  validate: () => {
    forcedValidation.value = true
    validate()
  }
})


const __returned__ = { t, addFormItem, removeFormItem, props, visited, forcedValidation, errorMessage, focused, itemId, inputRef, emit, value, isEmailType, isPhoneType, isRequired, isDisabled, equalTo, hasValue, pattern, handleInputChange, validate, onBlur, onFocus, onSpaceKeyHandler, handleFormItemClick, focus, FormItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})