import { ref } from 'vue';

interface TransactionType {
  entryMode: string,
  transactionId: string,
}

const SumupLocaleMap =  new Map()
SumupLocaleMap.set('en', 'en-GB')
SumupLocaleMap.set('es', 'es-ES')
SumupLocaleMap.set('it', 'it-IT')
SumupLocaleMap.set('ru', 'en-GB') // Russian not supported

export function useSumUp() {

  const locale = ref(localStorage.getItem('language') || navigator.language || 'en')
  const merchantName = ref('Points of Tango')
  const sumUpLoaded = ref(false)
  const sumUpIsLoading = ref(true)
  const sumUpProcessing = ref(false)

  function setSumUpLocale (value: 'en' | 'es' | 'it' | 'ru') {
    locale.value = value
  }

  function setSumUpMerchantName (value: string) {
    merchantName.value = value
  }

  async function loadSumUpScript() {
    return new Promise<void>((resolve, reject) => {
        const SumUpCard = (window as any).SumUpCard
        if (typeof SumUpCard == 'undefined') {
            const script = document.createElement('script');
            script.src = 'https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js';
            script.async = true;
            script.onload = async () => {
                const SumUpCard = (window as any).SumUpCard
                if (typeof SumUpCard != 'undefined') {
                    resolve(SumUpCard)
                } else {
                    reject(new Error('SumUpCard is not defined. SDK may not have loaded properly.'))
                }
            }
            script.onerror = async () => {
                reject(new Error('Error loading SumUpCard SDK'))
            }
            document.head.appendChild(script);
        } else {
            resolve(SumUpCard)
        }
    })
  }

  async function openSumUpFormAndProcessPayment(checkoutId: string): Promise<TransactionType> {
    return new Promise((resolve, reject) => {
        const SumUpCard = (window as any).SumUpCard
        // docs: https://developer.sumup.com/online-payments/tools/card-widget#configurations
        const sumupCard = SumUpCard.mount({
            id: 'sumup-card',
            checkoutId,
            locale: SumupLocaleMap.get(locale.value) || 'en-GB',
            onLoad: () => {
              sumUpLoaded.value = true
              sumUpIsLoading.value = false
            },
            onResponse: async function (type: string, body: any) {
                console.log('Sumup Response: ', {type, body});
                const transactionId = body.transactions?.length ? body.transactions[0].id : (body.id || null)
                const entryMode = body.transactions?.length ? body.transactions[0].entry_mode : 'Card' 
                const status = body.status?.toLowerCase()
                type = type.toLowerCase()
                if (type == 'sent') {
                    sumUpProcessing.value = true
                    return
                }
                if (type == 'success') {
                    console.log('Status: ', status)
                    console.log('TransactionId: ', transactionId)
                    sumupCard.unmount()
                    sumUpProcessing.value = false
                    console.log('Resolve promise for sumup payment')
                    resolve({
                        transactionId,
                        entryMode
                    })
                } else if (type == 'auth-screen') {
                    // do nothing
                } else if (type == 'error' || type == 'invalid' || type == 'fail') {
                    sumUpProcessing.value = false
                    reject(new Error(body?.message || 'Something went wrong! Please try again'));
                } else {
                    sumUpProcessing.value = false
                    reject(new Error(body?.message || 'Something went wrong! Please try again'));
                }
            },
            showFooter: false,
            googlePay: {
                merchantId: 'BCR2DN4TZC54L3DZ',
                merchantName: merchantName.value
            },
            eventBatchSize: 20,
            eventFlushInterval: 10, 
        });
    })
}

  return { sumUpLoaded, sumUpIsLoading, sumUpProcessing, setSumUpLocale, setSumUpMerchantName, loadSumUpScript, openSumUpFormAndProcessPayment };
}