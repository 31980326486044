import { createApp } from 'vue'
import App from './App.vue'
import i18n from "./i18n"
import router from "./router";
import { createPinia } from 'pinia'

import "bootstrap/dist/css/bootstrap.min.css"

import "@/assets/css/main.scss"
import "@/assets/css/custom.css"

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import vue3GoogleLogin from 'vue3-google-login';
import  VueGoogleMaps from '@fawmi/vue-google-maps'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const app = createApp(App)

app.config.productionTip = false;

app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY,
    },
})
app.use(vue3GoogleLogin, {clientId: '1053146260879-s4c7m8828mvorncu2318hk5dlsm6hom8.apps.googleusercontent.com'})
app.use(Toast)
app.use(router)
app.use(i18n)
app.use(createPinia())
app.use(Vue3ColorPicker)

app.mount('#app')
