import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "form-item-header" }
const _hoisted_2 = { class: "form-item-label--container" }
const _hoisted_3 = {
  key: 0,
  class: "form-item-label"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "form-item" }
const _hoisted_6 = { class: "form-item-footer" }
const _hoisted_7 = { class: "form-item-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-item-container",
    style: _normalizeStyle($setup.style),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('click')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!$setup.hideLabel)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.placeholder), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (!$setup.hideLabel && $setup.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString($setup.errorMessage || ''), 1 /* TEXT */)
    ])
  ], 4 /* STYLE */))
}