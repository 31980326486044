import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, computed, Ref } from 'vue'
import PotsLoader from '@/design-system/pots-loader.vue';
import StarComponent from '@/design-system/StarComponent.vue';
import { useI18n } from 'vue-i18n'
import { getEventMinified, getReviewForm, submitReviewForm } from '@/services/events.service';
import { useToast } from 'vue-toastification'
import PotsFooter from '@/components/PotsFooter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'event',
  props: {
    eventId: { type: String, required: true },
    userId: { type: String, required: true },
    country: { type: String, required: true },
    locale: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t, locale: i18nLocale } = useI18n()

const props = __props

const userId = computed <string> (() => props.userId)
const eventId = computed <string> (() => props.eventId)
const country = computed <string> (() => props.country)
const locale = computed <string> (() => props.locale || localStorage.getItem('language') || navigator.language || 'en')

const errorMessage = ref('')
const formLoaded = ref(false)
const isLoading = ref(true)
const submitError = ref('')
const submitSuccess = ref('')

const location = ref(0)
const organisation = ref(0)
const level = ref(0)
const artist = ref(0)
const dj = ref(0)
const benefit = ref(0)
const comments = ref('')
const share = ref(false)
const anonymous = ref(false)
const name = ref('')
const event = ref(null)

onMounted(async () => {
  try {
      i18nLocale.value = locale.value;
      if (!eventId.value) {
        errorMessage.value = t('review_page.missingEventId')
      } 
      if (!userId.value) {
        errorMessage.value = t('review_page.missingUserId')
        // load empty form
        formLoaded.value = true
      } else {
        // load form from server
        event.value = await getEventMinified(eventId.value, country.value)
        // getReviewForm(userId.value, eventId.value, country.value)

        formLoaded.value = true
        isLoading.value = false
      }
  } catch (e: any) {
      errorMessage.value = e.message
  }
})

async function submit() {
  try {
    if (!location.value || !organisation.value || !level.value || !artist.value || !dj.value || !benefit.value) {
        const toast = useToast()
        toast.error('Each category should be starred')
        return
    }
    const response = await submitReviewForm({
      userId: userId.value,
      eventId: {
        eventId: eventId.value,
        ...(country.value && { country: country.value }),
      },
      fullName: !anonymous.value ? name.value : '',
      anonymous: anonymous.value,
      share: anonymous.value,
      locationRate: location.value,
      organisationRate: organisation.value,
      levelRate: level.value,
      artistRate: artist.value,
      djRate: dj.value,
      benefitRate: benefit.value,
      comment: comments.value
    })
    console.log({response})
    if (response.responseCode == 200) {
      submitSuccess.value = t('review_page.reviewSubmitted')
    }
    
  } catch (error: any) {
    console.error(error)
    submitError.value = t('submitError')
  }
}


const __returned__ = { t, i18nLocale, props, userId, eventId, country, locale, errorMessage, formLoaded, isLoading, submitError, submitSuccess, location, organisation, level, artist, dj, benefit, comments, share, anonymous, name, event, submit, PotsLoader, StarComponent, PotsFooter }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})