import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "container event-links" }
const _hoisted_3 = { class: "hide-on-mobile" }
const _hoisted_4 = { class: "event-links-row" }
const _hoisted_5 = { class: "show-on-mobile" }
const _hoisted_6 = { class: "event-links-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($setup.links.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.links, (link, index) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: index,
                  to: link.route,
                  class: "nav-link old-class",
                  style: _normalizeStyle($setup.styleObjectHover)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createElementVNode("i", {
                        class: _normalizeClass(["fa-solid", [link.icon]])
                      }, null, 2 /* CLASS */),
                      _createTextVNode(" " + _toDisplayString(link.label), 1 /* TEXT */)
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "style"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              ref: "barsButtonRef",
              class: "navbar-toggler",
              type: "button",
              onClick: $setup.toggleMobileMenu
            }, [
              _createElementVNode("span", {
                class: "navbar-toggler-icon",
                style: _normalizeStyle({'color': $setup.eventStore.eventStyle.bodyTXT})
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("i", { class: "fa fa-bars" }, null, -1 /* HOISTED */)
              ]), 4 /* STYLE */)
            ], 512 /* NEED_PATCH */),
            ($setup.isMobileMenuOpened)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "mobile-menu",
                  style: _normalizeStyle({
               'border': `1px solid {eventStore.eventStyle.selectedTXT}`,
               'background': $setup.eventStore.eventStyle.headerBG,
               'top': `{barsButtonRef.getBoundingClientRect().bottom}px`
             })
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.links, (link, index) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        key: index,
                        to: link.route,
                        class: "nav-link old-class",
                        style: _normalizeStyle($setup.styleObjectHover),
                        onClick: $setup.toggleMobileMenu
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, [
                            _createElementVNode("i", {
                              class: _normalizeClass(["fa-solid", [link.icon]])
                            }, null, 2 /* CLASS */),
                            _createTextVNode(" " + _toDisplayString(link.label), 1 /* TEXT */)
                          ])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "style"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ], 4 /* STYLE */))
              : _createCommentVNode("v-if", true),
            ($setup.isMobileMenuOpened)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "menu-back-glass",
                  onClick: $setup.toggleMobileMenu
                }))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}