import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":".5rem 0","max-width":"300px","text-wrap":"pretty","font-size":"1.2rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createBlock(_component_GMapMap, {
    ref: "scrollTarget",
    class: "mt-4",
    center: $setup.center,
    zoom: $setup.zoom,
    options: {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
        },
    style: {"height":"430px","overflow":"hidden"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.markers, (m, index) => {
        return (_openBlock(), _createBlock(_component_GMapMarker, {
          key: index,
          position: m.position,
          clickable: true,
          icon: require('./../../assets/logos/pots_logo_32.png'),
          title: m.name,
          onClick: ($event: any) => ($setup.openMarker('GMapMarker',m.id))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GMapInfoWindow, {
              closeclick: true,
              opened: $setup.openedMarkerID === m.id,
              onCloseclick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openMarker('GMapInfoWindow',$setup.openedMarkerID)))
            }, {
              default: _withCtx(() => [
                _createVNode($setup["CardImage"], {
                  image: m?.coverUrl,
                  title: m.name,
                  style: {"max-width":"300px"}
                }, null, 8 /* PROPS */, ["image", "title"]),
                _createVNode(_component_router_link, {
                  to: { path: '/event/' + $setup.getEventUrlId(m) } ,
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(m.name), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]),
                _createVNode($setup["EventDateTime"], {
                  date: m.from,
                  "end-date": m.to
                }, null, 8 /* PROPS */, ["date", "end-date"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["opened"])
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["position", "icon", "title", "onClick"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["center", "zoom"]))
}