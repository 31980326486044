import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container artists-grid" }
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = { class: "card-content" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.performers.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "artists card-grid",
          style: _normalizeStyle([ $setup.eventCardColorAndBackgroundStyle ])
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "card-icon" }, [
            _createElementVNode("i", { class: "fa-solid fa-id-card" })
          ], -1 /* HOISTED */)),
          _createElementVNode("h5", _hoisted_2, _toDisplayString($setup.t('menu.artists')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["CardContainer"], null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.performers, (performer, index) => {
                  return (_openBlock(), _createBlock($setup["SmallProfileCard"], {
                    key: performer.id,
                    style: _normalizeStyle($setup.bodyWithBorderStyle),
                    "cover-image-url": performer?.pictureUrl,
                    name: performer.artisticName,
                    "country-flag-image-url": require(`@/assets/flags/${performer.flag}.png`),
                    "country-name": performer.country_full_name,
                    "text-color": $setup.bodyTXTColor
                  }, null, 8 /* PROPS */, ["style", "cover-image-url", "name", "country-flag-image-url", "country-name", "text-color"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    ($setup.djs.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "djs card-grid",
          style: _normalizeStyle([ $setup.eventCardColorAndBackgroundStyle ])
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "card-icon" }, [
            _createElementVNode("i", { class: "fa-solid fa-id-card" })
          ], -1 /* HOISTED */)),
          _createElementVNode("h5", _hoisted_4, _toDisplayString($setup.t('menu.dj')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["CardContainer"], null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.djs, (dj, index) => {
                  return (_openBlock(), _createBlock($setup["SmallProfileCard"], {
                    key: dj.id,
                    style: _normalizeStyle($setup.bodyWithBorderStyle),
                    "cover-image-url": dj?.pictureUrl,
                    name: dj.artisticName,
                    "country-flag-image-url": require(`@/assets/flags/${dj.flag}.png`),
                    "country-name": dj.country_full_name,
                    "text-color": $setup.bodyTXTColor
                  }, null, 8 /* PROPS */, ["style", "cover-image-url", "name", "country-flag-image-url", "country-name", "text-color"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}