import { defineComponent as _defineComponent } from 'vue'
import { ComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import moment from "moment";
import { useRouter } from 'vue-router';

export default /*@__PURE__*/_defineComponent({
  __name: 'BookButton',
  props: {
    eventUrlId: {
        type: String,
        default: ''
    },
    style: {
        type: Object,
        default: () => {}
    },
    event: {
        type: Object,
        default: () => null
    }
},
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter()
const { t } = useI18n()

const props = __props;

const shortDateFomat = (seconds: number) => {
  const time = moment.unix(seconds).utc()
  const dayNumber = time.format('D')
  const month = t('month_short.' + time.format('MMM'))

  const currentYear = new Date().getFullYear().toString()
  const formattedYear = time.format('YYYY')
  const year = (currentYear === formattedYear) ? '' : ` ${formattedYear}`

  return `${month} ${dayNumber}${year}`
}

const eventUrlId: ComputedRef<string> = computed(() => props.eventUrlId);
const style: ComputedRef<Object> = computed(() => props.style);
const startDateTime: ComputedRef<number> = computed(() => props.event?.from?.seconds || 0);
const bookingsStartDateTime: ComputedRef<number> = computed(() => props.event?.bookings?.seconds || 0);
const bookingsOpenDateTime: ComputedRef<number> = computed(() => props.event?.open?.seconds || 0);
const bookingsEndDateTime: ComputedRef<number> = computed(() => props.event?.close?.seconds || Infinity);
const eventEndsDateTime: ComputedRef<number> = computed(() => props.event?.to?.seconds || Infinity);

const isBookSoon: ComputedRef<boolean> = computed(() => {
  const today = Math.floor(Date.now() / 1000)
  return today < bookingsStartDateTime.value || today < bookingsOpenDateTime.value
})

const isBookNow: ComputedRef<boolean> = computed(() => {
  const today = Math.floor(Date.now() / 1000)
  return bookingsStartDateTime.value < today && bookingsOpenDateTime.value < today && today < bookingsEndDateTime.value
})

const isTicketsAvailableAtTheDoor: ComputedRef<boolean> = computed(() => {
  const today = Math.floor(Date.now() / 1000)
  return bookingsEndDateTime.value < today && today < eventEndsDateTime.value
})


const label: ComputedRef<String> = computed(() => {
  if (!startDateTime.value || !bookingsEndDateTime.value) {
    return t('book_now').toUpperCase()
  } else {
    if (isBookSoon.value) {
      return t('booking_starts_on', { date: shortDateFomat(bookingsStartDateTime.value) })
    } else {
      if (isBookNow.value) {
        return t('book_now').toUpperCase()
      } else if (isTicketsAvailableAtTheDoor.value) {
        return t('booking_closed_buy_tickets_at_the_door')
      } else {
        return ''
      }
    }
  }
});

const isDisabled: ComputedRef<boolean> = computed(() => {
  return !isBookNow.value
});

const bookingEnabled: ComputedRef<Boolean> = computed(() => {
    const today = Math.floor(Date.now() / 1000)
    return today < eventEndsDateTime.value
});

function handleClick() {
  if (!isDisabled.value) {
    router.push( `/event/${eventUrlId.value}/booking`)
    return 
  }
}

const __returned__ = { router, t, props, shortDateFomat, eventUrlId, style, startDateTime, bookingsStartDateTime, bookingsOpenDateTime, bookingsEndDateTime, eventEndsDateTime, isBookSoon, isBookNow, isTicketsAvailableAtTheDoor, label, isDisabled, bookingEnabled, handleClick }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})