
import { EventsData } from "@/model/EventsData";
import { SponsoredData } from "@/model/SponsoredData";
import { EventsRequest } from "@/model/events/EventsRequest";
import { EventsResponse } from "@/model/events/EventsResponse";
import axios from "@/axios";
import { CampaignModel } from "@/model/CampaignModel";

export interface RegularEventsResponse {
    regularEvents: EventsData[],
    sponsoredEvents: SponsoredData[],
    sectionsById: Map<string, EventsData[]>,
    sections: Sections[],
    campaigns: CampaignModel[],
}

export enum Sections {
    SOCIAL = 'SOCIAL',
    VACATIONS = 'VACATIONS',
    PRIVATES = 'PRIVATES',
    CLASS = 'CLASS',
    BEGINNERS = 'BEGINNERS',
    SPECIAL = 'SPECIAL',
    SHOW = 'SHOW',
    HIGHLIGHTED = 'HIGHLIGHTED'
}

export enum EventTypes {
    VACATIONS = 'VACATIONS',
    RETREAT = 'RETREAT',
    PRIVATE = 'PRIVATE',
    CLASS = 'CLASS',
    BEGINNERS = 'BEGINNERS',
    MILONGA_CLASS = 'MILONGA_CLASS', // to be depecaated soon
    PRACTICA_CLASS = 'PRACTICA_CLASS', // to be depecaated soon
    MILONGA = 'MILONGA',
    PRACTICA = 'PRACTICA',
    WORKSHOP = 'WORKSHOP',
    EXPO = 'EXPO',
    FAIR = 'FAIR',
    CHAMPIONSHIP = 'CHAMPIONSHIP',
    FESTIVAL_CHAMPIONSHIP = 'FESTIVAL_CHAMPIONSHIP',
    SHOW = 'SHOW',
    DINNER_SHOW = 'DINNER_SHOW',
    CONCERT = 'CONCERT',
    MARATHON = 'MARATHON',
    FESTIVAL = 'FESTIVAL',
    ENCUENTRO = 'ENCUENTRO',
    FESTIVAL_MARATHON = 'FESTIVAL_MARATHON',
}

function containsAny (eventTypes: string[]) {
    const normalizedEventTypes = (eventTypes).map((item: string) => item.toUpperCase() as EventTypes)
    return {
        in: (sectionTypes: EventTypes[]) => {
            const result = sectionTypes.some(type => normalizedEventTypes.includes(type))
            return result
        }
    }
}

export const fetchAllEvents = async (payload?: EventsRequest): Promise<RegularEventsResponse> => {
    // Map of validators for different sections
    const sectionValidators = new Map<Sections, (event: EventsData) => boolean>([
        [Sections.SOCIAL, (event: EventsData) => containsAny(event.types).in([
            EventTypes.PRACTICA, EventTypes.MILONGA, EventTypes.FESTIVAL, EventTypes.MARATHON,
            EventTypes.ENCUENTRO, EventTypes.CHAMPIONSHIP, EventTypes.EXPO, EventTypes.FAIR,
            EventTypes.MILONGA_CLASS, EventTypes.PRACTICA_CLASS // Todo remove these when deprecated
        ])],
        [Sections.VACATIONS, (event: EventsData) => containsAny(event.types).in([EventTypes.VACATIONS, EventTypes.RETREAT])],
        [Sections.PRIVATES, (event: EventsData) => containsAny(event.types).in([EventTypes.PRIVATE])],
        [Sections.CLASS, (event: EventsData) => containsAny(event.types).in([
            EventTypes.CLASS,  EventTypes.WORKSHOP, 
            EventTypes.MILONGA_CLASS, EventTypes.PRACTICA_CLASS, // Todo remove these when deprecated
        ])],
        [Sections.BEGINNERS, (event: EventsData) => containsAny(event.types).in([
            EventTypes.BEGINNERS
        ]) || event.beginners],
        [Sections.SPECIAL, (event: EventsData) => containsAny(event.types).in([
            EventTypes.EXPO, EventTypes.FAIR, EventTypes.CHAMPIONSHIP
        ])],
        [Sections.SHOW, (event: EventsData) => containsAny(event.types).in([EventTypes.SHOW, EventTypes.DINNER_SHOW, EventTypes.CONCERT])],
        [Sections.HIGHLIGHTED, (event: EventsData) => event.display?.toUpperCase() === Sections.HIGHLIGHTED ],
    ])

    const params = payload ? { ...payload, 'userId': payload.userId } : {}
    const response = await axios.get<EventsResponse>('events', { params })
    const sectionsById = new Map<string, EventsData[]>()
    Object.values(Sections).forEach(section => sectionsById.set(section, []));
    console.log('Events: ', response.data)
    const events = response.data.results

    // Categorizing events based on validator functions
    events.forEach(event => {
        let added = false
        sectionValidators.forEach((validator, section) => {
            if (validator(event)) {
                sectionsById.get(section)?.push(event);
                added = true
            }
        });
        if (!added) {
            sectionsById.get(Sections.HIGHLIGHTED)?.push(event);
        }
    });

    console.log('Sections By Id', sectionsById)
    const sections = response.data.sections.map((item: string) => item as Sections)
    console.log('Sections', sections)

    return {
        regularEvents: response.data.results,
        sponsoredEvents: response.data.sponsored,
        campaigns: response.data.campaigns,
        sectionsById,
        sections,
    }
}

export const fetchRegularEvents = async (payload?: EventsRequest): Promise<RegularEventsResponse> => {
    const params = payload ? { ...payload, 'userId': payload.userId } : {};
    const fetch = await axios.get<EventsResponse>('events/0', {
        params: params
    });
    return {
        regularEvents: fetch.data.results,
        sponsoredEvents: fetch.data.sponsored,
        sectionsById: new Map<string, EventsData[]>(),
        sections: [],
        campaigns: fetch.data.campaigns,
    }
}

export const fetchEventNuevos = async (payload?: EventsRequest) => {
    if (payload?.country == '') delete payload?.country
    if (payload?.region == '') delete payload?.region
    return await axios.get<EventsResponse>('events/carousel/NEW', {
        params: payload
    })
}

export const fetchEventDestacados = async (payload?: EventsRequest) => {
    if (payload?.country == '') delete payload?.country
    if (payload?.region == '') delete payload?.region
    return await axios.get<EventsResponse>('events/carousel/HIGHLIGHTED', {
        params: payload
    })
}

export const fetchEventPrincipiantes = async (payload?: EventsRequest) => {
    if (payload?.country == '') delete payload?.country
    if (payload?.region == '') delete payload?.region
    return await axios.get<EventsResponse>('events/carousel/BEGINNERS', {
        params: payload
    })
}


export const fetchcampaignEvents = async (payload?: EventsRequest) => {
    if (payload?.country == '') delete payload?.country
    if (payload?.region == '') delete payload?.region
    return await axios.get<EventsResponse>('marketing/campaigns', {
        params: payload
    })
}

export const getEventGateways = async (sessionId: string, currency: string) => {
    const response = await axios.get<any>(`payments/gateways/${sessionId}`, {
        params: {
            currency
        }
    })
    return response.data
}

export const getEventMinified = async (eventId: string, country: string) => {
    const countryPath = country ? `/${country}` : ''
    const response = await axios.get<any>(`events/seo${countryPath}/${eventId}`)
    return response.data.results
}

export const getReviewForm = async (eventId: string, userId: string, country?: string) => {
    const countryParam = country ? `&country=${country}` : ''
    const response = await axios.get<any>(`reviews/event/${eventId}?userId=${userId}${countryParam}`)
    return response.data
}

export const submitReviewForm = async (payload: any) => {
    const response = await axios.post<any>(`reviews`, {
        userId: payload.userId,
        eventId: payload.eventId,
        fullName: payload.fullName,
        anonymous: payload.anonymous,
        share: payload.share,
        locationRate: payload.locationRate,
        organisationRate: payload.organisationRate,
        levelRate: payload.levelRate,
        artistRate: payload.artistRate,
        djRate: payload.djRate,
        benefitRate: payload.benefitRate,
        comment: payload.comment
    })
    return response.data
}

