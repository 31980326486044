import { defineStore } from 'pinia'
import { ref, computed, Ref, markRaw } from 'vue'
import axios from 'axios'
import moment from 'moment';
import { useCartStore } from './cart-store'
import { useEventStore } from './event-store'
import { useUserStore } from './user-store'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import { useI18n } from "vue-i18n";
import { useCountriesOptions } from '@/composable/useCountriesOptions';
import stepRegistration from '@/components/event/booking/stepRegistration.vue';
import stepSelection from '@/components/event/booking/stepSelection.vue';
import stepComplete from '@/components/event/booking/stepComplete.vue';
import stepPayment from '@/components/event/booking/stepPayment.vue';

export interface PartnerForm {
  fullname: string
  email: string
  residency: any,
}

export const STEPS = {
  REGISTRATION: {
      id: 'Registration',
      label: 'steps_pages.registration',
      component: markRaw(stepRegistration),
  },
  SELECTION: {
      id: 'Selection',
      label: 'steps_pages.booking',
      component: markRaw(stepSelection),
  },
  PAYMENT: {
      id: 'Payment',
      label: 'steps_pages.payment',
      component: markRaw(stepPayment),
  },
  COMPLETE: {
      id: 'Complete',
      label: 'steps_pages.confirmation',
      component: markRaw(stepComplete),
  }
}

export const useEventBookingStore = defineStore('eventBooking', () => {

  const sessionId: Ref<string> = ref('')
  const sessionTimeCountDown: Ref<number> = ref(0)
  const sessionData: Ref<any> = ref(null)
  const promoCode: Ref<string> = ref('')
  const promoDiscount: Ref<number> = ref(0)
  const isLoading: Ref<boolean> = ref(false)
  const defaultBookingItem: Ref<string> = ref('')
  const isValidDefaultBookingItem: Ref<boolean> = ref(true)

  const bookingSteps: Ref<any[]> = ref([])
  const currentBookingStep: Ref<number> = ref(0)
  const bookingItems: Ref<any[]> = ref([])

  // Registration Form
  const registrationFormAnswers: Ref<any> = ref({})
  const selectedRole: Ref<string> = ref('')
  const isPartnerSelected: Ref<boolean> = ref(false)
  const partnerForm: Ref<PartnerForm> = ref({
    fullname: '',
    email: '',
    residency: '',
  })
  const selectedPaymentType: Ref<any> = ref(null)
  const pendingPaymentSuccess: Ref<boolean> = ref(false)
  const paymentSuccess: Ref<boolean> = ref(false)

  const enableRoleSelection = computed(() => {
    const eventStore = useEventStore()
    return eventStore.getRole != 'NONE'
  })

  const roleOptions = computed(() => {
    const eventStore = useEventStore()
    const { t } = useI18n();
    const showLeaderRadioButton = ['COUPLE','SINGLE', 'LEADER', 'LEADER_COUPLE'].includes(eventStore.getRole)
    const showFollowerRadioButton = ['COUPLE','SINGLE', 'FOLLOWER', 'FOLLOWER_COUPLE'].includes(eventStore.getRole)
    const options = []
    if (showLeaderRadioButton) {
      options.push({
        id: 'LEADER',
        label: t('registration_page.leader')
      })
    }
    if (showFollowerRadioButton) {
      options.push({
        id: 'FOLLOWER',
        label: t('registration_page.follower')
      })
    }
    return options
  })
  
  const enablePartnerSelection = computed(() => {
    const eventStore = useEventStore()
    return ['COUPLE', 'BOTH', 'LEADER_COUPLE', 'FOLLOWER_COUPLE'].includes(eventStore.getRole)
  })

  const registrationFormAnswersList = computed(() => {
    const answers: any[] = [];
    const questionsIdList = Object.keys(registrationFormAnswers.value)
    questionsIdList.forEach((questionId) => {
        const answerObj = registrationFormAnswers.value[questionId]
        answers.push(answerObj)
    })
    console.log('Ansers: ', answers)
    return answers
  })

  const missingRequiredQuestionsList = computed(() => {
    const eventStore = useEventStore()
    const missingRequiredAnswers: any[] = [];
    console.log('Checking required answers...')
    eventStore.mandatoryQuestions.forEach((question: any) => {
        console.log('Question: ', question)
        const answerObj = registrationFormAnswers.value[question.id]
        console.log('Answer: ', answerObj)
        console.log('Value: ', answerObj?.answer)
        if (!answerObj?.answer) {
            missingRequiredAnswers.push(question.id)
        }
    })
    return missingRequiredAnswers
  })

  const sessionTime = computed(() => {
    return moment.utc(sessionTimeCountDown.value*1000).format('mm:ss');
  })

  const getPromoCode = computed(() => promoCode.value)

  const getBookingSteps = computed(() => bookingSteps.value)

  const getBookingItems = computed(() => {
    let eventBookingItems
    const eventStore = useEventStore()
    console.log(`Loading booking items: Event Process == ${eventStore.event?.process}`)
    if (eventStore.event?.process == 'BOOKING') {
      eventBookingItems = eventStore.event.booking.items || []
    } else {
      eventBookingItems = bookingItems.value || []
    }
    console.log('Event Booking items: ', eventBookingItems)
    return eventBookingItems.filter((item: any) => !item.disabled)
  })

  const bookingItemsById = computed(() => {
    return getBookingItems.value.reduce((acc: any, item: any) => {
      acc = (item.potentials || []).reduce((acc: any, potentialItem: any) => ({ ...acc, [potentialItem.activityId]: potentialItem}), acc)
      return { ...acc, [item.id]: item}
    }, {})
  })

  const checkoutItems = computed(() => {
    const cartStore = useCartStore()
    return cartStore.getItems.reduce((acc: any, curr: any) => {
      acc.push({
          id: curr.id,
          type: curr.type,
      });
      return acc
    }, []) 
  })

  const quantitiesByItemId = computed(() => {
    const cartStore = useCartStore()
    return cartStore.getItems.reduce((acc: any, curr: any) => {
      acc[curr.id] = curr.count
      return acc
    }, {}) 
  })

  const claimedPotentialsByItemId = computed(() => {
    const cartStore = useCartStore()
    return cartStore.getItems.reduce((acc: any, curr: any) => {
      if (curr.selected_potentional_items) {
        acc[curr.id] = curr.selected_potentional_items.map((x: any) => x.activityId)
      }
      return acc
    }, {}) 
  })

  const isNumber = (value: any) => {
		return typeof value === "number" && !Number.isNaN(value);
	}

  const getFilteredEventPacksByCurrency = computed(() => {
    const cartStore = useCartStore()
    if (cartStore.availableCurrencies.length) {
      return getBookingItems.value.filter((item: any) => {
        return isNumber(item.prices[cartStore.getCurrency]) //Check that price is valid number for the selected currency
      })
    } else {
      return getBookingItems.value
    }
  })


  const getEventGroupBySection = computed(() => {
    const itemsBySection: Map<string, any[]> = new Map()
    
    getFilteredEventPacksByCurrency.value.forEach((item: any) => {
      if (!itemsBySection.get(item.section)) {
        itemsBySection.set(item.section, [])
      }
      itemsBySection.get(item.section)?.push(item)
    })
    console.log('Items by Section: ', itemsBySection)
    return itemsBySection
  })

  const isCryptoPayment = computed(() => {
    return selectedPaymentType.value?.method === 'CRYPTO'
  })

  const getCryptoRate = computed(() => {
    return selectedPaymentType.value?.cryptoDetails?.rate || 1
  })

  async function loadBookingSession() {
    sessionId.value = localStorage.getItem('sessionId') || ''
    isLoading.value = true
    const route = useRoute()
    if (route.query.itemId) {
      defaultBookingItem.value = route.query.itemId as string
    }

    setupBookingWorkflow()

    // currentBookingStep.value = (localStorage.getItem('currentBookingStep') || 0) as number

    let isPaymentCompleted = false
    if (route.query.sessionId) {
      console.log('Session Id (Local Storage): ', sessionId.value)
      if (sessionId.value && (route.query.sessionId !== sessionId.value)) {
        console.log('invalid session')
        // const Toast = useToast();
        // Toast.error('Session invalid')
        setSessionId('') // maybe reset booking store?
      } else {
        setSessionId(route.query.sessionId as string)
        const isPayedByQueryParams = route.query.orderStatus == 'PAID' || route.query.orderStatus == 'COMPLETED'
        const paymentStatusResponse = await axios.get(`${process.env.VUE_APP_BASE_URL}payments/v2/status/${route.query.sessionId}`)
        console.log('Booking Status: ', paymentStatusResponse) // PAID | PENDING_PAYMENT | CANCELLED | PAYMENT_REJECTED
        const isPayedInServer = paymentStatusResponse.data?.results == 'PAID' || paymentStatusResponse.data?.results == 'COMPLETED'

        if (isPayedByQueryParams || isPayedInServer) {
          isPaymentCompleted = true
        } else {
          isPaymentCompleted = false
        }
      }
    } else {
      const shouldRedirectToCompleteStep = route.query.e || route.query.m
      isPaymentCompleted = !!shouldRedirectToCompleteStep
    }


    if (isPaymentCompleted) {
      paymentSuccess.value = true
      goToBookingStep(getBookingSteps.value.length - 1)
    } else {
      paymentSuccess.value = false

      const availableCoupon = route.query.coupon || localStorage.getItem('coupon')
      if (availableCoupon ) {
        setPromoCode(availableCoupon as string)
      }

      const cartStore = useCartStore()
      cartStore.setDefaultSelectedCurrency()
      const eventStore = useEventStore()
      const { getCountryOptionById } = useCountriesOptions()
      partnerForm.value.residency = getCountryOptionById(eventStore.event.country)
      if (!defaultBookingItem.value) {
        loadRegistrationFormAnswers()
      }  

      // TODO load cart items from session in localStorage (Done in CartStore!)
    }
    isLoading.value = false
    
  }

  function setPromoCode(value: string) {
    promoCode.value = value
    localStorage.setItem('coupon', promoCode.value)
  }

  function loadRegistrationFormAnswers () {
    const eventStore = useEventStore()
    registrationFormAnswers.value = {}
    eventStore.getRegistrationForm.forEach((item: any) => {
      registrationFormAnswers.value[item.id] = {
          questionId: item.id,
          type: item.type,
          answer: (eventStore.savedRegistrationFormAnswersById.value || {})[item.id] || null,
      }
    })
  }

  function setupBookingWorkflow () {
    const eventStore = useEventStore()
    const process = eventStore.event?.process
    if (process === 'BOOKING') {
      bookingSteps.value = [
          ...(defaultBookingItem.value ? [] : [STEPS.SELECTION]),
          STEPS.REGISTRATION,
          STEPS.PAYMENT,
          STEPS.COMPLETE,
      ]
    } else { // process === 'REGISTRATION_BOOKING'
      bookingSteps.value = [
          STEPS.REGISTRATION,
          ...(defaultBookingItem.value ? [] : [STEPS.SELECTION]),
          STEPS.PAYMENT,
          STEPS.COMPLETE
      ]
    }
  }

  function setBookingItems(value: any) {
    bookingItems.value = value
    useCartStore().setDefaultSelectedCurrency()
  }

  function goToNextBookingStep() {
    if (currentBookingStep.value < (bookingSteps.value.length - 1)) {
      currentBookingStep.value++
      localStorage.setItem('currentBookingStep', currentBookingStep.value.toString())
    }
  }

  function goToPrevBookingStep() {
    if (currentBookingStep.value > 0) {
      currentBookingStep.value--
      localStorage.setItem('currentBookingStep', currentBookingStep.value.toString())
    }
  }

  function goToBookingStep(value: number) {
    if (value >= 0 && value < bookingSteps.value.length) {
      currentBookingStep.value = value
      localStorage.setItem('currentBookingStep', currentBookingStep.value.toString())
    }
  }

  function goToSelectionStep () {
    // TODO cancel payment?
    if (useEventStore().event?.process == 'BOOKING') {
      goToBookingStep(0)
    } else {
      goToPrevBookingStep()
    }
  }

  function setSessionData(data: any) {
    sessionData.value = data
  }

  function setSessionId(id: string) {
    console.log('Set session Id: ', id)
    sessionId.value = id
    localStorage.setItem('sessionId', id)
  }

  function reset() {
    console.log('Booking store reset...')
    promoDiscount.value = 0
    promoCode.value = ''
    registrationFormAnswers.value = {}
    selectedRole.value = ''
    partnerForm.value = {
      fullname: '',
      email: '',
      residency: '',
    }
    paymentSuccess.value = false
    pendingPaymentSuccess.value = false
    goToBookingStep(0)
    setSessionId('')
    setPromoCode('')
    const cartStore = useCartStore()
    cartStore.clear()
    setupBookingWorkflow()
    cartStore.setDefaultSelectedCurrency()
    loadRegistrationFormAnswers()
  }

  function endSession() {
    promoDiscount.value = 0
    promoCode.value = ''
    registrationFormAnswers.value = {}
    selectedRole.value = ''
    partnerForm.value = {
      fullname: '',
      email: '',
      residency: '',
    }
    setSessionId('')
    const cartStore = useCartStore()
    cartStore.clear()
  }

  async function clearCart() {
    console.log('Clear cart...')
    const cartStore = useCartStore()
    cartStore.clear()
    if (sessionId.value) {
      await updateSession()
    }
  }

  async function createSession(payload: any) {
    const userStore = useUserStore()
    const eventStore = useEventStore()

    // if (!VueCookies.get("sessionId")) { // TODO reuse active session if sessionId present in localStorage
    try {
      isLoading.value = true
      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}sessions`, payload)
      console.log('Session created! ', response)
      if (!userStore.isLoggedIn) {
        console.log('Guest user: ', response?.data?.results?.t1?.userId)
        console.log('Set guest user')
        userStore.setGuestUser({
          id: response?.data?.results?.t1?.userId,
          firstName: payload.user.name,
          fullName: payload.user.fullName,
          lastName: payload.user.lastName,
          email: payload.user.email,
          nationality: payload.user.nationality,
          residency: payload.user.residency,
        })
      }
      
      console.log('CreateSession Response: ', response)
      if (eventStore.event?.process !== 'BOOKING') {
        console.log('Update booking items: ')
        setBookingItems(response.data?.results?.t2?.items)
      } else {
        console.log('When process is booking start timer')
        setSessionData(response.data.results);
      }
      if (defaultBookingItem.value) {
        const bookingItem = bookingItemsById.value[defaultBookingItem.value]
        isValidDefaultBookingItem.value = bookingItem && !bookingItem.disabled && bookingItem.maxQty > 0
        const cartStore = useCartStore()
        if (isValidDefaultBookingItem.value && !cartStore.isActivityInCart(bookingItem)) {
            cartStore.addToCart(bookingItem)
        }
      }
      setSessionId(response.data.results.t1.id);
      return response    
    } catch (error: any) {
      console.error('Error catched in create session: ', error)
      throw {
        message: 'Bad Request',
        code: error.response?.data?.responseCode
      }
    } finally {
      isLoading.value = false
    }
    /* } else {
        return VueCookies.get("sessionId")
    } */
  }

  async function updateSession() {
    const cartStore = useCartStore()
    const eventStore = useEventStore()
    const eventBookingStore = useEventBookingStore() 

    const userStore = useUserStore()
    const payload: {
      domain: string,
      eventId: any,
      id: string,
      userId: string,
      form: any,
      checkoutIds: string[],
      quantities: any,
      claimed: any,
      currency: string,
      partners?: any,
      status: "CREATED",
      role: string,
      platform: string,
      locale: string,
    } = {
        domain: eventStore.event.domain,
        eventId: eventStore.getEventIdRequestBody,
        id: sessionId.value,
        userId: userStore.user.id, 
        form: eventBookingStore.registrationFormAnswersList,
        checkoutIds: checkoutItems.value,
        quantities: quantitiesByItemId.value,
        claimed: claimedPotentialsByItemId.value,
        currency: cartStore.getCurrency,
        status: "CREATED",
        role: eventBookingStore.selectedRole || 'NONE',
        platform: eventStore.isEmbeddedEventMode ? 'EMBEDDED' : "VUE",
        locale: localStorage.getItem('language') || navigator.language || 'en'
    }
    if (isPartnerSelected.value) {
      payload.partners = [{
        fullName: eventBookingStore.partnerForm.fullname,
        email: eventBookingStore.partnerForm.email,
        residency: eventBookingStore.partnerForm.residency.value,
        nationality: eventBookingStore.partnerForm.residency.value,
      }]
    }
    isLoading.value = true
    const response = await axios.put(`${process.env.VUE_APP_BASE_URL}sessions`, payload)
    console.log('Respose: ', response)
    if (response?.data?.responseCode == 200) {
      console.log('Response code  = 200')
      setSessionData(response.data.results);
      isLoading.value = false
    } else {
      isLoading.value = false
      const error = new Error('Bad Request') as any
      error.code = response?.data?.responseCode
      throw error
    }
  }

  async function applyPromoCode () {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}coupons/apply/${sessionId.value}/${promoCode.value}`)
    if (response.data.responseCode == 200) {  
      promoDiscount.value = response.data.results
      return promoDiscount.value
    } else {
      throw new Error('Invalid error code.')
    }
  }

  function clearPromocode() {
    promoDiscount.value = 0
    promoCode.value = ''
  }

  function selectPaymentType(value: any | null) {
    selectedPaymentType.value = value
  }

  async function pendingPayment(gatewayId?: string) {
    const queryParams: {
      gatewayId?: string,
      promoCode?: string,
    } = {
      gatewayId,
      promoCode: promoCode.value
    };

    // Filter out undefined values
    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value !== undefined)
    );

    const queryString = new URLSearchParams(filteredParams).toString();
    const finalQueryString = queryString ? `?${queryString}` : '';
    
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}payments/v2/pendingPayment/${sessionId.value}${finalQueryString}`)
    if(response.data.responseCode == 200) {
        pendingPaymentSuccess.value = true
        return response.data
    } else {
        throw new Error('Error processing payment. Please contact support.')
    }
  }

  async function createPayment(gatewayId?: string) {
    const payload: {
      sessionId: string,
      gatewayId?: string,
      promoCode?: string,
    } = {
        sessionId: sessionId.value,
        gatewayId: gatewayId,
    };
    if (promoCode.value) {
      payload.promoCode = promoCode.value
    }
    try {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}payments/v2/create`, payload)
        paymentSuccess.value = true
        return response.data?.results
    } catch (error: any) {
        if (error?.response?.data?.responseCode == 500) {
            throw new Error(error?.response?.data?.message)
        } else {
            throw new Error('Error processing payment. Please contact support.')
        }
    }
  }

  async function cancelPayment(confirmationId?: string) {
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}payments/v2/cancelPayment/${sessionId.value}${confirmationId ? `?confirmationId=${confirmationId}` : ``}`)
    return response.data
  }

  return {
    sessionId,
    sessionTime,
    sessionTimeCountDown,
    promoCode,
    promoDiscount,
    selectedPaymentType,
    pendingPaymentSuccess,
    paymentSuccess,
    isLoading,
    defaultBookingItem,
    isValidDefaultBookingItem,
    registrationFormAnswers,
    registrationFormAnswersList,
    missingRequiredQuestionsList,
    selectedRole,
    enableRoleSelection,
    roleOptions,
    enablePartnerSelection,
    isPartnerSelected,
    partnerForm,
    isCryptoPayment,
    getCryptoRate,
    getBookingSteps, currentBookingStep, goToNextBookingStep, goToPrevBookingStep, goToBookingStep, goToSelectionStep,
    getBookingItems, bookingItemsById, getFilteredEventPacksByCurrency, getEventGroupBySection,
    getPromoCode,
    setSessionId,
    setBookingItems,
    reset,
    endSession,
    clearCart,
    loadBookingSession,
    createSession,
    updateSession,
    applyPromoCode,
    clearPromocode,
    selectPaymentType,
    pendingPayment,
    createPayment,
    cancelPayment,
  }
})
