import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "accordion__item" }
const _hoisted_2 = {
  key: 0,
  class: "radio-container"
}
const _hoisted_3 = {
  key: 0,
  class: "fa-solid fa-circle-check"
}
const _hoisted_4 = {
  key: 1,
  class: "fa-regular fa-circle"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "accordion__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion__trigger", { 'accordion__trigger_active': $setup.visible }]),
      style: _normalizeStyle($setup.style),
      onClick: $setup.open
    }, [
      ($setup.isRadio)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            ($setup.visible)
              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
              : (_openBlock(), _createElementBlock("i", _hoisted_4))
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.title), 1 /* TEXT */))
        : _renderSlot(_ctx.$slots, "header", { key: 2 }, undefined, true),
      (!$setup.isRadio)
        ? (_openBlock(), _createElementBlock("i", {
            key: 3,
            class: _normalizeClass(["fa-solid icon fa-angle-down", { 'icon-up': $setup.visible }])
          }, null, 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 6 /* CLASS, STYLE */),
    _createVNode(_Transition, {
      name: "accordion",
      onEnter: $setup.start,
      onAfterEnter: $setup.end,
      onBeforeLeave: $setup.start,
      onAfterLeave: $setup.end,
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.visible]
        ])
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}