import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import countriesData from '@/json/countries.json';
import CardContainer from '@/components/CardContainer.vue';
import SmallProfileCard from '@/components/profile/SmallProfileCard.vue'
import { useEventStore } from '@/store/event-store';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'artists',
  setup(__props, { expose: __expose }) {
  __expose();

const eventStore = useEventStore();
const { t } = useI18n();

const countriesByCode = computed(() => (countriesData.countries.reduce((acc, curr) => {
    acc[curr.code] = curr
    return acc
}, {})));

const bodyWithBorderStyle = computed(() => ({
    'border': `1px solid ${eventStore.eventStyle.selectedTXT}`,
    'background-color': eventStore.eventStyle.bodyBG,
    'color': eventStore.eventStyle.bodyTXT
}));

const eventCardColorAndBackgroundStyle = computed(() => ({
    'color': eventStore.eventStyle.bodyTXT, 
    'background': eventStore.eventStyle.bodyBG
}));

const bodyTXTColor = computed(() => eventStore.eventStyle.bodyTXT)
const performers = computed(() => {
    return eventStore.visiblePerformers.filter((performer) => {
        return !(performer.type[0] == 'DJ')
    }).reduce((acc, curr) => {
        let country = countriesByCode.value[curr.birthCountry]
        if(country){
            curr.flag = country.flag;
            curr.country_full_name = country.name;
        }
        acc.push(curr)
        return acc
    }, []);
});
const djs = computed(() => {
    return eventStore.visibleDjs.filter((dj) => {
        return dj.type[0] == 'DJ' && dj.show == true // TODO Fix show true filter
    }).reduce((acc, curr) => {
        let country = countriesByCode.value[curr.birthCountry]
        if(country){
            curr.flag = country.flag;
            curr.country_full_name = country.name;
        }
        acc.push(curr)
        return acc
    }, []);
});


const __returned__ = { eventStore, t, countriesByCode, bodyWithBorderStyle, eventCardColorAndBackgroundStyle, bodyTXTColor, performers, djs, CardContainer, SmallProfileCard }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})