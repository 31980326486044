import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "event-logo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "event-date-line" }
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = { class: "event-name cursor-pointer" }
const _hoisted_6 = { class: "event-location-line" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "event-cta hide-on-mobile" }
const _hoisted_11 = { style: {"display":"flex","justify-content":"end"} }
const _hoisted_12 = { class: "blink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "event-navbar",
      style: _normalizeStyle({
    'background': $setup.eventStore.eventStyle.headerBG,
    'color': $setup.eventStore.eventStyle.headerTXT
  })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: { path: '/event/' + $setup.eventUrlId }
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "event-logo-img",
              src: $setup.event.logoUrl,
              alt: ""
            }, null, 8 /* PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString($setup.shortDate?.toUpperCase()) + " | ", 1 /* TEXT */),
        _createElementVNode("span", null, _toDisplayString($setup.shortTime?.toUpperCase()), 1 /* TEXT */)
      ]),
      _createElementVNode("a", {
        href: $setup.linkUrl,
        target: "_blank",
        rel: "noopener noreferrer nofollow",
        class: "event-name-line",
        title: $setup.linkLabel
      }, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString($setup.event.name), 1 /* TEXT */)
      ], 8 /* PROPS */, _hoisted_4),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", null, _toDisplayString($setup.t(`event_types.${$setup.event.type}`).toUpperCase()), 1 /* TEXT */),
        ($setup.event.address)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " | "))
          : _createCommentVNode("v-if", true),
        ($setup.event.address)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _createTextVNode(_toDisplayString($setup.event.address) + " ", 1 /* TEXT */),
              ($setup.event.address && $setup.event.location)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: `https://www.google.com/maps/search/?api=1&query=${$setup.event.location.latitude}%2C${$setup.event.location.longitude}`,
                    style: {"padding-left":".5rem"}
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("i", { class: "fa-solid fa-location-dot" }, null, -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_9))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        ($setup.shouldDisplayTicketsSection)
          ? (_openBlock(), _createBlock($setup["BookButton"], {
              key: 0,
              "event-url-id": $setup.eventUrlId,
              style: _normalizeStyle({
                    'color': $setup.eventStore.eventStyle.buttonTXT,
                    'background-color': $setup.eventStore.eventStyle.buttonBG
                  }),
              event: $setup.event
            }, null, 8 /* PROPS */, ["event-url-id", "style", "event"]))
          : _createCommentVNode("v-if", true)
      ])
    ], 4 /* STYLE */),
    ($setup.shouldDisplayTicketsSection)
      ? (_openBlock(), _createBlock($setup["MobileFloatingFooter"], {
          key: 0,
          class: "show-on-mobile",
          color: $setup.eventStore.eventStyle.headerTXT,
          "background-color": $setup.eventStore.eventStyle.headerBG
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              ($setup.shouldDisplayTicketsSection)
                ? (_openBlock(), _createBlock($setup["BookButton"], {
                    key: 0,
                    "event-url-id": $setup.eventUrlId,
                    style: _normalizeStyle({
                    'color': $setup.eventStore.eventStyle.buttonTXT,
                    'background-color': $setup.eventStore.eventStyle.buttonBG
                  }),
                    event: $setup.event
                  }, null, 8 /* PROPS */, ["event-url-id", "style", "event"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["color", "background-color"]))
      : _createCommentVNode("v-if", true),
    ($setup.eventStore.isBookingEnabled)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "container",
          style: _normalizeStyle({'background': $setup.eventStore.eventStyle.headerBG})
        }, [
          _createElementVNode("button", {
            class: "btn blink-btn",
            style: _normalizeStyle($setup.paymentuserBtnobj)
          }, [
            _createElementVNode("span", _hoisted_12, _toDisplayString($setup.t('bookingStatus.' + ($setup.userStore.isLoggedIn ? $setup.getUserPaymentStatus : $setup.event.banner))), 1 /* TEXT */)
          ], 4 /* STYLE */)
        ], 4 /* STYLE */)), [
          [_vShow, false]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}