import { defineComponent as _defineComponent } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
    import 'vue3-carousel/dist/carousel.css'
    import CoverImage from '../../CoverImage.vue'
    import { computed, ref, onMounted } from 'vue'
import { CampaignModel } from '@/model/CampaignModel'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CarouselComponent',
  props: {
    loading: { type: Boolean, required: true },
    events: { type: Array, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

    const props = __props;

    const events = computed(() => props.events)
    const loading = computed(() => props.loading)


    const getEventUrlId = (event: any) => {
        return event.region ? `${event.country}-${event.id}` : event.id
    }

    const mounted = ref(false)

    onMounted(() => {
      setTimeout(() => {
        mounted.value = true
      }, Math.random() * (500 - 300) + 300)
    })


const __returned__ = { props, events, loading, getEventUrlId, mounted, get Carousel() { return Carousel }, get Slide() { return Slide }, get Navigation() { return Navigation }, CoverImage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})