import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, onMounted } from 'vue'
import ProfilesService, { ProfilesInterface } from '@/services/profiles.service'
import ProfileCard from '@/components/profile/ProfileCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'profile',
  props: {
    id: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();


const props = __props

const id = computed <string> (() => {
  return props.id
})

const loading = ref(true)
const error = ref('')
const profile: Ref<ProfilesInterface | null> = ref(null) 

const loadData = async () => {
  loading.value = true
  try {
    profile.value = await ProfilesService.fetchProfile(id.value)
  } catch (e) {
    error.value = 'Something went wrong when loading page'
  }
  loading.value = false
}

onMounted(() => {
  loadData()
})


const __returned__ = { props, id, loading, error, profile, loadData, ProfileCard }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})