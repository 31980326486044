import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4 text-justify text-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.paymentMethodData.wuDetails.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.paymentMethodData.wuDetails.description), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["BankDetailsRow"], {
      label: "Bank Name",
      value: $setup.paymentMethodData.wuDetails.name
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Email",
      value: $setup.paymentMethodData.wuDetails.email
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Country",
      value: $setup.paymentMethodData.wuDetails.country
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Address",
      value: $setup.paymentMethodData.wuDetails.address
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Id Card",
      value: $setup.paymentMethodData.wuDetails.idCard
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Postal Code",
      value: $setup.paymentMethodData.wuDetails.postCode
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Phone Number",
      value: $setup.paymentMethodData.wuDetails.phone
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Account Holder",
      value: $setup.paymentMethodData.wuDetails.accountHolder
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Account Number",
      value: $setup.paymentMethodData.wuDetails.accountNumber
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "IBAN",
      value: $setup.paymentMethodData.wuDetails.iban
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Swift",
      value: $setup.paymentMethodData.wuDetails.swift
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Sort Code",
      value: $setup.paymentMethodData.wuDetails.sortCode
    }, null, 8 /* PROPS */, ["value"]),
    _createElementVNode("div", {
      class: "message-wrapper",
      style: _normalizeStyle($setup.messageWrapperStyle)
    }, _toDisplayString($setup.t('payment.payment_link_message')), 5 /* TEXT, STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}