import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return ($setup.eventStore.isLoading)
    ? (_openBlock(), _createBlock($setup["PotsLoader"], { key: 0 }))
    : ($setup.event)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle($setup.eventBackgroundColor)
        }, [
          ($setup.showHeaderAndFooter)
            ? (_openBlock(), _createBlock($setup["EventHeader"], {
                key: 0,
                "event-url-id": $setup.urlId
              }, null, 8 /* PROPS */, ["event-url-id"]))
            : _createCommentVNode("v-if", true),
          (!$setup.isBookingPage)
            ? (_openBlock(), _createBlock($setup["EventLinksBar"], {
                key: 1,
                "event-url-id": $setup.urlId
              }, null, 8 /* PROPS */, ["event-url-id"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_router_view),
          (!$setup.showHeaderAndFooter)
            ? (_openBlock(), _createBlock($setup["PotsFooter"], { key: 2 }))
            : _createCommentVNode("v-if", true)
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
}