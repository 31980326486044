import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted } from "vue";
import { ColorInputWithoutInstance } from "tinycolor2";
import { useToast } from "vue-toastification";
import { useEventStore } from "@/store/event-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'visuals',
  setup(__props, { expose: __expose }) {
  __expose();


const eventStore = useEventStore()

const headerBG = ref<ColorInputWithoutInstance>("");
const headerTXT = ref<ColorInputWithoutInstance>("");
const bodyBG = ref<ColorInputWithoutInstance>("");
const bodyTXT = ref<ColorInputWithoutInstance>("");
const bodyTAB = ref<ColorInputWithoutInstance>("");
const buttonBG = ref<ColorInputWithoutInstance>("");
const buttonTXT = ref<ColorInputWithoutInstance>("");
const selectedTXT = ref<ColorInputWithoutInstance>("");
const background = ref<ColorInputWithoutInstance>("");
const saving = ref(false)

const buttonStyle = computed(() => {
    return {
        'color': eventStore.eventStyle.buttonTXT,
        'background-color': eventStore.eventStyle.buttonBG
    }
})

onMounted(() => {
    headerBG.value = eventStore.eventStyle.headerBG
    headerTXT.value = eventStore.eventStyle.headerTXT
    bodyBG.value = eventStore.eventStyle.bodyBG
    bodyTXT.value = eventStore.eventStyle.bodyTXT
    bodyTAB.value = eventStore.eventStyle.bodyTAB
    buttonBG.value = eventStore.eventStyle.buttonBG
    buttonTXT.value = eventStore.eventStyle.buttonTXT
    selectedTXT.value = eventStore.eventStyle.selectedTXT
    background.value = eventStore.eventStyle.background
})

const save = async () => {

    const parseColorName = (color: string) => {
        return '0xFF' + (color[0] === '#' ? color.slice(1) : color) 
    }

    const colors = {
        headerBG: parseColorName(headerBG.value),
        headerTXT: parseColorName(headerTXT.value),
        bodyBG: parseColorName(bodyBG.value),
        bodyTXT: parseColorName(bodyTXT.value), 
        bodyTAB: parseColorName(bodyTAB.value), 
        buttonBG: parseColorName(buttonBG.value), 
        buttonTXT: parseColorName(buttonTXT.value), 
        selectedTXT: parseColorName(selectedTXT.value),
        background: parseColorName(background.value)
    }
    try {
        saving.value = true
        await eventStore.updateEventColors(colors)
        saving.value = false
    } catch (error) {
        const Toast = useToast()
        Toast.error('Unable to save');
    }
}


const __returned__ = { eventStore, headerBG, headerTXT, bodyBG, bodyTXT, bodyTAB, buttonBG, buttonTXT, selectedTXT, background, saving, buttonStyle, save }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})