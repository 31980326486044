import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"width":"100%","background-color":"#343434","padding":"1rem","font-size":".7rem"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-evenly","flex-wrap":"wrap","gap":".5rem","align-items":"center","color":"white","text-align":"center"} }
const _hoisted_3 = { class: "footer-text-container hide-on-mobile" }
const _hoisted_4 = {
  key: 0,
  style: {"text-decoration":"underline","text-align":"left"}
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "nav-link",
  "aria-current": "page",
  href: "https://points-of-tango.web.app/register-event",
  target: "_blank"
}
const _hoisted_8 = {
  class: "nav-link",
  "aria-current": "page",
  href: "https://points-of-tango.web.app/register-page",
  target: "_blank"
}
const _hoisted_9 = {
  class: "nav-link",
  "aria-current": "page",
  href: "https://points-of-tango.web.app/register-store",
  target: "_blank"
}
const _hoisted_10 = { class: "pots-section" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { href: "https://www.pointsoftango.com/terms-and-conditions" }
const _hoisted_13 = { href: "https://www.pointsoftango.com/privacy-policy" }
const _hoisted_14 = { href: "https://www.pointsoftango.com/disclaimer" }
const _hoisted_15 = { class: "links-section" }
const _hoisted_16 = {
  href: "https://www.facebook.com/pointsoftango",
  "aria-label": "pointOfTango-link"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  href: "https://www.instagram.com/pointsoftango/?hl=es",
  "aria-label": "instagram-link"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  href: "https://twitter.com/pointsoftango",
  "aria-label": "twitter-link"
}
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  href: "https://wa.me/447751672159",
  "aria-label": "whatsapp-link"
}
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  href: "https://www.youtube.com/@pointsoftangoenglish8474",
  "aria-label": "youtube-link"
}
const _hoisted_25 = ["src"]
const _hoisted_26 = ["href"]
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "app-buttons" }
const _hoisted_29 = {
  href: "https://apps.apple.com/us/app/points-of-tango/id1580307505#?platform=iphone",
  "aria-label": "appstore-link"
}
const _hoisted_30 = ["src"]
const _hoisted_31 = {
  href: "https://play.google.com/store/apps/details?id=com.pointsoftango.android",
  "aria-label": "play-store-link"
}
const _hoisted_32 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (($setup.mainStore.homeCountryCode) && ($setup.mainStore.homeRegionCode))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                class: "nav-link",
                "aria-current": "page",
                href: $setup.mainStore.getRegistrationLink,
                target: "_blank"
              }, _toDisplayString($setup.t('createEvent')), 9 /* TEXT, PROPS */, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("a", _hoisted_7, _toDisplayString($setup.t('createEvent')), 1 /* TEXT */)
            ])),
        _createElementVNode("div", null, [
          _createElementVNode("a", _hoisted_8, _toDisplayString($setup.t('createProfProfile')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", _hoisted_9, _toDisplayString($setup.t('createStore')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", null, [
          _cache[0] || (_cache[0] = _createTextVNode("©2023 by Points of Tango | ")),
          _createElementVNode("small", null, "v" + _toDisplayString($setup.version), 1 /* TEXT */)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", { href: $setup.mailToLink }, "info@pointsoftango.app", 8 /* PROPS */, _hoisted_11)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("a", _hoisted_12, _toDisplayString($setup.t('termsConditions')), 1 /* TEXT */),
          _cache[1] || (_cache[1] = _createTextVNode(" | ")),
          _createElementVNode("a", _hoisted_13, _toDisplayString($setup.t('privacyPolicy')), 1 /* TEXT */),
          _cache[2] || (_cache[2] = _createTextVNode(" | ")),
          _createElementVNode("a", _hoisted_14, _toDisplayString($setup.t('disclaimer')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("a", _hoisted_16, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_facebook.svg'),
            alt: "facebook-svg",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_17)
        ]),
        _createElementVNode("a", _hoisted_18, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_instagram.svg'),
            alt: "instagram-svg",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_19)
        ]),
        _createElementVNode("a", _hoisted_20, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_twitter.svg'),
            alt: "twitter-svg",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_21)
        ]),
        _createElementVNode("a", _hoisted_22, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_whatsapp.svg'),
            alt: "whatsapp-svg",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_23)
        ]),
        _createElementVNode("a", _hoisted_24, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_youtube.svg'),
            alt: "youtube-svg",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_25)
        ]),
        _createElementVNode("a", {
          href: $setup.mailToLink,
          "aria-label": "mail-link"
        }, [
          _createElementVNode("img", {
            src: require('@/assets/icons/icon_mail.svg'),
            alt: "icon-mail",
            style: {"height":"25px"}
          }, null, 8 /* PROPS */, _hoisted_27)
        ], 8 /* PROPS */, _hoisted_26)
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("a", _hoisted_29, [
          _createElementVNode("img", {
            class: "app-btn",
            src: require('@/assets/btn_appstore.png'),
            sizes: "(max-width: 600px) 100vw, 50vw",
            alt: "app-img",
            style: {"width":"100%","object-fit":"contain"}
          }, null, 8 /* PROPS */, _hoisted_30)
        ]),
        _createElementVNode("a", _hoisted_31, [
          _createElementVNode("img", {
            class: "app-btn",
            src: require('@/assets/btn_google_play.png'),
            sizes: "(max-width: 600px) 100vw, 50vw",
            alt: "Download from Play Store",
            style: {"width":"100%","object-fit":"contain"}
          }, null, 8 /* PROPS */, _hoisted_32)
        ])
      ])
    ])
  ]))
}