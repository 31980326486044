import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, watch, onMounted } from 'vue'
    import Separator from './Separator.vue'
    import DropdownComponent, { type OptionProps } from '@/design-system/DropdownComponent.vue'
    import SeparatorV from './SeparatorV.vue'
    import moment, { Moment } from 'moment'
    import { RegionFilter } from '@/interfaces'
    import DateDropdown from './Filters_components/DateDropdown.vue'
    import { useMainStore } from '@/store/main-store'
    import { useI18n } from 'vue-i18n'
    import InputDropdown from '@/design-system/InputDropdown.vue'
    import FormItemInput from '@/design-system/FormItemInput.vue'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'Filters',
  props: {
    isInternational: { type: Boolean, required: true },
    location: { type: String, required: true },
    startingPosition: { type: Number, required: true }
  },
  emits: ["region-changed", "month-changed", "day-changed", "go-to-map"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

    const mainStore = useMainStore()
    const { t } = useI18n()

    const emit = __emit

    const selectedCountry = ref < OptionProps | null > (null)
    const selectedRegion = ref < OptionProps | null > (null)
    const selectedMonth = ref < OptionProps | null > (null)
    const searchQuery = ref < string > ('')
    const regionsOptions = ref<Array<OptionProps>> ([])

    const props = __props

    const isInternational = computed <Boolean> (() => props.isInternational)
    const initialLocation = computed <string> (() => props.location)
    const startingPosition = computed <number> (() => props.startingPosition)

    watch(searchQuery, (newQuery) => {
        mainStore.eventSearchQuery = newQuery
    })

    onMounted(() => {
        selectedMonth.value = { id: 'All', label: t('all') };
        //for on load if country and region selected
        if (initialLocation.value) {
            const jsonFilePath = require('@/json/locations.json')
            const selectedCountryRegion = jsonFilePath.find((city: any) => city.path === initialLocation.value)
            let countryId = selectedCountryRegion?.country
            const regionId = selectedCountryRegion?.region
            const regions = countriesMap.value[countryId]?.regions || []
            if (countryId) {
                selectedCountry.value = {
                    id: countryId,
                    label: t('countries.' + countryId),
                }
                handleCountrySelected(selectedCountry.value)
                if (regionId) {
                    selectedRegion.value = {
                        id: regionId,
                        label: regions[regionId],
                    }
                    handleRegionSelected(selectedRegion.value)
                } else {
                    selectedCountry.value = {
                        id: 'Intl',
                        label: t('international'),
                    }
                    handleCountrySelected(selectedCountry.value)
                }
            }
        } else {
            selectedCountry.value = {
                id: 'Intl',
                label: t('international'),
            } 
        }

    })

    const isShowFilter = computed(() => {
        return !searchQuery.value && selectedCountry.value?.id && selectedCountry.value?.id !== 'Intl'
    })

    const handleCountrySelected = (value: OptionProps) => {
        if (value) {
            const regions = countriesMap.value[value.id]?.regions || [];
            regionsOptions.value.length = 0
            Object.keys(regions).forEach((key) => {
                regionsOptions.value.push({
                    id: key,
                    label: regions[key]
                })
            })
        }

        if (selectedCountry.value?.id === 'Intl' && !initialLocation.value) {
            const payload: RegionFilter = {
                region: '',
                country: 'Internacional'
            }
            emit('region-changed', payload)
        }
        mainStore.homeCountryCode = selectedCountry.value?.id || ''

        if (regionsOptions.value.length == 1) {
            selectedRegion.value = regionsOptions.value[0]
            handleRegionSelected(regionsOptions.value[0])
        } else {
            selectedRegion.value = null
        }
    }

    const handleRegionSelected = (value: OptionProps) => {
        const payload: RegionFilter = {
            region: value?.id || '',
            country: selectedCountry.value?.id || '',
        }

        emit('region-changed', payload)
        mainStore.homeRegionCode = value?.id
    }

    const handleMonthSelected = (value: OptionProps) => {
        const newMonth = moment(value.id).format('MMMYY')
        mainStore.homeMonthCode = newMonth
        emit('month-changed', newMonth, 'month')
    }

    const countriesMap = computed(() => {
        return require('@/json/regions.json');
    })

    const countriesOptions = computed(() => {
        const countriesList = Object.keys(countriesMap.value)
        const countriesOptions = countriesList.reduce((acc, countryId) => ([
            ...acc,
            {
                id: countryId,
                label: t('countries.' + countryId),
            }
        ]), [] as Array<OptionProps>)

        countriesOptions.unshift({
            id: 'Intl',
            label: t('international'),
        })
        return countriesOptions
    })

    const monthsOptions = computed(() => {
        const monthObj = new Date()
        let months: OptionProps[] = []

        for (let i = 0; i < 12; ++i) {
            const month = monthObj.toLocaleString('en-US', { month: 'long' })
            const year = monthObj.toLocaleString('en-US', { year: 'numeric' })
            months.push({
                id: `${month} ${year}`,
                label: `${t(month)} ${year}`,
            })
            monthObj.setMonth(monthObj.getMonth() + 1)
        }

        // Add the "All" option at the beginning of the months array
        months.unshift({
            id: 'All',
            label: t('All')
        });
        return months
    })

    const totalDays = ref<number>(14)

    const weekOptions: ComputedRef<{ status: string; date: Moment }[]> = computed(() => {

        moment.updateLocale('en', {
            week: {
                dow: 0
            }
        })

        const startOfWeek = moment().startOf('week')
        const daysOfWeek: { status: string; date: Moment }[] = []
        const today = moment().startOf('day')
        for (let i = 1; i < totalDays.value; i++) {
            const currentDay = moment(startOfWeek).add(i, 'days')
            const status = weekStatus(currentDay)
            if (currentDay.isAfter(today.subtract(1, 'day')) || currentDay.isSame(today) || status === 'active' || status === 'regular') {
                daysOfWeek.push({
                    status,
                    date: currentDay
                })
            }
        }

        return daysOfWeek
    })

    function weekStatus(current: Moment): string {
        const today = moment().startOf('day')
        const selected = mainStore.selectedDay.startOf('day')
        if (current.isSame(selected)) {
            return 'active'
        } else if (current.isBefore(selected) || current.isBefore(today)) {
            return 'past'
        }

        return 'regular'
    }

    function next() {
        document?.getElementById('esta-semana-container')?.scrollBy({
            left: 800,
            behavior: 'smooth'
        })

        totalDays.value += 7
    }

    function previous() {
        const containerElement = document.getElementById('esta-semana-container');
        if (containerElement) {
            const scrollAmount = containerElement.offsetWidth;
            containerElement.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        }
    }

    function handleDaySelected(payload: { status: string; date: Moment }) {
        mainStore.selectedDay = payload.date
        let month = payload.date.format('MMMYY')
        emit('day-changed')
        emit('month-changed', month, 'day')
        scrollToFilterSection()
    }

    function goToMap() {
        emit('go-to-map')
    }

    function scrollToFilterSection() {
        console.log('Scrolling... ', startingPosition.value)
        window.scrollTo({top: startingPosition.value, behavior: 'smooth'});
    }

const __returned__ = { mainStore, t, emit, selectedCountry, selectedRegion, selectedMonth, searchQuery, regionsOptions, props, isInternational, initialLocation, startingPosition, isShowFilter, handleCountrySelected, handleRegionSelected, handleMonthSelected, countriesMap, countriesOptions, monthsOptions, totalDays, weekOptions, weekStatus, next, previous, handleDaySelected, goToMap, scrollToFilterSection, get moment() { return moment }, InputDropdown, FormItemInput }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})