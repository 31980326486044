import { createRouter, createWebHistory } from 'vue-router'
import EventDetailPage from '@/pages/event/:eventId/index.vue'
import ArtistsPage from '@/pages/event/:eventId/artists.vue'
import ProgrammePage from '@/pages/event/:eventId/programme.vue'
import FavoritePage from '@/pages/event/:eventId/favorite.vue'
import Visuals from '@/pages/event/:eventId/visuals.vue'
import UserPage from '@/pages/user.vue'
import BookingPage from '@/pages/event/:eventId/booking.vue'
import LandingPage from '@/pages/index.vue'
import Index from '@/pages/event/index.vue'
import ProfilePage from '@/pages/profile.vue'
import ProfilesPage from '@/pages/profiles.vue'
import PaymentLinkSumup from '@/pages/payment-link/sumup.vue'
import ReviewEvent from '@/pages/review/event.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: LandingPage,
        meta: {
            // Add your metadata here
            title: 'Home Page',
            description: 'This is the description of the home page.',
            // Add any other metadata properties you need
        },
    },
    {
        path: '/:name',
        name: 'Home1',
        component: LandingPage,
        props: route => ({ location: route.params.name || '' })
    },
    {
        path: '/user',
        name: 'user',
        component: UserPage
    },
    {
        path: '/profiles',
        name: 'profiles-page',
        component: ProfilesPage,
    },
    {
        path: '/profile/:id',
        name: 'profile-page',
        component: ProfilePage,
        props: route => ({ id: route.params.id })
    },
    {
        path: '/event/:id', //id can contain country-eventId or just eventId for international events
        name: 'EventIndex',
        component: Index,
        props: route => {
            if (route.params.id.includes('-')) {
                return {
                    urlId: route.params.id, 
                    id: route.params.id.split('-')[1],
                    country: route.params.id.split('-')[0]
                }
            } else {
                return {
                    urlId: route.params.id,
                    id: route.params.id,
                    country: ''
                }
            }         
        },
        children: [
            {
                path: '',
                name: 'EventDetailPage',
                component: EventDetailPage,
            },
            {
                path: 'artists',
                name: 'ArtistsPage',
                component: ArtistsPage
            },
            {
                path: 'programme',
                name: 'ProgrammePage',
                component: ProgrammePage
            },
            {
                path: 'booking',
                name: 'booking',
                component: BookingPage
            },
            {
                path: 'favorites',
                name: 'favorites',
                component: FavoritePage
            },
            {
                path: 'visuals',
                name: 'visuals',
                component: Visuals,
                // TODO check if user in organizers
            }
        ],
    },
    {
        path: '/embedded/event/:id', //id can contain country-eventId or just eventId for international events
        name: 'EmbeddedBookingPage',
        component: Index,
        props: route => {
            if (route.params.id.includes('-')) {
                return {
                    urlId: route.params.id, 
                    id: route.params.id.split('-')[1],
                    country: route.params.id.split('-')[0],
                    embedded: true
                }
            } else {
                return {
                    urlId: route.params.id,
                    id: route.params.id,
                    country: '',
                    embedded: true
                }
            }         
        },
        children: [
            {
                path: 'booking',
                alias: '',
                name: 'EmbeddedBookingPage',
                component: BookingPage,
            }
        ],
    },
    {
        path: '/payment-link/sumup',
        name: 'payment-link-sumup',
        component: PaymentLinkSumup,
        props: route => ({
            checkoutId: route.query.checkoutId || '',
            sessionId: route.query.sessionId || '',
            close: route.query.close === 'true',
            merchantName: route.query.mName || '',
            debug: route.query.debug === 'true',
            locale: route.query.locale,
        })
    },
    {
        path: '/review/event',
        name: 'reviewEvent',
        component: ReviewEvent,
        props: route => ({
            eventId: route.query.event || '',
            userId: route.query.user || '',
            country: route.query.country || '',
            locale: route.query.locale,
        })
    },
    {
        path: '/review/event/:id',
        name: 'reviewEvent',
        component: ReviewEvent,
        props: route => {
            if (route.params.id.includes('-')) {
                return {
                    eventId: route.params.id.split('-')[1],
                    country: route.params.id.split('-')[0],
                    userId: route.query.user || '',
                    locale: route.query.locale,
                }
            } else {
                return {
                    eventId: route.params.id,
                    country: '',
                    userId: route.query.user || '',
                    locale: route.query.locale,
                }
            }         
        }
    },
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
