import { defineStore } from 'pinia'
import { ref, Ref, computed } from 'vue'
import { useI18n } from 'vue-i18n';
import moment, { Moment } from 'moment';

export const useMainStore = defineStore('mainStore', () => {

  const { locale: i18nLocale  } = useI18n();

  const languageSelected: Ref<string> = ref(localStorage.getItem('language') || process.env.VUE_APP_LOCALE_LANG || 'en')
  
  const homeRegionCode: Ref<string> = ref('')
  const homeCountryCode: Ref<string> = ref('')
  const homeMonthCode: Ref<string> = ref('')
  const selectedDay: Ref<Moment> = ref(moment())
  const eventSearchQuery: Ref<string> = ref('')


  const getRegistrationLink = computed(() => {
    const country = homeCountryCode.value
    const region = homeRegionCode.value
    const baseURL = "https://points-of-tango.web.app/register-event";
    return (country && region) ? `${baseURL}?country=${country}&region=${region}` : baseURL;
  })

  function init () {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const queryParams = Object.fromEntries(params);
    const languageParam = queryParams.lang as string
    if (['en', 'es', 'it', 'fr', 'ru'].includes(languageParam)) {
      changeLanguage(languageParam)
    }
  }

  function changeLanguage(newValue: string) {
    if (languageSelected.value !== newValue) {
      languageSelected.value = newValue;
      i18nLocale.value = newValue;
      localStorage.setItem('language', newValue);
    }
  };

  init()

  return {
    languageSelected,
    homeRegionCode, homeCountryCode, homeMonthCode,
    selectedDay, eventSearchQuery,
    changeLanguage,
    getRegistrationLink,
  }
})