import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "color-form-container" }
const _hoisted_2 = { class: "color-form-row" }
const _hoisted_3 = { class: "color-form-row" }
const _hoisted_4 = { class: "color-form-row" }
const _hoisted_5 = { class: "color-form-row" }
const _hoisted_6 = { class: "color-form-row" }
const _hoisted_7 = { class: "color-form-row" }
const _hoisted_8 = { class: "color-form-row" }
const _hoisted_9 = { class: "color-form-row" }
const _hoisted_10 = { class: "color-form-row" }
const _hoisted_11 = { class: "color-form-row color-form-footer" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_color_picker = _resolveComponent("color-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[9] || (_cache[9] = _createTextVNode(" Header background: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.headerBG,
        "onUpdate:pureColor": _cache[0] || (_cache[0] = ($event: any) => (($setup.headerBG) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[10] || (_cache[10] = _createTextVNode(" Header Text: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.headerTXT,
        "onUpdate:pureColor": _cache[1] || (_cache[1] = ($event: any) => (($setup.headerTXT) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[11] || (_cache[11] = _createTextVNode(" Body background: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.bodyBG,
        "onUpdate:pureColor": _cache[2] || (_cache[2] = ($event: any) => (($setup.bodyBG) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[12] || (_cache[12] = _createTextVNode(" Body Text: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.bodyTXT,
        "onUpdate:pureColor": _cache[3] || (_cache[3] = ($event: any) => (($setup.bodyTXT) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[13] || (_cache[13] = _createTextVNode(" Body Tabs: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.bodyTAB,
        "onUpdate:pureColor": _cache[4] || (_cache[4] = ($event: any) => (($setup.bodyTAB) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[14] || (_cache[14] = _createTextVNode(" Button Background: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.buttonBG,
        "onUpdate:pureColor": _cache[5] || (_cache[5] = ($event: any) => (($setup.buttonBG) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[15] || (_cache[15] = _createTextVNode(" Button Text: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.buttonTXT,
        "onUpdate:pureColor": _cache[6] || (_cache[6] = ($event: any) => (($setup.buttonTXT) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[16] || (_cache[16] = _createTextVNode(" Selected Text: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.selectedTXT,
        "onUpdate:pureColor": _cache[7] || (_cache[7] = ($event: any) => (($setup.selectedTXT) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[17] || (_cache[17] = _createTextVNode(" Background: ")),
      _createVNode(_component_color_picker, {
        pureColor: $setup.background,
        "onUpdate:pureColor": _cache[8] || (_cache[8] = ($event: any) => (($setup.background) = $event)),
        format: "hex",
        shape: "square",
        "disable-alpha": ""
      }, null, 8 /* PROPS */, ["pureColor"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        class: "btn",
        disabled: $setup.saving,
        style: _normalizeStyle($setup.buttonStyle),
        onClick: $setup.save
      }, _toDisplayString($setup.saving ? 'Saving...' : 'Save'), 13 /* TEXT, STYLE, PROPS */, _hoisted_12)
    ])
  ]))
}