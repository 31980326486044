import { onMounted, onUnmounted } from 'vue';

export function useInterval(callback: () => void, config?: {
  startOnMount?: boolean,
  time: 1000,
}) {
  let timer: any = null;
  
  function start() {
    stop();
    timer = setInterval(callback, config?.time || 1000);
  }

  function stop() {
    if (timer !== null) {
      clearInterval(timer);
      timer = null;
    }
  }

  onMounted(() => {
    if (config?.startOnMount) {
      start()
    }
  });
  
  onUnmounted(stop);

  return { start, stop };
}