import { createI18n } from "vue-i18n";

import en from "@/i18n/locale/en"
import ru from "@/i18n/locale/ru"
import it from "@/i18n/locale/it"
import es from "@/i18n/locale/es"
import fr from "@/i18n/locale/fr"

const messages = {
  en: {
      ...en
  },
  ru: {
    ...ru
  },
  it: {
    ...it
  },
  es: {
    ...es
  },
  fr: {
    ...fr
  }
}

let locale = process.env.VUE_APP_LOCALE_LANG || 'en'
if (localStorage.getItem('language')) {
  locale = localStorage.getItem('language');
} else {
  locale = navigator.lenguage || 'en'
}

export default createI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_LOCALE_LANG || 'en',
  legacy: false, // <--- 3
  messages,
  globalInjection: true
})