import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FormItem"], {
    style: _normalizeStyle($props.style),
    "is-required": $setup.isRequired,
    placeholder: $props.placeholder,
    "error-message": ($setup.forcedValidation || $setup.visited) && $setup.errorMessage || '',
    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleFormItemClick()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        ref: "inputRef",
        value: $setup.value,
        type: 'date',
        disabled: $setup.isDisabled,
        style: _normalizeStyle($props.style),
        class: "form-item-input",
        onBlur: $setup.onBlur,
        onFocus: $setup.onFocus,
        onClick: $setup.handleClick,
        onInput: $setup.handleInputChange
      }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1),
      _createElementVNode("i", {
        class: "calendar-icon fa fa-calendar",
        "aria-hidden": "true",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleClick()))
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "is-required", "placeholder", "error-message"]))
}