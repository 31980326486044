import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, StyleValue } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormItem',
  props: {
    placeholder: { type: String, required: true },
    isRequired: { type: Boolean, required: false },
    hideLabel: { type: Boolean, required: false },
    errorMessage: { type: String, required: false },
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true }
  },
  emits: ["click"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;


// define emits
const emit = __emit;

const placeholder: ComputedRef<string> = computed(() => props.placeholder || '');
const isRequired: ComputedRef<boolean> = computed(() => props.isRequired);
const errorMessage: ComputedRef<string> = computed(() => props.errorMessage || '');
const style: ComputedRef<StyleValue | null> = computed(() => props.style || null);
const hideLabel: ComputedRef<boolean> = computed(() => props.hideLabel);


const __returned__ = { props, emit, placeholder, isRequired, errorMessage, style, hideLabel }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})