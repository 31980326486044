import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "line-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "line-container",
    style: _normalizeStyle($props.style)
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "horizontal-line" }, null, -1 /* HOISTED */)),
    ($setup.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.text), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "horizontal-line" }, null, -1 /* HOISTED */))
  ], 4 /* STYLE */))
}