import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, onMounted } from 'vue'
import countriesData from '@/json/countries.json';
import linkifyHtml from 'linkify-html';
import { useI18n } from "vue-i18n";
import CardContainer from '@/components/CardContainer.vue';
import SmallProfileCard from '@/components/profile/SmallProfileCard.vue'
import CoverImage from '@/components/CoverImage.vue'
import BookButton from '@/components/event/BookButton.vue'
import EventDateTime from '@/components/EventDateTime.vue';
import { useEventStore } from '@/store/event-store';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const eventStore = useEventStore();
const { t } = useI18n();

const countriesByCode = computed(() => countriesData.countries.reduce((acc, curr) => {
    acc[curr.code] = curr
    return acc
}, {}))

const eventCardColorAndBackgroundStyle = computed(() => ({
    'color': eventStore.eventStyle.bodyTXT, 
    'background': eventStore.eventStyle.bodyBG
}));

const event = computed(() => eventStore.event);
const eventUrlId = computed(() => eventStore.eventUrlId);
const organisersDetails = computed(() => {
    const organizersNoAdmins = event.value?.organisersDetails?.filter((o) => !o.admin) || [];
    return organizersNoAdmins.reduce((acc, curr) => {
        let country = countriesByCode.value[curr.nationality]
        if (country){
            curr.flag = country.flag;
            curr.country_full_name = country.name;
        }
        acc.push(curr)
        return acc
    }, []);
});
const shouldDisplayTicketsSection = computed(() => eventStore.isBookingEnabled)
const fromDate = computed(() => event.value?.from?.seconds)
const toDate = computed(() => event.value?.to?.seconds)
const styleObject = computed(() => ({ 'border': `1px solid ${eventStore.eventStyle.buttonBG}` }))

function sanitizeContent(content: string) {
    if(content) {
        const linkedContent = linkifyHtml(content, {
            defaultProtocol: 'http',
            attributes: {
            rel: 'nofollow',
            target: '_blank'
            }
        });
        return linkedContent;
    } else {
        return "Description is not available";
    }
}

const __returned__ = { eventStore, t, countriesByCode, eventCardColorAndBackgroundStyle, event, eventUrlId, organisersDetails, shouldDisplayTicketsSection, fromDate, toDate, styleObject, sanitizeContent, CardContainer, SmallProfileCard, CoverImage, BookButton, EventDateTime }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})