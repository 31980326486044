import { defineComponent as _defineComponent } from 'vue'
import { useEventStore } from '@/store/event-store';
  import { useEventBookingStore } from '@/store/booking-store';
  import { onMounted, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'booking',
  setup(__props, { expose: __expose }) {
  __expose();

  const { t } = useI18n();

  const eventStore = useEventStore()
  const eventBookingStore = useEventBookingStore()
  
  const stepLabelTextStyle = computed(() => ({
    color: eventStore.eventStyle.bodyTXT,
    opacity: '.5',
  }));

  const activeStepLabelTextStyle = computed(() => ({
    color: eventStore.eventStyle.selectedTXT,
    opacity: '1',
  }));

  const activeStepStyle = computed(() => ({
    background: eventStore.eventStyle.buttonBG,
    color: eventStore.eventStyle.buttonTXT,
  }));

  const stepStyle = computed(() => ({
    background: eventStore.eventStyle.background,
    color: eventStore.eventStyle.bodyTXT,
  }));

  onMounted(() => {
    eventBookingStore.loadBookingSession();
  });

const __returned__ = { t, eventStore, eventBookingStore, stepLabelTextStyle, activeStepLabelTextStyle, activeStepStyle, stepStyle }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})