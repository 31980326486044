import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { useEventStore } from '@/store/event-store';
import BankDetailsRow from './BankDetailsRow.vue';
import { useI18n } from 'vue-i18n';

export default /*@__PURE__*/_defineComponent({
  __name: 'CryptoPaymentMethodSection',
  props: {
    paymentMethodData: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const eventStore = useEventStore()

const props = __props;

const paymentMethodData = computed(() => props.paymentMethodData)

const messageWrapperStyle = computed(() => ({'border': `1px solid ${eventStore.eventStyle.selectedTXT}`}))


const __returned__ = { t, eventStore, props, paymentMethodData, messageWrapperStyle, BankDetailsRow }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})