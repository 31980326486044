import axios from "@/axios";
import { ProfileModel, ProfileResponse, ProfilesResponse } from "@/model/ProfileModel";

export enum ProfilesTypesEnum {
  ORCHESTRA = 'ORCHESTRA',
  PROFESSIONAL = 'PROFESSIONAL',
  DJ = 'DJ',
  TAXI_DANCER = 'TAXI_DANCER',
  MUSICIAN = 'MUSICIAN',
}

export const ProfilesTypesEnumMap: Map<string, ProfilesTypesEnum> = new Map()
ProfilesTypesEnumMap.set('ORCHESTRA', ProfilesTypesEnum.ORCHESTRA)
ProfilesTypesEnumMap.set('PROFESSIONAL', ProfilesTypesEnum.PROFESSIONAL)
ProfilesTypesEnumMap.set('DJ', ProfilesTypesEnum.DJ)
ProfilesTypesEnumMap.set('TAXI_DANCER', ProfilesTypesEnum.TAXI_DANCER)
ProfilesTypesEnumMap.set('MUSICIAN', ProfilesTypesEnum.MUSICIAN)

export const fetchProfiles = async (type = ProfilesTypesEnum.PROFESSIONAL): Promise<ProfileModel[]> => {
  const fetch = await axios.get<ProfilesResponse>(`${process.env.VUE_APP_BASE_URL}pages/all/${type}`)
  return fetch.data.results
}

export const fetchProfile = async (id: string): Promise<ProfileModel> => {
  const fetch = await axios.get<ProfileResponse>(`${process.env.VUE_APP_BASE_URL}pages/${id}`)
  return fetch.data.results
}

export const isFollowedProfile = async (id: string): Promise<any> => {
  const fetch = await axios.get<any>(`${process.env.VUE_APP_BASE_URL}pages/followers/${id}`)
  return fetch.data.results
}

export const followProfile = async (id: string): Promise<any> => {
  const fetch = await axios.put<any>(`${process.env.VUE_APP_BASE_URL}pages/follower/${id}`)
  return fetch.data.results
}

export const unfollowProfile = async (pageId: string, userId: string): Promise<any> => {
  const fetch = await axios.get<any>(`${process.env.VUE_APP_BASE_URL}pages/follower/${pageId}/${userId}`)
  return fetch.data.results
}

export default {
  fetchProfiles,
  fetchProfile,
  isFollowedProfile,
  followProfile,
  unfollowProfile,
}