import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted } from 'vue';
import EventHeader from '@/components/common/EventHeader.vue';
import PotsLoader from '@/design-system/pots-loader.vue';
import EventLinksBar from '@/components/common/EventLinksBar.vue';
import PotsFooter from '@/components/PotsFooter.vue';
import { useEventStore } from '@/store/event-store';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    urlId: { type: String, required: true },
    id: { type: String, required: true },
    country: { type: String, required: true },
    embedded: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { locale: i18nLocale  } = useI18n();

const eventStore = useEventStore()
const router = useRouter()

const props = __props;

const urlId = computed(() => props.urlId)
const id = computed(() => props.id)
const country = computed(() => props.country)
const embedded = computed(() => props.embedded || false)

const event = computed(() => eventStore.event)

const eventBackgroundColor = computed(() => ({
    'background-color': eventStore.eventStyle.background
}))

const isBookingPage = computed(() => {
    return router.currentRoute.value.name == 'booking' || router.currentRoute.value.name == 'EmbeddedBookingPage'
})

const isEmbeddedEventPage = computed(() => router.currentRoute.value.name?.toLowerCase().startsWith('embedded'))

const showHeaderAndFooter = computed(() => {
    return !isEmbeddedEventPage.value && !eventStore.isEmbeddedEventMode
})

onMounted(async () => {
    eventStore.setEmbeddedMode(embedded.value)
    const languageParam = router.currentRoute.value.query.lang as string

    if (['en', 'es', 'it', 'fr', 'ru'].includes(languageParam)) {
        i18nLocale.value = languageParam;
        localStorage.setItem('language', languageParam);
    }
    
    if (!event.value || event.value.id !== id.value) {
        await eventStore.loadEvent(id.value, country.value)
    }
})

const __returned__ = { i18nLocale, eventStore, router, props, urlId, id, country, embedded, event, eventBackgroundColor, isBookingPage, isEmbeddedEventPage, showHeaderAndFooter, EventHeader, PotsLoader, EventLinksBar, PotsFooter }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})