import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.showHeaderAndFooter)
      ? (_openBlock(), _createBlock($setup["Navbar"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_router_view),
    ($setup.showHeaderAndFooter)
      ? (_openBlock(), _createBlock($setup["Footer"], { key: 1 }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}