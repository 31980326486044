import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "option-container" }
const _hoisted_2 = { class: "option-container" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = {
  key: 0,
  class: "logout-section"
}
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "form-container" }
const _hoisted_12 = { class: "form-row" }
const _hoisted_13 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.mode == 'NOLOGIN')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "step-registration-options-selector",
          style: _normalizeStyle({
        'color': $setup.eventStore.eventStyle.bodyTXT,
    })
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["LoginForm"], {
              onOpenSignUpForm: $setup.openSignUp,
              onSuccess: $setup.onLogin
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["HorizontalLine"], {
              text: $setup.t('or'),
              style: _normalizeStyle({
        '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
      })
            }, null, 8 /* PROPS */, ["text", "style"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "secondary-button",
                style: _normalizeStyle($setup.secondaryButtonStyle),
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.selectGuestUser()))
              }, _toDisplayString($setup.t('continue_as_guest_user')), 5 /* TEXT, STYLE */)
            ])
          ])
        ], 4 /* STYLE */))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["FormComponent"], {
            ref: "formRef",
            name: "registrationStepForm",
            class: "form-container"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.firstName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.firstName) = $event)),
                  required: "",
                  placeholder: $setup.t('registration_page.first_name'),
                  style: _normalizeStyle({
                  '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                  '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
              })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.lastName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.lastName) = $event)),
                  required: "",
                  placeholder: $setup.t('registration_page.last_name'),
                  style: _normalizeStyle({
                  '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                  '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
              })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.email,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.email) = $event)),
                  email: "",
                  required: "",
                  placeholder: $setup.t('registration_page.email'),
                  style: _normalizeStyle({
                '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
            })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
                (!$setup.userStore.isLoggedIn || $setup.mode == 'GUEST')
                  ? (_openBlock(), _createBlock($setup["FormItemInput"], {
                      key: 0,
                      modelValue: $setup.confirm_email,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.confirm_email) = $event)),
                      email: "",
                      required: "",
                      "equal-to": $setup.email,
                      placeholder: $setup.t('registration_page.confirm_email'),
                      style: _normalizeStyle({
                '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
            })
                    }, null, 8 /* PROPS */, ["modelValue", "equal-to", "placeholder", "style"]))
                  : _createCommentVNode("v-if", true)
              ]),
              ($setup.userStore.isLoggedIn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", {
                      class: "text-btn",
                      style: _normalizeStyle({ color: $setup.eventStore.eventStyle.bodyTXT }),
                      onClick: $setup.handleLogout
                    }, _toDisplayString($setup.t('registration_page.not_you')), 5 /* TEXT, STYLE */)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_7, [
                (!$setup.userStore.isLoggedIn || $setup.mode == 'GUEST')
                  ? (_openBlock(), _createBlock($setup["InputDropdown"], {
                      key: 0,
                      modelValue: $setup.selectedResidencyOption,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.selectedResidencyOption) = $event)),
                      required: "",
                      options: $setup.countriesDropdownOptions,
                      placeholder: $setup.t('registration_page.country'),
                      disabled: $setup.userStore.isLoggedIn,
                      "search-placeholder": $setup.t('registration_page.nationalityPlaceholder'),
                      color: $setup.eventStore.eventStyle.bodyTXT,
                      "background-color": $setup.eventStore.eventStyle.bodyBG,
                      "selected-color": $setup.eventStore.eventStyle.selectedTXT
                    }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "disabled", "search-placeholder", "color", "background-color", "selected-color"]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventBookingStore.enableRoleSelection)
                  ? (_openBlock(), _createBlock($setup["FormItemRadioButton"], {
                      key: 1,
                      modelValue: $setup.eventBookingStore.selectedRole,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.eventBookingStore.selectedRole) = $event)),
                      placeholder: $setup.t('registration_page.role'),
                      options: $setup.eventBookingStore.roleOptions,
                      required: "",
                      style: _normalizeStyle({
                  '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                  '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
              }),
                      onChange: $setup.handleRoleChange
                    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "style"]))
                  : _createCommentVNode("v-if", true)
              ]),
              ($setup.eventBookingStore.enablePartnerSelection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _withDirectives(_createElementVNode("input", {
                        id: "addPartnerCheckbox",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.eventBookingStore.isPartnerSelected) = $event)),
                        type: "checkbox",
                        onInput: $setup.handleRegisterPartnerChange
                      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                        [_vModelCheckbox, $setup.eventBookingStore.isPartnerSelected]
                      ]),
                      _createElementVNode("label", {
                        for: "addPartnerCheckbox",
                        style: _normalizeStyle({'--border-color':$setup.eventStore.eventStyle.bodyTXT}),
                        class: "custom-label"
                      }, _toDisplayString($setup.t('registration_page.registerCouple')), 5 /* TEXT, STYLE */)
                    ]),
                    ($setup.eventBookingStore.isPartnerSelected)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("label", {
                            class: "question-label",
                            style: _normalizeStyle($setup.headerTXTObj)
                          }, _toDisplayString($setup.t('partner_detail')), 5 /* TEXT, STYLE */),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode($setup["FormItemInput"], {
                                modelValue: $setup.eventBookingStore.partnerForm.fullname,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.eventBookingStore.partnerForm.fullname) = $event)),
                                placeholder: $setup.t('registration_page.fullname'),
                                required: "",
                                style: _normalizeStyle({
                          '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                          '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
                      })
                              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
                              _createVNode($setup["FormItemInput"], {
                                modelValue: $setup.eventBookingStore.partnerForm.email,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.eventBookingStore.partnerForm.email) = $event)),
                                email: "",
                                placeholder: $setup.t('registration_page.email_not_required'),
                                style: _normalizeStyle({
                          '--primary-color': $setup.eventStore.eventStyle.bodyTXT,
                          '--secondary-color': $setup.eventStore.eventStyle.bodyBG,
                      })
                              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"])
                            ]),
                            _createVNode($setup["InputDropdown"], {
                              modelValue: $setup.eventBookingStore.partnerForm.residency,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.eventBookingStore.partnerForm.residency) = $event)),
                              options: $setup.countriesDropdownOptions,
                              placeholder: $setup.t('registration_page.country'),
                              "search-placeholder": $setup.t('registration_page.nationalityPlaceholder'),
                              color: $setup.eventStore.eventStyle.bodyTXT,
                              "background-color": $setup.eventStore.eventStyle.bodyBG,
                              "selected-color": $setup.eventStore.eventStyle.selectedTXT
                            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "search-placeholder", "color", "background-color", "selected-color"])
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true),
              ($setup.eventStore.getRegistrationForm)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("hr", {
                      style: _normalizeStyle({
              color: $setup.eventStore.eventStyle.bodyTXT,
              marginTop: 0,
          })
                    }, null, 4 /* STYLE */),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventBookingStore.registrationFormAnswers, (answer, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        style: {"margin":"1rem 0"}
                      }, [
                        _createVNode($setup["QuestionFormItem"], {
                          modelValue: $setup.eventBookingStore.registrationFormAnswers[answer.questionId].answer,
                          "onUpdate:modelValue": ($event: any) => (($setup.eventBookingStore.registrationFormAnswers[answer.questionId].answer) = $event),
                          label: ($setup.eventStore.showDescriptionInEnglish && $setup.eventStore.questionsById[answer.questionId].translation) ? $setup.eventStore.questionsById[answer.questionId].question : $setup.eventStore.questionsById[answer.questionId].translation,
                          required: $setup.eventStore.questionsById[answer.questionId].mandatory,
                          options: $setup.eventStore.questionsById[answer.questionId].options,
                          "translated-options": ($setup.eventStore.showDescriptionInEnglish && $setup.eventStore.questionsById[answer.questionId].translated) ? $setup.eventStore.questionsById[answer.questionId].question : $setup.eventStore.questionsById[answer.questionId].translation,
                          type: $setup.eventStore.questionsById[answer.questionId].type,
                          disabled: false && !$setup.eventStore.questionsById[answer.questionId].editable,
                          "input-style": $setup.inputStyle
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "required", "options", "translated-options", "type", "disabled", "input-style"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */)
        ])),
    ($setup.userStore.isLoggedIn || $setup.mode == 'GUEST')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "mt-3 footer-sticky",
          style: _normalizeStyle([{"width":"100%"}, {'--backgroundColor': $setup.eventStore.eventStyle.background}])
        }, [
          _createVNode($setup["BookingNavbar"], {
            "prev-navigation-hidden": $setup.eventStore.event?.process !== 'BOOKING' || !!$setup.eventBookingStore.defaultBookingItem,
            "edit-currency-disabled": $setup.eventStore.event?.process == 'BOOKING',
            onPrev: _cache[11] || (_cache[11] = ($event: any) => ($setup.prev())),
            onNext: _cache[12] || (_cache[12] = ($event: any) => ($setup.next()))
          }, null, 8 /* PROPS */, ["prev-navigation-hidden", "edit-currency-disabled"])
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("end"),
    _createVNode($setup["DialogComponent"], {
      ref: "loginModalDialog",
      "hide-header": true,
      "hide-footer": true,
      "dialog-color-style": {
        primaryColor: $setup.colours.colours.bodyTXT,
        secondaryColor: $setup.colours.colours.background,
    },
      "button-color-style": {
        primaryColor: $setup.colours.colours.buttonTXT,
        secondaryColor: $setup.colours.colours.buttonBG,
    },
      width: '800px'
    }, {
      default: _withCtx(() => [
        _createVNode($setup["SignUpLoginForm"], {
          "is-popup": true,
          "show-sign-up": $setup.showSignUpInModal,
          onClose: $setup.handleLoginPopupClose
        }, null, 8 /* PROPS */, ["show-sign-up"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["dialog-color-style", "button-color-style"]),
    _createVNode($setup["ConfirmDialog"], {
      ref: "confirmDialogRef",
      title: 'Confirm',
      message: $setup.t('registration_page.registrationInfoChangeWarning'),
      "dialog-color-style": {
          primaryColor: $setup.eventStore.eventStyle.bodyTXT,
          secondaryColor: $setup.eventStore.eventStyle.bodyBG,
      },
      "button-color-style": {
          primaryColor: $setup.eventStore.eventStyle.buttonTXT,
          secondaryColor: $setup.eventStore.eventStyle.buttonBG,
      },
      "confirm-button-label": $setup.t('button.yes'),
      "cancel-button-label": $setup.t('button.cancel'),
      onConfirm: $setup.handleConfirmation
    }, null, 8 /* PROPS */, ["message", "dialog-color-style", "button-color-style", "confirm-button-label", "cancel-button-label"])
  ], 64 /* STABLE_FRAGMENT */))
}