import { defineComponent as _defineComponent } from 'vue'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import CoverImage from '../CoverImage.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SmallProfileCard',
  props: {
    profileId: { type: String, required: false },
    avatarImageUrl: { type: String, required: false },
    name: { type: String, required: true },
    description: { type: String, required: false },
    showFollowButton: { type: Boolean, required: false },
    coverImageUrl: { type: String, required: false },
    countryFlagImageUrl: { type: String, required: false },
    countryName: { type: String, required: false },
    textColor: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const router = useRouter()

const props = __props

const avatarImageUrl = computed(() => props.avatarImageUrl)
const name = computed(() => props.name)
const profileId = computed(() => props.profileId)
const description = computed(() => props.description)
const showFollowButton = computed(() => props.showFollowButton)
const coverImageUrl = computed(() => props.coverImageUrl)
const countryFlagImageUrl = computed(() => props.countryFlagImageUrl)
const countryName = computed(() => props.countryName)
const textColor = computed(() => props.textColor || 'black')

const clickHandler = (event: Event) => {
  router.push(`/profile/${profileId.value}`)
}


const __returned__ = { router, props, avatarImageUrl, name, profileId, description, showFollowButton, coverImageUrl, countryFlagImageUrl, countryName, textColor, clickHandler, CoverImage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})