import { defineComponent as _defineComponent } from 'vue'
import { PropType, computed, ref, Ref } from 'vue'
    import type { EventsData } from '@/model/EventsData'
    import EventCard from './EventCard.vue'
    import CardContainer from '../../CardContainer.vue'
    import { useTrimText } from '@/composable/text'
    import CheckboxComponent from '@/design-system/CheckboxComponent.vue'
    import DialogComponent from '@/design-system/DialogComponent.vue';
    import { Carousel, Slide, Navigation } from 'vue3-carousel'
    import 'vue3-carousel/dist/carousel.css'
    import { useI18n } from 'vue-i18n';
    
export default /*@__PURE__*/_defineComponent({
  __name: 'Events',
  props: {
        id: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        filteredEvents: {
            type: Object as PropType<EventsData[]>,
            required: true
        },
        showBegginersFilter: {
            type: Boolean,
            required: false
        },
    },
  emits: ["goToMap"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

    const { t } = useI18n();

    const props = __props

    // define emits
    const emit = __emit;

    const allEventsDialog: Ref<typeof DialogComponent | null> = ref(null)
    const currentSlide: Ref<number> = ref(0)

    const eventData = computed < EventsData[] > (() => {
        const events = props.filteredEvents || []
        return showBegginersOnly.value ? events.filter((item) => item.beginners) : events
    })

    const breakpoints = computed(() => {
        return {
            // 700px and up
            0: {
                itemsToShow: (eventData.value.length > 1 ? 1.1 : 1),
            },
            700: {
                itemsToShow: (eventData.value.length > 2 ? 2.1 : 2),
            },
            992: {
                itemsToShow: eventData.value.length > 2 ?
                    (eventData.value.length > 3 ? 3.1 : 3) :
                    (eventData.value.length == 2 ? 2 : 2.1),
            },
            // 1024 and up
            1122: {
                itemsToShow: eventData.value.length > 2 ?
                    (eventData.value.length > 3 ?
                        (eventData.value.length > 4 ? 4.1 : 4) :
                        (eventData.value.length == 3 ? 3 : 3.1)
                    ) : (eventData.value.length == 2 ? 2 : 2.1),
            },
        }
    })

    const showBegginersFilter = computed <Boolean> (() => {
        return props.showBegginersFilter
    })

    const showBegginersOnly = ref(false)


    const card = ref < HTMLElement | null > (null)

    function handleGoToMapEvent(event: any) {
        emit('goToMap', event)
    }

    function next() {
        if (currentSlide.value < eventData.value.length - 1) {
            currentSlide.value++
        }
    }

    function prev() {
        if (currentSlide.value > 0) {
            currentSlide.value--
        }
    }

const __returned__ = { t, props, emit, allEventsDialog, currentSlide, eventData, breakpoints, showBegginersFilter, showBegginersOnly, card, handleGoToMapEvent, next, prev, EventCard, CardContainer, get useTrimText() { return useTrimText }, CheckboxComponent, DialogComponent, get Carousel() { return Carousel }, get Slide() { return Slide } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})