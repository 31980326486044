import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef } from 'vue';
import { useCartStore } from '@/store/cart-store';
import { useEventStore } from '@/store/event-store'
import { useI18n } from 'vue-i18n';
import { useEventBookingStore } from '@/store/booking-store';

export type ColorStyle = {
  primaryColor: string;
  secondaryColor: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CartItem',
  props: {
    cartItem: { type: null, required: true },
    colorStyle: { type: Object, required: false }
  },
  emits: ["onRemove"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();
const cartStore = useCartStore()
const eventStore = useEventStore()
const eventBookingStore = useEventBookingStore()

const defaultColorStyle = {
  primaryColor: '#333',
  secondaryColor: '#eee',
}

const props = __props;

const emit = __emit;

const cartItem = computed(() => props.cartItem)
const colorStyle: ComputedRef<ColorStyle> = computed(() => props.colorStyle || defaultColorStyle);

function handleRemove () {
  cartStore.removeFromCart(cartItem.value)
  emit('onRemove')
}

const __returned__ = { t, cartStore, eventStore, eventBookingStore, defaultColorStyle, props, emit, cartItem, colorStyle, handleRemove }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})