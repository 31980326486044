import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "name", "value"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FormItem"], {
    style: _normalizeStyle($props.style),
    "is-required": $setup.isRequired,
    placeholder: $props.placeholder,
    "error-message": ($setup.forcedValidation || $setup.visited) && $setup.errorMessage || ''
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "radio-button-container",
        style: _normalizeStyle($props.style)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["radio-button-item", {'border-left': index != 0}])
          }, [
            _withDirectives(_createElementVNode("input", {
              id: 'radioButton' + item.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
              class: "radio-button",
              type: "radio",
              name: `${$props.placeholder}-group`,
              value: item.id,
              onBlur: $setup.onBlur,
              onFocus: $setup.onFocus,
              onChange: $setup.handleInputChange
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [
              [_vModelRadio, $setup.value]
            ]),
            _createElementVNode("label", {
              for: 'radioButton' + item.id,
              class: "radio-button-label"
            }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_2)
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 4 /* STYLE */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "is-required", "placeholder", "error-message"]))
}