import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "sumup-card" }
const _hoisted_2 = {
  key: 1,
  class: "sumup-loader",
  style: {"margin-top":"1rem"}
}
const _hoisted_3 = {
  key: 2,
  class: "alert alert-danger",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DialogComponent"], {
    ref: "sumupDialogRef",
    "hide-footer": "",
    "dialog-color-style": {
				primaryColor: $setup.eventStore.eventStyle.bodyTXT,
				secondaryColor: $setup.eventStore.eventStyle.bodyBG,
		},
    "button-color-style": {
				primaryColor: $setup.eventStore.eventStyle.buttonTXT,
				secondaryColor: $setup.eventStore.eventStyle.buttonBG,
		},
    width: '600px',
    onClose: $setup.onClose
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */), [
        [_vShow, $setup.sumUpLoaded]
      ]),
      (!$setup.sumUpLoaded && !$setup.error)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "sumup-loader",
            style: _normalizeStyle({ color: `${$setup.eventStore.eventStyle.bodyTXT}` })
          }, _toDisplayString($setup.t('payment.loading')), 5 /* TEXT, STYLE */))
        : _createCommentVNode("v-if", true),
      ($setup.sumUpProcessing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t('payment.processing_payment')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.t('payment.error_loading_sumup_payment_service')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dialog-color-style", "button-color-style"]))
}