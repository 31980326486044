import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "checked", "name"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "fa-solid fa-star",
  "aria-hidden": "true"
}
const _hoisted_4 = {
  key: 1,
  class: "fa-regular fa-star",
  "aria-hidden": "true"
}
const _hoisted_5 = ["id", "checked", "name", "disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "ratingEl",
    class: _normalizeClass(["vue-stars", { readonly: $setup.readonly }])
  }, [
    _createElementVNode("input", {
      id: $setup.name + '0',
      checked: $setup.value === 0,
      name: $setup.name,
      type: "radio",
      value: "0"
    }, null, 8 /* PROPS */, _hoisted_1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.max, (x) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: 'i' + x
      }, [
        _createElementVNode("label", {
          for: $setup.name + x
        }, [
          (x <= $setup.value)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : (_openBlock(), _createElementBlock("i", _hoisted_4))
        ], 8 /* PROPS */, _hoisted_2),
        _createElementVNode("input", {
          id: $setup.name + x,
          checked: $props.modelValue === x,
          name: $setup.name,
          disabled: $setup.readonly,
          value: x,
          type: "radio",
          onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateInput(($event?.target as HTMLInputElement)?.value))),
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateInput(($event?.target as HTMLInputElement)?.value)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_5)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}