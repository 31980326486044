import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "green-color fa-solid fa-check-square"
}
const _hoisted_2 = {
  key: 1,
  class: "gray-color fa-solid fa-square"
}
const _hoisted_3 = { class: "checkbox-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "cursor-pointer",
    onClick: $setup.updateInput
  }, [
    ($props.modelValue)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1))
      : (_openBlock(), _createElementBlock("i", _hoisted_2)),
    _createElementVNode("span", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)
  ]))
}