import { vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FormItem"], {
    style: _normalizeStyle($props.style),
    "is-required": $setup.isRequired,
    placeholder: $props.placeholder,
    "error-message": ($setup.forcedValidation || $setup.visited) && $setup.errorMessage || '',
    "hide-label": !($setup.focused && !$setup.hasValue || $setup.hasValue),
    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleFormItemClick()))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("textarea", {
        ref: "textareaRef",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
        row: "10",
        placeholder: $setup.focused ? '' : `${$props.placeholder}${ $setup.isRequired ? ' *' : ''}`,
        disabled: $setup.isDisabled,
        style: _normalizeStyle($props.style),
        class: "form-item-textarea",
        onBlur: $setup.onBlur,
        onFocus: $setup.onFocus,
        onChange: $setup.handleInputChange
      }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1), [
        [_vModelText, $setup.value]
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "is-required", "placeholder", "error-message", "hide-label"]))
}