import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/store/user-store'
import { useEventBookingStore } from '@/store/booking-store';
import { useEventStore } from '@/store/event-store';
import DialogComponent from '@/design-system/DialogComponent.vue'
import { useI18n } from 'vue-i18n';
import { useSumUp } from '@/composable/useSumUp';

export default /*@__PURE__*/_defineComponent({
  __name: 'SumUpPaymentDialog',
  props: {
    paymentMethodData: { type: null, required: true }
  },
  emits: ["success", "close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const eventBookingStore = useEventBookingStore()
const userStore = useUserStore()
const eventStore = useEventStore()

const props = __props;

// define emits
const emit = __emit;

const error = ref(null);
const sumupDialogRef: Ref<typeof DialogComponent | null> = ref(null)
const { sumUpLoaded, sumUpProcessing, loadSumUpScript, openSumUpFormAndProcessPayment } = useSumUp()

const paymentMethodData = computed(() => props.paymentMethodData)

async function open() {
    try {
        sumupDialogRef.value?.open()
        await loadSumUpScript();
        await startPaymentProcess();
    } catch (error: any) {
        console.log('Error loading SumUp:', error)
        error.value = error.message
    }
}

function onClose () {
    emit('close'); 
    // TODO call API.cancelPayment()
}

function redirectToConfirmationPage() {
    sumupDialogRef.value?.close()
    emit('success');
}

async function initializePayment() {
    const userId = userStore.user?.id;
    const sessionId = eventBookingStore.sessionId

    if (!userId) {
        throw new Error(t('toast_translation.login_first'))
    } else if (!sessionId) {                    
        throw new Error(t('toast_translation.error_mesg'));
    } else {
        let paymentFinalObject = {
            sessionId,
            clientId: paymentMethodData.value.clientId,
            method: 'SUM_UP',
            locale: process.env.VUE_APP_LOCALE_LANG,
            platform: eventStore.isEmbeddedEventMode ? 'EMBEDDED' : "VUE",
            addOns: [],
        };
        console.log('initialize sumup payment')
        if (eventBookingStore.promoDiscount) {
            console.log('PromCode: ', eventBookingStore.promoCode)
            paymentFinalObject['promoCode'] = eventBookingStore.promoCode
        }
        return await eventBookingStore.sumUpPaymentMethod(paymentFinalObject)
    }
}

async function startPaymentProcess() {
    const Toast = useToast();
    try {
        const paymentData = await initializePayment();
        await openSumUpFormAndProcessPayment(paymentData.paymentIntent);
        Toast.success(t('payment_successful'));
        redirectToConfirmationPage();
    } catch (error: any) {
        /* await eventBookingStore.cancelSumupPayment({
            paymentId,
            confirmationId: null,
        }); */
        error.value = error.message;
        Toast.error(error.message); // TODO translate by error code
    }
}

__expose({
  open
})

const __returned__ = { t, eventBookingStore, userStore, eventStore, props, emit, error, sumupDialogRef, sumUpLoaded, sumUpProcessing, loadSumUpScript, openSumUpFormAndProcessPayment, paymentMethodData, open, onClose, redirectToConfirmationPage, initializePayment, startPaymentProcess, DialogComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})