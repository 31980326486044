import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "login-signup-header"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "signup-form-container"
}
const _hoisted_4 = {
  key: 0,
  class: "google-button-container"
}
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-row" }
const _hoisted_7 = { class: "form-row" }
const _hoisted_8 = { class: "form-row" }
const _hoisted_9 = {
  for: "newsletter",
  class: "custom-label"
}
const _hoisted_10 = {
  href: "https://www.pointsoftango.com/terms-and-conditions",
  target: "_blank"
}
const _hoisted_11 = {
  href: "https://www.pointsoftango.com/privacy-policy",
  target: "_blank"
}
const _hoisted_12 = { class: "centered-elements" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "centered-elements" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleLogin = _resolveComponent("GoogleLogin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", {
            class: "title",
            style: _normalizeStyle({ 'color': $setup.defaultColors?.bodyTXT, backgroundColor: $setup.defaultColors.background })
          }, _toDisplayString(!$setup.isSignUpMode ? $setup.t('buyer_details.buyer_details') : $setup.t('buyer_details.sign_up')), 5 /* TEXT, STYLE */),
          _createElementVNode("button", {
            style: _normalizeStyle({ ...$setup.primaryButtonStyle, border: `1px solid ${$setup.defaultColors.bodyTXT}`}),
            class: "close-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeForm()))
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("i", {
              class: "fa fa-times",
              "aria-hidden": "true"
            }, null, -1 /* HOISTED */)
          ]), 4 /* STYLE */)
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.isSignUpMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["LoginForm"], {
            onSuccess: $setup.closeForm,
            onOpenSignUpForm: _cache[1] || (_cache[1] = ($event: any) => ($setup.isSignUp = true))
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!$setup.incompleteSignUp)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_GoogleLogin, {
                  "logout-button": true,
                  callback: $setup.googleLoginCallback
                })
              ]))
            : _createCommentVNode("v-if", true),
          (!$setup.incompleteSignUp)
            ? (_openBlock(), _createBlock($setup["HorizontalLine"], {
                key: 1,
                text: $setup.t('or'),
                style: _normalizeStyle({
        '--primary-color': $setup.defaultColors.bodyTXT,
      })
              }, null, 8 /* PROPS */, ["text", "style"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("h2", {
            style: _normalizeStyle({'color': $setup.defaultColors?.bodyTXT}),
            class: "profile-details-title"
          }, _toDisplayString($setup.t('profile_details')), 5 /* TEXT, STYLE */),
          _createVNode($setup["FormComponent"], {
            ref: "registrationFormRef",
            name: "signUpForm"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.firstName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.firstName) = $event)),
                  required: "",
                  placeholder: $setup.t('registration_page.first_name'),
                  style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.lastName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.lastName) = $event)),
                  required: "",
                  placeholder: $setup.t('registration_page.last_name'),
                  style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.email,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.email) = $event)),
                  email: "",
                  required: "",
                  placeholder: $setup.t('registration_page.email'),
                  disabled: $setup.incompleteSignUp,
                  style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                }, null, 8 /* PROPS */, ["modelValue", "placeholder", "disabled", "style"]),
                _createVNode($setup["FormItemInput"], {
                  modelValue: $setup.confirm_email,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.confirm_email) = $event)),
                  email: "",
                  required: "",
                  "equal-to": $setup.email,
                  placeholder: $setup.t('registration_page.confirm_email'),
                  disabled: $setup.incompleteSignUp,
                  style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                }, null, 8 /* PROPS */, ["modelValue", "equal-to", "placeholder", "disabled", "style"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (!$setup.incompleteSignUp)
                  ? (_openBlock(), _createBlock($setup["PasswordInput"], {
                      key: 0,
                      modelValue: $setup.register_password,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.register_password) = $event)),
                      required: "",
                      placeholder: $setup.t('registration_page.password'),
                      style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]))
                  : _createCommentVNode("v-if", true),
                (!$setup.incompleteSignUp)
                  ? (_openBlock(), _createBlock($setup["PasswordInput"], {
                      key: 1,
                      modelValue: $setup.confirm_password,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.confirm_password) = $event)),
                      required: "",
                      "equal-to": $setup.register_password,
                      placeholder: $setup.t('registration_page.confirm_password'),
                      style: _normalizeStyle({
                    '--primary-color': $setup.defaultColors.bodyTXT,
                    '--secondary-color': $setup.defaultColors.background,
                })
                    }, null, 8 /* PROPS */, ["modelValue", "equal-to", "placeholder", "style"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode($setup["InputDropdown"], {
                  modelValue: $setup.selectedCountryOption,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.selectedCountryOption) = $event)),
                  required: "",
                  options: $setup.countriesDropdownOptions,
                  placeholder: $setup.t('registration_page.nationality'),
                  "search-placeholder": $setup.t('registration_page.nationalityPlaceholder'),
                  disabled: false,
                  color: $setup.defaultColors.bodyTXT,
                  "background-color": $setup.defaultColors.background,
                  "selected-color": $setup.defaultColors.selectedTXT
                }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "search-placeholder", "color", "background-color", "selected-color"]),
                _createVNode($setup["InputDropdown"], {
                  modelValue: $setup.selectedResidencyOption,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.selectedResidencyOption) = $event)),
                  required: "",
                  options: $setup.countriesDropdownOptions,
                  placeholder: $setup.t('registration_page.country'),
                  "search-placeholder": $setup.t('registration_page.nationalityPlaceholder'),
                  disabled: false,
                  color: $setup.defaultColors.bodyTXT,
                  "background-color": $setup.defaultColors.background,
                  "selected-color": $setup.defaultColors.selectedTXT
                }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder", "search-placeholder", "color", "background-color", "selected-color"])
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: "form-group",
                  style: _normalizeStyle({'color': $setup.defaultColors?.bodyTXT, '--border-color':$setup.defaultColors.bodyTXT})
                }, [
                  _withDirectives(_createElementVNode("input", {
                    id: "newsletter",
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.selectednewsLetters) = $event)),
                    type: "checkbox"
                  }, null, 512 /* NEED_PATCH */), [
                    [_vModelCheckbox, $setup.selectednewsLetters]
                  ]),
                  _createElementVNode("label", _hoisted_9, _toDisplayString($setup.t('registration_page.subscribe_line')), 1 /* TEXT */),
                  _createCommentVNode(" <p v-if=\"selectednewsLetters === false\" class=\"error-message\">You must check the checkbox to subscribe.</p> ")
                ], 4 /* STYLE */),
                _createElementVNode("div", {
                  class: "form-group",
                  style: _normalizeStyle({'color': $setup.defaultColors?.bodyTXT, '--border-color':$setup.defaultColors.bodyTXT})
                }, [
                  _withDirectives(_createElementVNode("input", {
                    id: "terms-conditions-checkbox",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.acceptterm_condition) = $event)),
                    type: "checkbox",
                    onChange: $setup.handleCheckboxChangeTerm
                  }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                    [_vModelCheckbox, $setup.acceptterm_condition]
                  ]),
                  _createElementVNode("label", {
                    for: "terms-conditions-checkbox",
                    class: _normalizeClass(["custom-label", $setup.acceptterm_condition == false ? 'default_subscribe_line' : ''])
                  }, [
                    _createTextVNode(_toDisplayString($setup.t('registration_page.accept')) + " ", 1 /* TEXT */),
                    _createElementVNode("a", _hoisted_10, _toDisplayString($setup.t('registration_page.term_condition')), 1 /* TEXT */),
                    _createTextVNode(" " + _toDisplayString($setup.t('registration_page.and')) + " ", 1 /* TEXT */),
                    _createElementVNode("a", _hoisted_11, _toDisplayString($setup.t('registration_page.privacy_policy')), 1 /* TEXT */)
                  ], 2 /* CLASS */)
                ], 4 /* STYLE */)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("button", {
                  disabled: !$setup.acceptterm_condition,
                  style: _normalizeStyle($setup.primaryButtonStyle),
                  class: "primary-button",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => ($setup.SingUpWithTango()))
                }, _toDisplayString($setup.t('button.sign_upto_points_of_tango')), 13 /* TEXT, STYLE, PROPS */, _hoisted_13)
              ]),
              _createElementVNode("div", null, [
                (!$setup.incompleteSignUp)
                  ? (_openBlock(), _createBlock($setup["HorizontalLine"], {
                      key: 0,
                      text: $setup.t('buyer_details.already_have_account'),
                      style: _normalizeStyle({
                '--primary-color': $setup.defaultColors.bodyTXT,
              })
                    }, null, 8 /* PROPS */, ["text", "style"]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_14, [
                  (!$setup.incompleteSignUp)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        style: _normalizeStyle($setup.secondaryButtonStyle),
                        class: "secondary-button",
                        onClick: _cache[13] || (_cache[13] = ($event: any) => ($setup.switchToLogin()))
                      }, _toDisplayString($setup.t('sign_in')), 5 /* TEXT, STYLE */))
                    : _createCommentVNode("v-if", true)
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */)
        ]))
  ], 64 /* STABLE_FRAGMENT */))
}