import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FormItem"], {
    "is-required": $setup.isRequired,
    placeholder: $setup.placeholder,
    "error-message": ($setup.forcedValidation || $setup.visited) && $setup.errorMessage || '',
    style: _normalizeStyle({
      '--primary-color': $setup.color,
      '--secondary-color': $setup.backgroundColor,
    }),
    "hide-label": !($setup.focused && !$setup.hasValue || $setup.hasValue),
    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleFormItemClick()))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["DropdownComponent"], {
        ref: "dropdownComponentRef",
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
        options: $setup.options,
        color: $setup.color,
        "background-color": $setup.backgroundColor,
        "selected-color": $setup.selectedColor,
        placeholder: $setup.focused ? '' : `${$setup.placeholder}${ $setup.isRequired ? ' *' : ''}`,
        "search-placeholder": $setup.searchPlaceholder,
        disabled: $props.disabled,
        "no-borders": true,
        filterable: "",
        onBlur: $setup.onBlur,
        onFocus: $setup.onFocus,
        onChange: $setup.onChange
      }, null, 8 /* PROPS */, ["modelValue", "options", "color", "background-color", "selected-color", "placeholder", "search-placeholder", "disabled"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-required", "placeholder", "error-message", "style", "hide-label"]))
}