import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

export default /*@__PURE__*/_defineComponent({
  __name: 'BankDetailsRow',
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const copied = ref(false)

const label = computed(() => props.label)
const value = computed(() => props.value)

async function copyText () {
  if (!this.copied) {
      try {
          await navigator.clipboard.writeText(value.value);
          const Toast = useToast();
          Toast.success(t('toast_translation.copied_txt'), {
            timeout: 1000,
          });
          copied.value = true
          setTimeout(() => copied.value = false, 1000)
      } catch (error) {
          console.error('Failed to copy: ', error);
      }
    }
}


const __returned__ = { t, props, copied, label, value, copyText }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})