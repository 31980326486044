import { defineComponent as _defineComponent } from 'vue'
import { ComputedRef, computed, ref, Ref, onMounted } from 'vue';
import { useSessionCountdown } from '@/composable/useSessionCountdown'
import { useToast } from 'vue-toastification'
import BankPaymentMethodSection from '@/components/event/paymentMethods/BankPaymentMethodSection.vue';
import DirectPaymentMethodSection from '@/components/event/paymentMethods/DirectPaymentMethodSection.vue';
import LinkPaymentMethodSection from '@/components/event/paymentMethods/LinkPaymentMethodSection.vue';
import WesternUnionPaymentMethodSection from '@/components/event/paymentMethods/WesternUnionPaymentMethodSection.vue';
import StripeMethodSection from '@/components/event/paymentMethods/StripeMethodSection.vue';
import PaypalMethodSection from '@/components/event/paymentMethods/PaypalMethodSection.vue';
import MERCADO_PAGOMethodSection from '@/components/event/paymentMethods/MERCADO_PAGOMethodSection.vue';
import SumUpPaymentMethod from '@/components/event/paymentMethods/SumUpPaymentMethod.vue';
import SumUpPaymentDialog from '@/components/event/paymentMethods/SumUpPaymentDialog.vue';
import PotsLoader from '@/design-system/pots-loader.vue';
import AccordionComponent from '@/design-system/accordion/AccordionComponent.vue';
import AccordionItemComponent from '@/design-system/accordion/AccordionItemComponent.vue';
import ConfirmDialog from '@/design-system/ConfirmDialog.vue';
import BookingNavbar from '../BookingNavbar.vue'
import ShoppingCart from '@/components/event/ShoppingCart.vue';
import CartItem from '@/components/event/CartItem.vue';
import DialogComponent from '@/design-system/DialogComponent.vue';
import {
    getEventGateways
} from '@/services/events.service'
import { useUserStore } from '@/store/user-store'
import { useCartStore } from '@/store/cart-store';
import { useEventStore } from '@/store/event-store';
import { useEventBookingStore } from '@/store/booking-store';
import { useI18n } from 'vue-i18n';
interface Map {
  [key: string]: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'stepPayment',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const eventBookingStore = useEventBookingStore()
const userStore = useUserStore()
const eventStore = useEventStore()
const cartStore = useCartStore()

const paymentMethodSelected: Ref<string | null> = ref(null);
const loading = ref(false);
const paymentMethods: Ref<any[]> = ref([]);
const paymentMethodsLoading = ref(false);
const invalidPromoCodeMessage = ref('');
const sumUpPaymentDialog: Ref<typeof SumUpPaymentDialog | null> = ref(null)
const confirmDialogRef: Ref<typeof ConfirmDialog | null> = ref(null)
const cartDialogRef: Ref<typeof DialogComponent | null>  = ref(null)

const { startSessionTimer, resumeSessionTimer, stopSessionTimer, sessionTime, addMoreTime} = useSessionCountdown({
    onRunningOutOfTime: () => {
        confirmDialogRef.value?.open()
    },
    onTimeout: () => {
        cancelPayment()
    }
})

const paymentMethodsById: ComputedRef<Map> = computed(() => {
    return paymentMethods.value.reduce((acc: Map, curr: any) => ({ ...acc, [curr.method]: curr}), {})
})

const componentMap  = computed(() => {
    return {
        'SUM_UP': SumUpPaymentMethod,
        'BANK': BankPaymentMethodSection,
        'STRIPE': StripeMethodSection,
        'PAYPAL': PaypalMethodSection,
        'WU': WesternUnionPaymentMethodSection,
        'LINK': LinkPaymentMethodSection,
        'DIRECT': DirectPaymentMethodSection,
        'MERCADO_PAGO': MERCADO_PAGOMethodSection,
    }
})

const total_amt = computed(() => cartStore.getCartTotal + paymentMethodDeltaP.value + paymentMethodDeltaD.value - eventBookingStore.promoDiscount)

const paymentMethodDeltaP  = computed(() => {
    const deltaP = paymentMethodSelected.value ? (paymentMethodsById.value[paymentMethodSelected.value]?.deltaP || 0) : 0
    return cartStore.getCartTotal * deltaP / 100
})

const partial_total_amt = computed(() => {
    return cartStore.getCartPartialTotal + paymentMethodPartialDeltaP.value + paymentMethodDeltaD.value
})

const paymentMethodPartialDeltaP  = computed(() => {
    const deltaP = paymentMethodSelected.value ? (paymentMethodsById.value[paymentMethodSelected.value]?.deltaP || 0) : 0
    return cartStore.getCartPartialTotal * deltaP / 100
})

const paymentMethodDeltaD  = computed(() => paymentMethodSelected.value ? paymentMethodsById.value[paymentMethodSelected.value]?.deltaD : 0)

const paymentDetailsContainer = computed(() => ({
    'border': `1px solid ${eventStore.eventStyle.selectedTXT}`,
    'border-radius': '1rem',
    'padding': '1rem',
}))

const cartDetailBottomborder = computed(() => ({
    'border-bottom': `1px solid ${eventStore.eventStyle.selectedTXT}`,
    'color': eventStore.eventStyle.buttonBG,
}))

const cartTxtObj = computed(() => ({ 'color': eventStore.eventStyle.bodyTXT }))

const applycodeBorder = computed(() => ({
    'border': `1px solid ${eventStore.eventStyle.selectedTXT}`,
    'color': eventStore.eventStyle.bodyTXT,
    'background': eventStore.eventStyle.background
}))

const eventButtonStyle = computed(() => ({
    'color': eventStore.eventStyle.buttonTXT,
    'background-color': eventStore.eventStyle.buttonBG,
}))

onMounted(async () => {
    window.scrollTo(0,0);
    try {
        paymentMethodsLoading.value = true
        console.log('Session Id: ', eventBookingStore.sessionId)
        const gateways = await getEventGateways(eventBookingStore.sessionId, cartStore.getCurrency)
        gateways.forEach((element: any) => {
            paymentMethods.value.push(element)
        });
        if (paymentMethods.value.length == 1) {
            paymentMethodSelected.value = paymentMethods.value[0].method
            console.log('Payment Method selected: ', paymentMethodSelected.value)
        }
    } catch (error) {
        const Toast = useToast();
        Toast.error('Sorry, there was an error getting payment methods.');
    }
    paymentMethodsLoading.value = false
    if (eventBookingStore.promoCode) {
        applyPromoCode()
    }
    startSessionTimer()
})

const prev = () => {
    eventBookingStore.goToPrevBookingStep()
}

const next = () => {
    eventBookingStore.goToNextBookingStep()
}

const remove_from_cart = async () => {
    if (!cartStore.getItems.length) {
        if (eventStore.event?.process !== 'BOOKING') {
            prev()
        } else {
            eventBookingStore.goToBookingStep(0)
        }
    }
    try {
        loading.value = true;
        await eventBookingStore.updateSession()
        if (eventBookingStore.promoCode) {
            applyPromoCode()
        }
    } catch (error) {
        console.log(error);
    }
    loading.value = false;
}

const applyPromoCode = async () => {
    const Toast = useToast();
    invalidPromoCodeMessage.value = ''
    try {
        await eventBookingStore.applyPromoCode()
        Toast.success(t('toast_translation.promo_code'));
    } catch (error) {
        invalidPromoCodeMessage.value = t('toast_translation.enter_valid_promo_code')
        Toast.error(t('toast_translation.enter_valid_promo_code'));
    }
}

const clearPromocode = async () => {
    eventBookingStore.clearPromocode()
}

const confirmBooking = async () => {
    const Toast = useToast();
    let userId = userStore.user?.id;

    if (!userId) {
        Toast.error(t('toast_translation.login_first'));
    } else if (eventBookingStore.sessionId) {
        const action = total_amt.value === 0 ? 'DIRECT' : paymentMethodSelected.value || 'DIRECT'
        console.log('Pay Action: ', action)
        if (['BANK','WU','LINK','DIRECT'].includes(action)) {
            let paymentFinalObject = {
                sessionId: eventBookingStore.sessionId,
                userId,
                action,
                process: eventStore.event?.process || '',
                promoCode: eventBookingStore.promoCode || null,
                eventId: eventStore.getEventIdRequestBody,
                free: total_amt.value == 0,
                locale: localStorage.getItem('language') || navigator.language || 'en'
            };
            try {
                loading.value = true;
                await eventBookingStore.bankPaymentMethod(paymentFinalObject)
                loading.value = false;
                eventBookingStore.goToNextBookingStep()
            } catch (error) {
                loading.value = false;
                Toast.error(t('toast_translation.error_mesg'));
            }
        } else if (action == 'SUM_UP') {
            stopSessionTimer()
            sumUpPaymentDialog.value?.open()
        } else {
            const methodMap: Map = {
                'STRIPE': 'STRIPE_WEB',
                'PAYPAL': 'PAYPAL',
                'MERCADO_PAGO': 'MERCADO_PAGO',
                'SUM_UP': 'SUM_UP',
            }
            let paymentFinalObject = {
                sessionId: eventBookingStore.sessionId,
                clientId: (paymentMethodsById as Map)[action].clientId,
                method: methodMap[action],
                promoCode: eventBookingStore.promoCode || null,
                locale: localStorage.getItem('language') || navigator.language || 'en'
            };
            try {
                loading.value = true;
                const paymentData = await eventBookingStore.withoutDetailPaymentMethod(paymentFinalObject);
                loading.value = false;
                window.open(paymentData.checkoutUrl, '_self');     
            } catch (error) {
                loading.value = false;
                Toast.error(t('toast_translation.error_mesg'));
            }
        }
        
    } else {
        Toast.error(t('toast_translation.error_mesg'));
    }
}
const handlePaymentMethodSelectionChange = (value: string | null) => {
    paymentMethodSelected.value = value
    eventBookingStore.selectedPaymentType = value
}
const cancelPayment =  () => {
    eventBookingStore.reset()
}


const __returned__ = { t, eventBookingStore, userStore, eventStore, cartStore, paymentMethodSelected, loading, paymentMethods, paymentMethodsLoading, invalidPromoCodeMessage, sumUpPaymentDialog, confirmDialogRef, cartDialogRef, startSessionTimer, resumeSessionTimer, stopSessionTimer, sessionTime, addMoreTime, paymentMethodsById, componentMap, total_amt, paymentMethodDeltaP, partial_total_amt, paymentMethodPartialDeltaP, paymentMethodDeltaD, paymentDetailsContainer, cartDetailBottomborder, cartTxtObj, applycodeBorder, eventButtonStyle, prev, next, remove_from_cart, applyPromoCode, clearPromocode, confirmBooking, handlePaymentMethodSelectionChange, cancelPayment, SumUpPaymentDialog, PotsLoader, AccordionComponent, AccordionItemComponent, ConfirmDialog, BookingNavbar, ShoppingCart, CartItem, DialogComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})