<script setup lang="ts">

</script>

<template>
  <div class="wrapper-flex">
    <slot/>
  </div>
</template>

<style scoped>
.wrapper-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

</style>
