import { defineComponent as _defineComponent } from 'vue'
import { useMainStore } from '@/store/main-store';
    import {ref, computed} from 'vue'
    import { useI18n } from 'vue-i18n';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props, { expose: __expose }) {
  __expose();

    const appVersion = require('@/../package.json').version;
    const version = ref(appVersion)
    const { t } = useI18n()
    const mainStore = useMainStore()

    const mailToLink = computed(() => 'mailto:info@pointsoftango.app')

const __returned__ = { appVersion, version, t, mainStore, mailToLink }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})