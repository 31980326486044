import { defineComponent as _defineComponent } from 'vue'
import { ComputedRef, computed, ref, Ref } from 'vue';
import DialogComponent from '@/design-system/DialogComponent.vue';

export type ColorStyle = {
  primaryColor: string;
  secondaryColor: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDialog',
  props: {
    message: { type: String, required: true },
    title: { type: String, required: false },
    confirmButtonLabel: { type: String, required: false },
    cancelButtonLabel: { type: String, required: false },
    dialogColorStyle: { type: Object, required: false },
    buttonColorStyle: { type: Object, required: false },
    hideCancel: { type: Boolean, required: false }
  },
  emits: ["confirm", "cancel"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const dialogId = ref('')
const customProps: Ref<any> = ref({})
const dialogRef: Ref<typeof DialogComponent | null>  = ref(null)

// define emits
const emit = __emit;

const props = __props;

const defaultColorStyle = {
  primaryColor: '#333',
  secondaryColor: '#eee',
}

const message: ComputedRef<string> = computed(() => props.message);
const confirmButtonLabel: ComputedRef<string> = computed(() => props.confirmButtonLabel || 'Confirm');
const cancelButtonLabel: ComputedRef<string> = computed(() => props.cancelButtonLabel || 'Cancel');
const title: ComputedRef<string> = computed(() => props.title || 'Confirm');
const dialogColorStyle: ComputedRef<ColorStyle> = computed(() => props.dialogColorStyle || defaultColorStyle);
const buttonColorStyle: ComputedRef<ColorStyle> = computed(() => props.buttonColorStyle || defaultColorStyle);
const hideCancel: ComputedRef<boolean> = computed(() => (customProps.value.hideCancel) != undefined ? customProps.value.hideCancel : props.hideCancel);

function onConfirm () {
    dialogRef.value?.close()
    emit('confirm', dialogId.value)
}

function onCancel () {
    dialogRef.value?.close()
    emit('cancel', dialogId.value)
}

__expose({
  open: (id: string, props: any) => {
    dialogId.value = id
    customProps.value.hideCancel = props?.hideCancel
    dialogRef.value?.open(id)
  },
  close: () => {
    dialogRef.value?.close()
  }
})

const __returned__ = { dialogId, customProps, dialogRef, emit, props, defaultColorStyle, message, confirmButtonLabel, cancelButtonLabel, title, dialogColorStyle, buttonColorStyle, hideCancel, onConfirm, onCancel, DialogComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})