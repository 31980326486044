import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["BankDetailsRow"], {
      label: "Coin",
      value: $setup.paymentMethodData.cryptoDetails?.coin
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Rate",
      value: $setup.paymentMethodData.cryptoDetails?.rate
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Net",
      value: $setup.paymentMethodData.cryptoDetails?.mainNet
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode($setup["BankDetailsRow"], {
      label: "Address",
      value: $setup.paymentMethodData.cryptoDetails?.address
    }, null, 8 /* PROPS */, ["value"]),
    _createElementVNode("div", {
      class: "message-wrapper",
      style: _normalizeStyle($setup.messageWrapperStyle)
    }, _toDisplayString($setup.t('payment.payment_link_message')), 5 /* TEXT, STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}