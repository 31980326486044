import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { useEventStore } from '@/store/event-store'


export default /*@__PURE__*/_defineComponent({
  __name: 'PotsFooter',
  setup(__props, { expose: __expose }) {
  __expose();

const eventStore = useEventStore()

// Computed properties for styles
const footerStyle = computed(() => ({
    background: eventStore.eventStyle.headerBG,
    color: eventStore.eventStyle.headerTXT,
}));

const logoSrc = require('@/assets/logos/main_logo.png');


const __returned__ = { eventStore, footerStyle, logoSrc }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})