import { defineComponent as _defineComponent } from 'vue'
import PotsLoader from '@/design-system/pots-loader.vue';
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'programme',
  setup(__props, { expose: __expose }) {
  __expose();

const loading = ref(true);

const __returned__ = { loading, PotsLoader }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})