import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cart-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "cart-footer"
}
const _hoisted_4 = { class: "cart-total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cartStore.getItems, (cartItem) => {
      return (_openBlock(), _createBlock($setup["CartItem"], {
        key: cartItem.id,
        "cart-item": cartItem,
        "color-style": {
          primaryColor: $setup.eventStore.eventStyle.bodyTXT,
          secondaryColor: $setup.eventStore.eventStyle.bodyBG,
      }
      }, null, 8 /* PROPS */, ["cart-item", "color-style"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (!$setup.cartStore.getItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t('checkout.cart_is_empty')), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "clear-cart-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.confirmClearCartDialogRef?.open()))
          }, _toDisplayString($setup.t('checkout.clear_cart')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(`${$setup.cartStore.getCurrencySymbol} ${$setup.cartStore.getCartTotal}`), 1 /* TEXT */)
          ])
        ])),
    _createCommentVNode("clear cart modal"),
    _createVNode($setup["ConfirmDialog"], {
      ref: "confirmClearCartDialogRef",
      title: $setup.t('cart_page.clear_cart'),
      message: $setup.t('cart_page.clear_cart_mesg'),
      "dialog-color-style": {
          primaryColor: $setup.eventStore.eventStyle.bodyTXT,
          secondaryColor: $setup.eventStore.eventStyle.bodyBG,
      },
      "button-color-style": {
          primaryColor: $setup.eventStore.eventStyle.buttonTXT,
          secondaryColor: $setup.eventStore.eventStyle.buttonBG,
      },
      "confirm-button-label": $setup.t('button.yes'),
      "cancel-button-label": $setup.t('button.no'),
      onConfirm: _cache[1] || (_cache[1] = ($event: any) => ($setup.UserclearCart()))
    }, null, 8 /* PROPS */, ["title", "message", "dialog-color-style", "button-color-style", "confirm-button-label", "cancel-button-label"]),
    _createCommentVNode("end")
  ]))
}