import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, ref } from 'vue';  
  import { useEventStore } from '@/store/event-store';
  import { useUserStore } from '@/store/user-store';
  import { useI18n } from "vue-i18n";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EventLinksBar',
  props: {
    eventUrlId: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "0254fdc0-eventStore\.eventStyle\.selectedTXT": (_unref(eventStore).eventStyle.selectedTXT)
}))

  const eventStore = useEventStore();
  const userStore = useUserStore();
  const { t } = useI18n();

  const props = __props;

  const isMobileMenuOpened = ref(false);
  const barsButtonRef = ref(null)

  const event = computed(() => eventStore.event);
  const eventUrlId = computed(() => props.eventUrlId);

  const styleObjectHover = computed(() => ({
    '--a-color--hover': eventStore.eventStyle.selectedTXT,
    '--bs-nav-link-hover-color': eventStore.eventStyle.selectedTXT,
    '--a-color': eventStore.eventStyle.bodyTXT,
    '--a-color--active': eventStore.eventStyle.selectedTXT
  }));

  const userIsOrganiser = computed(() => {
    const organisers = event.value?.organisers || [];
    return organisers.includes(userStore.user?.id);
  });

  const links = computed(() => {
    const links = [];

    links.push({
      route: { path: '/event/' + eventUrlId.value },
      label: t('menu.detail'),
      icon: 'fa-info-circle'
    });

    if (eventStore.visiblePerformers.length > 0 || eventStore.visibleDjs.length > 0) {
      links.push({
        route: { path: '/event/' + eventUrlId.value + '/artists' },
        label: t('menu.artists'),
        icon: 'fa-palette'
      });
    }

    if (eventStore.programme.length > 0) {
      links.push({
        route: { path: '/event/' + eventUrlId.value + '/programme' },
        label: t('menu.programme'),
        icon: 'fa-calendar-days'
      });
    }

    if (userIsOrganiser.value) {
      links.push({
        route: { path: '/event/' + eventUrlId.value + '/visuals' },
        label: t('menu.visuals'),
        icon: 'fa-sliders'
      });
    }

    if (links.length === 1) {
      return [];
    }

    return links;
  });

  function toggleMobileMenu() {
    isMobileMenuOpened.value = !isMobileMenuOpened.value;
  };

const __returned__ = { eventStore, userStore, t, props, isMobileMenuOpened, barsButtonRef, event, eventUrlId, styleObjectHover, userIsOrganiser, links, toggleMobileMenu }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})