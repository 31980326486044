import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["value", "disabled", "placeholder", "title"]
const _hoisted_3 = {
  key: 0,
  class: "dropdown-options-container"
}
const _hoisted_4 = {
  key: 0,
  class: "filter-container"
}
const _hoisted_5 = ["placeholder", "disabled"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "mobile-dropdown-options-list" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 1,
  class: "filter-container"
}
const _hoisted_13 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-container", { 'expand-up': $setup.isExpandUp }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-value", {
        'expand-up': $setup.isExpandUp,
        'open': $setup.isDropdownOpen,
        'disabled': $setup.disabled,
        'dropdown-value-border': !$setup.noBorders,
        'dropdown-value-padding': $setup.padding
      }]),
      style: _normalizeStyle({ color: $setup.color, backgroundColor: $setup.backgroundColor, borderColor: $setup.color }),
      onClick: $setup.toggleDropdown
    }, [
      ($setup.value?.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "dropdown-img",
            src: $setup.value?.icon,
            loading: "lazy"
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      _createElementVNode("input", {
        ref: "input",
        value: $setup.value?.label || '',
        readonly: "",
        disabled: $setup.disabled,
        placeholder: $setup.placeholder,
        title: $setup.value?.label || '',
        class: "dropdown-input",
        autocomplete: "false",
        onBlur: $setup.onBlur,
        onFocus: $setup.onFocus,
        onKeydown: $setup.handleKeyDownOnInput
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
      ($setup.isExpandUp)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["dropdown-icon fa fa-angle-up", {'icon-rotate': $setup.isDropdownOpen}]),
            "aria-hidden": "true"
          }, null, 2 /* CLASS */))
        : (_openBlock(), _createElementBlock("i", {
            key: 2,
            class: _normalizeClass(["dropdown-icon fa fa-angle-down", {'icon-rotate': $setup.isDropdownOpen}]),
            "aria-hidden": "true"
          }, null, 2 /* CLASS */))
    ], 6 /* CLASS, STYLE */),
    ($setup.isDropdownOpen && !$setup.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          ($setup.isFilterable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  ref: "queryInput",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.query) = $event)),
                  placeholder: $setup.searchPlaceholder,
                  disabled: !$setup.isDropdownOpen,
                  type: "search",
                  role: "presentation",
                  autocomplete: "off",
                  class: "query-input",
                  onKeydown: $setup.handleKeyDownOnFilter,
                  onBlur: $setup.handleBlurOnFilter,
                  onFocus: $setup.handleFocusOnFilter
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_5), [
                  [_vModelText, $setup.query]
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("ul", {
            ref: "dropdownList",
            class: _normalizeClass({ open: $setup.isDropdownOpen }),
            style: _normalizeStyle({ color: $setup.color, backgroundColor: $setup.backgroundColor, borderColor: $setup.color })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                style: _normalizeStyle({
            transition: 'border-color ease 200ms',
            color: (option?.id == $setup.value?.id) ? $setup.selectedColor : $setup.color,
            borderLeft: $setup.isFilterable ? '.25rem solid' : '0',
            borderColor: (index == $setup.currentSelectedIndex) ? $setup.selectedColor : $setup.backgroundColor,
          }),
                onClick: ($event: any) => ($setup.selectOption(option)),
                onMousedown: $setup.handleMouseDownOnItem,
                onMouseup: $setup.handleMouseUpOnItem
              }, [
                (option?.icon)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      loading: "lazy",
                      src: option?.icon,
                      alt: "",
                      style: {"height":"20px","width":"20px","margin-right":".5rem"}
                    }, null, 8 /* PROPS */, _hoisted_7))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("span", null, _toDisplayString(option?.label), 1 /* TEXT */)
              ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_6))
            }), 128 /* KEYED_FRAGMENT */)),
            (!$setup.filteredOptions.length)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, "No results"))
              : _createCommentVNode("v-if", true)
          ], 6 /* CLASS, STYLE */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
            ($setup.isDropdownOpen)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "mobile-dropdown-options-container",
                  style: _normalizeStyle({ backgroundColor: $setup.backgroundColor || 'white', color: $setup.color})
                }, [
                  (!$setup.isFilterable)
                    ? (_openBlock(), _createElementBlock("h2", {
                        key: 0,
                        class: "mobile-dropdown-options-title",
                        style: _normalizeStyle({ color: $setup.color })
                      }, _toDisplayString($setup.placeholder || 'Select option'), 5 /* TEXT, STYLE */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredOptions, (option, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "mobile-dropdown-options-list-item",
                        style: _normalizeStyle({
                  color: (option?.id == $setup.value?.id) ? $setup.selectedColor : $setup.color,
                }),
                        onClick: ($event: any) => ($setup.selectOption(option))
                      }, [
                        _createElementVNode("span", null, _toDisplayString(option?.label), 1 /* TEXT */)
                      ], 12 /* STYLE, PROPS */, _hoisted_11))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  ($setup.isFilterable)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _withDirectives(_createElementVNode("input", {
                          ref: "queryInputMobile",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.query) = $event)),
                          placeholder: $setup.searchPlaceholder,
                          type: "search",
                          role: "presentation",
                          autocomplete: "off",
                          class: "query-input query-input-mobile"
                        }, null, 8 /* PROPS */, _hoisted_13), [
                          [_vModelText, $setup.query]
                        ])
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 4 /* STYLE */))
              : _createCommentVNode("v-if", true),
            ($setup.isDropdownOpen)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "dropdown-back mobile-glass",
                  onClick: $setup.close
                }))
              : _createCommentVNode("v-if", true)
          ]))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isDropdownOpen && !$setup.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "dropdown-back",
          onClick: $setup.close
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}