import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, computed } from 'vue'
import SmallProfileCard from '../components/profile/SmallProfileCard.vue'
import { useProfileStore } from '@/store/profiles-store'
import Dropdown, { type OptionProps } from '@/design-system/DropdownComponent.vue';
import { ProfilesTypesEnum, ProfilesTypesEnumMap } from '@/services/profiles.service'
import CardContainer from '@/components/CardContainer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'profiles',
  setup(__props, { expose: __expose }) {
  __expose();

const profileStore = useProfileStore()

const profiles = computed(() => {
  return profileStore.profiles
})

const profileTypes: OptionProps[] = [
  {
    id: ProfilesTypesEnum.PROFESSIONAL,
    label: 'Professional'
  },
  {
    id: ProfilesTypesEnum.DJ,
    label: 'DJ'
  },
  {
    id: ProfilesTypesEnum.MUSICIAN,
    label: 'Musician'
  },
  {
    id: ProfilesTypesEnum.ORCHESTRA,
    label: 'Orchestra'
  },
  {
    id: ProfilesTypesEnum.TAXI_DANCER,
    label: 'Taxi Dancer'
  },
]

const profileTypeSelected = ref(profileTypes[0])

onMounted(async () => {
    await profileStore.fetchProfiles(ProfilesTypesEnumMap.get(profileTypeSelected.value.id) || ProfilesTypesEnum.PROFESSIONAL)
})

const profileTypeChangeHandler = async function (value: OptionProps) {
  await profileStore.fetchProfiles(ProfilesTypesEnumMap.get(value.id) || ProfilesTypesEnum.PROFESSIONAL)
}


const __returned__ = { profileStore, profiles, profileTypes, profileTypeSelected, profileTypeChangeHandler, SmallProfileCard, Dropdown, CardContainer }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})