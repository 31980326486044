import { defineComponent as _defineComponent } from 'vue'
import FavoriteEventCard from '/src/components/event/FavoriteEventCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'favorite',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { FavoriteEventCard }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})