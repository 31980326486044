import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FavoriteEventCard',
  setup(__props, { expose: __expose }) {
  __expose();

onMounted(() => console.log('FavoriteEventCard mounted.'))


const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})