import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"border-top-right-radius":".5rem","border-top-left-radius":".5rem","overflow":"hidden"} }
const _hoisted_2 = { class: "event-card-middle" }
const _hoisted_3 = {
  key: 0,
  class: "book-btn"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "book-btn disable-book-btn"
}
const _hoisted_6 = { class: "event-card-middle-buttons" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "event-card-desc" }
const _hoisted_10 = { class: "max-2-lines cursor-pointer event-card-title" }
const _hoisted_11 = { class: "event-location-container" }
const _hoisted_12 = { class: "event-card-location" }
const _hoisted_13 = { class: "text-left max-2-lines" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { persisted: "" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          style: {"padding":"0","scroll-snap-align":"start","position":"relative","width":"100%"},
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.clicked({item_id: $props.item_id, country:$props.country, region:$props.region, domain:$props.domain})))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["CardImage"], {
              image: $props.image,
              title: $props.title
            }, null, 8 /* PROPS */, ["image", "title"]),
            _createElementVNode("div", _hoisted_2, [
              ($props.process != 'LISTED' && $setup.isBookingButtonDisplayed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: require('@/assets/icons/ticket.svg'),
                      alt: "ticket",
                      class: "event-card-img"
                    }, null, 8 /* PROPS */, _hoisted_4),
                    _createElementVNode("div", null, _toDisplayString($setup.bookButonLabel), 1 /* TEXT */)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString($setup.t('listed')), 1 /* TEXT */)
                  ])),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: _normalizeClass(["rounded-button favourite-button", { disabled: $setup.processingToggleFavorite }]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.addFavorite()), ["stop"]))
                }, [
                  _createElementVNode("img", {
                    src: $setup.event.favourite ? $setup.markedFavImage : $setup.unmarkedFavImage,
                    alt: "thump-green-img"
                  }, null, 8 /* PROPS */, _hoisted_7)
                ], 2 /* CLASS */),
                _createElementVNode("div", {
                  class: "rounded-button map-link-button",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.handleMapClick()), ["stop"]))
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/icons/pin_map.svg'),
                    alt: "map-button"
                  }, null, 8 /* PROPS */, _hoisted_8)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString($props.title), 1 /* TEXT */),
              _createVNode($setup["EventDateTime"], {
                date: $props.date,
                "end-date": $props.to_date
              }, null, 8 /* PROPS */, ["date", "end-date"]),
              _createElementVNode("div", _hoisted_11, [
                _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa-solid fa-location-dot" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString($props.location), 1 /* TEXT */)
                ])
              ])
            ])
          ])
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.mounted]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["ConfirmDialog"], {
      ref: "confirmClearCartDialogRef",
      title: $setup.t('Confirmation'),
      message: $setup.t('ItemsInCartWarningMessage'),
      "confirm-button-label": $setup.t('button.yes'),
      "cancel-button-label": $setup.t('button.no'),
      onConfirm: _cache[3] || (_cache[3] = ($event: any) => ($setup.clearCart()))
    }, null, 8 /* PROPS */, ["title", "message", "confirm-button-label", "cancel-button-label"])
  ], 64 /* STABLE_FRAGMENT */))
}