import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", {
    class: "footer",
    style: _normalizeStyle($setup.footerStyle)
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "label" }, "Powered by", -1 /* HOISTED */)),
    _createVNode(_component_router_link, { to: '/' }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "pots-logo",
          src: $setup.logoSrc,
          alt: "Main logo",
          sizes: "(max-width: 768px) 100vw, 400px",
          loading: "lazy"
        }, null, 8 /* PROPS */, _hoisted_1)
      ]),
      _: 1 /* STABLE */
    })
  ], 4 /* STYLE */))
}