import { createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { ref: "carouselRef" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "centered-label"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["CarouselComponent"], {
        loading: $setup.isLoading,
        events: $setup.sponsoredEvents
      }, null, 8 /* PROPS */, ["loading", "events"])
    ], 512 /* NEED_PATCH */),
    _withDirectives(_createVNode($setup["Filters"], {
      location: $setup.location,
      "is-international": $setup.isInternational,
      "starting-position": $setup.carouselHeight,
      onRegionChanged: $setup.regionChanged,
      onMonthChanged: $setup.monthChanged,
      onDayChanged: $setup.dayChanged,
      onGoToMap: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleGoToMapEvent()))
    }, null, 8 /* PROPS */, ["location", "is-international", "starting-position"]), [
      [_vShow, !$setup.isLoading]
    ]),
    _createCommentVNode("<Events v-if=\"(filteredSectionById.get('SOCIAL') ?? []).length\" :id=\"-1\" :title=\"'SOCIAL'\" :filtered-events=\"filteredSectionById.get('SOCIAL') ?? []\" @go-to-map=\"handleGoToMapEvent\"/>"),
    (!$setup.isProcessing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(['SOCIAL', ...$setup.sections], (section, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (($setup.filteredSectionById.get(section) ?? []).length)
                ? (_openBlock(), _createBlock($setup["Events"], {
                    key: 0,
                    id: index,
                    title: section == 'SOCIAL' ? $setup.dayInString : $setup.t(`sections.${section}`),
                    "filtered-events": $setup.filteredSectionById.get(section)  ?? [],
                    onGoToMap: $setup.handleGoToMapEvent
                  }, null, 8 /* PROPS */, ["id", "title", "filtered-events"]))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ((!$setup.isLoading && !$setup.isProcessing) && !$setup.filteredItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.t('NoResults')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("\n    <Events :id=\"1\" :title=\"dayInString\" :filtered-events=\"filteredRegularEvents ?? []\" @go-to-map=\"handleGoToMapEvent\"/>\n    <Categories :campaign-data=\"campaignData\" />\n    <Events v-if=\"filteredBegginnerEvents.length\" :id=\"2\" :title=\"t('beginners')\"\n        :filtered-events=\"filteredBegginnerEvents ?? []\" :show-begginers-filter=\"true\"\n        @go-to-map=\"handleGoToMapEvent\"/>\n    <Events v-if=\"filteredHighlightedEvents.length\" :id=\"3\" :title=\"t('highlighted')\"\n        :filtered-events=\"filteredHighlightedEvents ?? []\"\n        @go-to-map=\"handleGoToMapEvent\"/>\n    "),
    _createCommentVNode(" add to favorite "),
    ($setup.userStore.isLoggedIn && !$setup.isLoading && !$setup.isProcessing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          ($setup.filteredFavoriteRegularEvents.length)
            ? (_openBlock(), _createBlock($setup["Events"], {
                key: 0,
                id: 4,
                title: $setup.t('favorites'),
                "filtered-events": $setup.filteredFavoriteRegularEvents ?? [],
                onGoToMap: $setup.handleGoToMapEvent
              }, null, 8 /* PROPS */, ["title", "filtered-events"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode("<Categories :campaign-data=\"campaignData\" />"),
    _createCommentVNode("\n    <Events v-if=\"filteredNewEvents.length\" :id=\"5\" :title=\"t('recentAdded')\"\n        :filtered-events=\"filteredNewEvents ?? []\"\n        @go-to-map=\"handleGoToMapEvent\"/>\n    "),
    _withDirectives(_createVNode($setup["GoogleMap"], {
      ref: "mapRefEl",
      "map-events": $setup.mapEvents,
      "single-selected-event": $setup.selectedEventInMap
    }, null, 8 /* PROPS */, ["map-events", "single-selected-event"]), [
      [_vShow, !$setup.isLoading && !$setup.isProcessing]
    ]),
    ($setup.isLoading || $setup.isProcessing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}