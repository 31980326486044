import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cart-item-header" }
const _hoisted_2 = { class: "cart-item-title" }
const _hoisted_3 = {
  key: 0,
  class: "potential-items-container"
}
const _hoisted_4 = { class: "potential-item-content" }
const _hoisted_5 = { class: "potential-item-title" }
const _hoisted_6 = { class: "potential-item-date" }
const _hoisted_7 = { class: "potential-item-actions" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "cart-item-actions" }
const _hoisted_10 = { class: "cart-item-footer" }
const _hoisted_11 = { class: "cart-item-quantity" }
const _hoisted_12 = { class: "cart-item-content" }
const _hoisted_13 = { class: "cart-item-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "cart-item-row",
    style: _normalizeStyle({
      color: `${$setup.colorStyle.primaryColor}`,
      backgroundColor: `${$setup.colorStyle.secondaryColor}`,
      borderColor: `${$setup.colorStyle.primaryColor}`,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(($setup.eventStore.showDescriptionInEnglish && $setup.cartItem.titleENG) ? $setup.cartItem.titleENG : $setup.cartItem.title), 1 /* TEXT */)
    ]),
    ($setup.cartItem.selected_potentional_items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cartItem.selected_potentional_items, (event_potential) => {
            return (_openBlock(), _createElementBlock("div", {
              key: event_potential.activityId,
              class: "potential-item-container"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(($setup.eventStore.showDescriptionInEnglish && event_potential.titleENG) ? event_potential.titleENG : event_potential.title), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString($setup.t(event_potential.weekDay)), 1 /* TEXT */),
                  _cache[0] || (_cache[0] = _createElementVNode("span", null, " ", -1 /* HOISTED */)),
                  _createElementVNode("span", null, _toDisplayString(event_potential.startTime.hour) + ":" + _toDisplayString(event_potential.startTime.minutes), 1 /* TEXT */),
                  _cache[1] || (_cache[1] = _createElementVNode("span", null, " - ", -1 /* HOISTED */)),
                  _createElementVNode("span", null, _toDisplayString(event_potential.endTime.hour) + ":" + _toDisplayString(event_potential.endTime.minutes), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                (!$setup.eventBookingStore.defaultBookingItem)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "delete-cart-item-button",
                      style: _normalizeStyle({
              color: `${$setup.colorStyle.primaryColor}`,
              border: 'none'
            }),
                      onClick: ($event: any) => ($setup.cartStore.removePotentialItemFromCart(event_potential.activityId, $setup.cartItem))
                    }, [...(_cache[2] || (_cache[2] = [
                      _createElementVNode("i", {
                        class: "fa fa-times",
                        "aria-hidden": "true"
                      }, null, -1 /* HOISTED */)
                    ]))], 12 /* STYLE, PROPS */, _hoisted_8))
                  : _createCommentVNode("v-if", true)
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_9, [
      (!$setup.eventBookingStore.defaultBookingItem)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "delete-cart-item-button",
            style: _normalizeStyle({
            color: `${$setup.colorStyle.primaryColor}`,
            borderColor: `${$setup.colorStyle.primaryColor}`,
          }),
            onClick: $setup.handleRemove
          }, _toDisplayString($setup.t('checkout.remove')), 5 /* TEXT, STYLE */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString($setup.t('cart_page.table.quantity')) + ": " + _toDisplayString($setup.cartItem.labelPrices[$setup.cartStore.getCurrency] == 'COUPLE' ? '1' : $setup.cartItem.count), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, _toDisplayString($setup.cartItem.showPrices[$setup.cartStore.getCurrency]), 1 /* TEXT */)
      ])
    ])
  ], 4 /* STYLE */))
}