import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed } from 'vue';
import { useToast } from 'vue-toastification'
import PasswordInput from '@/design-system/PasswordInput.vue';
import FormItemInput from '@/design-system/FormItemInput.vue';
import { useUserStore } from '@/store/user-store'
import colours from '@/json/defaultColor.json'
import FormComponent from '@/design-system/FormComponent.vue';
import { useI18n } from 'vue-i18n';
import ResetPasswordDialog from './ResetPasswordDialog.vue';
import HorizontalLine from '@/design-system/HorizontalLine.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  emits: ["success", "openSignUpForm"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();
const userStore = useUserStore();

// define emits
const emit = __emit;

const email = ref('');
const password = ref('');
const resetPasswordDialogRef: Ref<typeof ResetPasswordDialog | null> = ref(null)

const defaultColors = computed(() => colours.colours)
const primaryButtonStyle = computed(() => ({
  color: defaultColors.value.buttonTXT,
  backgroundColor: defaultColors.value.buttonBG,
}))
const secondaryButtonStyle = computed(() => ({
  color: defaultColors.value.buttonBG,
  backgroundColor: defaultColors.value.buttonTXT,
}))

// Function to sign in using Tango
async function signInToTango() {
  const Toast = useToast();
  if (!email.value || !password.value) {
    Toast.error(t('toast_translation.fill_the_data'));
  } else {
    try {
      await userStore.login({
        'email': email.value,
        'password': password.value,
      });
      emit('success');
    } catch (error) {
      Toast.error(t('toast_translation.error_mesg_signInUSer'));
    }
  }
}

// Function to handle Google login callback
async function googleLoginCallback(googleUser: any) {
  try {
    const user = await userStore.googleLogin(googleUser);
    if (user.registered) {
      emit('success');
    } else {
      openSignUpForm()
    }
    
    return user
  } catch (error) {
    const Toast = useToast();
    Toast.error(t('toast_translation.error_mesg_signInUSer'));
  }
}

function toggleForgotPassword () {
  resetPasswordDialogRef.value?.open()
}

function openSignUpForm() {
  emit('openSignUpForm')
}


const __returned__ = { t, userStore, emit, email, password, resetPasswordDialogRef, defaultColors, primaryButtonStyle, secondaryButtonStyle, signInToTango, googleLoginCallback, toggleForgotPassword, openSignUpForm, PasswordInput, FormItemInput, FormComponent, ResetPasswordDialog, HorizontalLine }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})