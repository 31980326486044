import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "width-100 text-center",
  style: {"height":"300px"}
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.profileStore.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { style: {"top":"50%","position":"relative"} }, "Loading profiles...", -1 /* HOISTED */)
        ])))
      : ($setup.profiles.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode($setup["Dropdown"], {
              modelValue: $setup.profileTypeSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.profileTypeSelected) = $event)),
              options: $setup.profileTypes,
              disabled: false,
              class: "m-2",
              onChange: $setup.profileTypeChangeHandler
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["CardContainer"], null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.profiles, (profile, index) => {
                  return (_openBlock(), _createBlock($setup["SmallProfileCard"], {
                    key: index,
                    "profile-id": profile.id,
                    "avatar-image-url": profile?.logoUrl,
                    "cover-image-url": profile?.pictureUrl,
                    name: profile.name
                  }, null, 8 /* PROPS */, ["profile-id", "avatar-image-url", "cover-image-url", "name"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, " No profiles "))
  ]))
}