import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/store/user-store'
import { useEventBookingStore } from '@/store/booking-store';
import { useEventStore } from '@/store/event-store';
import DialogComponent from '@/design-system/DialogComponent.vue'
import { useI18n } from 'vue-i18n';
import { useSumUp } from '@/composable/useSumUp';

export default /*@__PURE__*/_defineComponent({
  __name: 'SumUpPaymentDialog',
  props: {
    paymentMethodData: { type: null, required: true }
  },
  emits: ["success", "close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const eventBookingStore = useEventBookingStore()
const userStore = useUserStore()
const eventStore = useEventStore()

const props = __props;

// define emits
const emit = __emit;

const error: Ref<string | null> = ref(null);
const paymentData: any = ref(null);
const sumupDialogRef: Ref<typeof DialogComponent | null> = ref(null)
const { sumUpLoaded, sumUpProcessing, loadSumUpScript, openSumUpFormAndProcessPayment } = useSumUp()

const paymentMethodData = computed(() => props.paymentMethodData)

async function open() {
    try {
        sumupDialogRef.value?.open()
        await loadSumUpScript();
        await startPaymentProcess();
    } catch (error: any) {
        console.log('Error loading SumUp:', error)
        error.value = error.message
    }
}

function onClose () {
    emit('close'); 
    eventBookingStore.cancelPayment()
}

function redirectToConfirmationPage() {
    sumupDialogRef.value?.close()
    emit('success');
}

async function initializePayment() {
    const userId = userStore.user?.id;

    if (!userId) {
        throw new Error(t('toast_translation.login_first'))
    } else {
        console.log('initialize sumup payment')
        return await eventBookingStore.createPayment(paymentMethodData.value.id)
    }
}

async function startPaymentProcess() {
    const Toast = useToast();
    try {
        paymentData.value = await initializePayment();
    } catch (error: any) {
        Toast.error(error.message);
    }
    if (paymentData.value) {
        console.log('Payment Data: ', paymentData.value)
        openSumUpFormAndProcessPayment(paymentData.value.paymentIntent, (payload: any) => {
            Toast.success(t('payment.payment_successful'));
            redirectToConfirmationPage();
        }, (error: any) => {
            Toast.error(error.message);
        });
    }
}

__expose({
  open
})

const __returned__ = { t, eventBookingStore, userStore, eventStore, props, emit, error, paymentData, sumupDialogRef, sumUpLoaded, sumUpProcessing, loadSumUpScript, openSumUpFormAndProcessPayment, paymentMethodData, open, onClose, redirectToConfirmationPage, initializePayment, startPaymentProcess, DialogComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})