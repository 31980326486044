import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, StyleValue, inject, onMounted } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionItemComponent',
  props: {
    title: { type: String, required: false },
    value: { type: String, required: true },
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true }
  },
  emits: ["change"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const { count, isRadio, activeRow } = inject('AccordionComponent', () => {}) as any

const props = __props;

// define emits
const emit = __emit;

const index = ref(NaN)

const visible = computed(() => index.value == activeRow.value)
const value = computed(() => props.value)
const title = computed(() => props.title)
const style = computed(() => props.style)

onMounted(() => {
  index.value = count.value++;
})

function open() {
  if (visible.value) {
    activeRow.value = null;
    emit('change', null)
  } else {
    emit('change', value.value)
    activeRow.value = index.value;
  }
}

function start(el: HTMLElement) {
  el.style.height = el.scrollHeight + "px";
}

function end(el: HTMLElement) {
  el.style.height = "";
}

const __returned__ = { count, isRadio, activeRow, props, emit, index, visible, value, title, style, open, start, end }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})