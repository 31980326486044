import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container pb-5" }
const _hoisted_2 = { id: "sumup-card" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { style: {"text-align":"center","padding":"1rem"} }
const _hoisted_7 = {
  key: 3,
  style: {"text-align":"center"}
}
const _hoisted_8 = { style: {"text-align":"left","padding":"1rem","border":"1px solid black","border-radius":"1rem"} }
const _hoisted_9 = { style: {"margin":"0"} }
const _hoisted_10 = { style: {"color":"grey"} }
const _hoisted_11 = { style: {"margin":"0"} }
const _hoisted_12 = {
  key: 0,
  style: {"margin":"0.5rem -1rem"}
}
const _hoisted_13 = {
  key: 4,
  style: {"margin-top":"500px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */), [
      [_vShow, $setup.sumUpLoaded]
    ]),
    ((!$setup.sumUpLoaded || $setup.sumUpIsLoading) && !$setup.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["PotsLoader"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.errorMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.paymentInProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString($setup.t('payment.payment_in_progress_please_wait')), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.debug && $setup.infoLog.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.infoLog, (line, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("pre", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(line.time), 1 /* TEXT */),
                  _createTextVNode(": " + _toDisplayString(line.message), 1 /* TEXT */)
                ]),
                _createElementVNode("pre", _hoisted_11, _toDisplayString(line.value), 1 /* TEXT */),
                (index !== $setup.infoLog.length - 1)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_12))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.log, (line, index) => {
            return (_openBlock(), _createElementBlock("pre", { key: index }, "        " + _toDisplayString(line) + "\n      ", 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}