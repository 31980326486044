import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, onMounted } from 'vue'
import { followProfile, isFollowedProfile, unfollowProfile, ProfilesInterface } from '@/services/profiles.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileCard',
  props: {
    profile: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const profile = computed <ProfilesInterface> (() => {
  return props.profile
})

const loadingIsFollow = ref(true)
const isFollowed = ref(false)

const follow = async () => {
  const response = await followProfile(profile.value.id)
  console.log('Follow: ', response)
}

const unfollow = async () => {
  const response = await unfollowProfile(profile.value.id, 'some-user-id')
  console.log('Unfollow: ', response)
}

onMounted(async () => {
  loadingIsFollow.value = true
  try {
    const response = await isFollowedProfile(profile.value.id)
    console.log('Is Followed: ', response)
    isFollowed.value = response
    loadingIsFollow.value = false
  } catch (e) {
    console.log('Error: ', e)
  }
})


const __returned__ = { props, profile, loadingIsFollow, isFollowed, follow, unfollow }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})