import { defineStore } from 'pinia'
import { ref, computed, Ref } from 'vue'
import { STEPS, useEventBookingStore } from './booking-store'
import { useEventStore } from './event-store'
import { toRaw } from 'vue'
import { useUserStore } from './user-store'

export const useCartStore = defineStore('cart', () => {
  const savedCart = JSON.parse(localStorage.getItem('cart') || '{}')
  const userStore = useUserStore()
  const savedUser =  userStore.user?.id || 'GUEST'
  const savedEvent = localStorage.getItem("eventId");
  let savedItems = []
  let savedCurrency = ''
  if (savedCart.eventId === savedEvent && savedCart.userId == savedUser) {
    savedCurrency = savedCart.currency || ''
    savedItems = savedCurrency ? savedCart.items : []
  }
  const items: Ref<Array<any>> = ref(savedItems)
  const currency: Ref<string> = ref(savedCurrency)

  const getItems = computed(() => items.value)
  const isEmpty = computed(() => !items.value.length)
  const getCurrency = computed(() => currency.value)
  const getCurrencySymbol = computed(() => {
    const currencySymbolMap = {
      'GBP': '£',
      'EUR': '€',
      'USD': '$',
      'ARS': '$',
    }
    return currencySymbolMap[currency.value as keyof typeof currencySymbolMap] || ''
  })
  const cartItemsById = computed(() => {
    return items.value.reduce((acc, item) => ({ ...acc, [item.id]: item}), {})
  })

  const getCartTotal = computed(() => {
    return (items.value || []).reduce((acc, item) => acc + (item.count || 1) * parseFloat(item.fullPrices[getCurrency.value]), 0)
  })

  const getCartPartialTotal = computed(() => {
    return (items.value || []).reduce((acc, item) => acc + (item.count || 1) * parseFloat(item.prices[getCurrency.value]), 0)
  })

  const selectedActivitiesById = computed(() => {
    const selectedActivitiesMap = new Map<string, boolean>()
    items.value.forEach((cartItem) => {
      selectedActivitiesMap.set(cartItem.id, true)
      if (cartItem?.included && cartItem.included.length) {
        cartItem.included.forEach((itemId: string) => {
          selectedActivitiesMap.set(itemId, true)
        })
      }
      if (cartItem?.selected_potentional_items?.length) {
        cartItem?.selected_potentional_items.forEach((item: any) => {
          selectedActivitiesMap.set(item.activityId, true)
        })
      }
    })
    return selectedActivitiesMap
  })

  const isActivityInCart = computed(() => {
    return (eventpack: any) => selectedActivitiesById.value.get(eventpack.id)
  })

  const selectedPotencialItemsById = computed(() => {
    const selectedPotencialItemsMap = new Map<string, any>()
    items.value.forEach((cartItem) => {
      if (cartItem?.selected_potentional_items?.length) {
        cartItem?.selected_potentional_items.forEach((item: any) => {
          selectedPotencialItemsMap.set(item.activityId, cartItem)
        });
      }
    })
    return selectedPotencialItemsMap
  })

  /**
   * @returns The cart item
   */
  const isPotentialItemInCart = computed(() => {
    return (id: string) => selectedPotencialItemsById.value.get(id)
  })

  const getInvalidItemsWithPotentials = computed(() => {
    const invalidItems: any[] = []
    items.value.forEach((cartItem) => {
      if (cartItem.potentials && !cartItem.selected_potentional_items?.length) {
        invalidItems.push(cartItem)
      }
    })
    return invalidItems
  })

  const availableCurrencies = computed(() => {
    const eventBookingStore = useEventBookingStore()
    const eventStore = useEventStore()
    let eventCurrencies = toRaw(eventStore.event?.currencies)
    const firstBookingItem = toRaw(eventBookingStore.getBookingItems[0])
    const defaultCurrencyFromBookingItem = firstBookingItem?.currency || null;
    eventCurrencies = eventCurrencies && eventCurrencies.length ? eventCurrencies : (defaultCurrencyFromBookingItem ? [{ currency: defaultCurrencyFromBookingItem }] : [])
    return eventCurrencies.reduce((acc: any[], curr: any) => {
      acc.push({
        id: curr.currency,
        label: curr.currency
      })
      return acc
    }, [])
  })

  function addToCart(eventPack: any) {
    const eventBookingStore = useEventBookingStore()
    const newCartItem = {
      ...eventPack,
      count: eventBookingStore.isPartnerSelected ? 2 : 1,
      selected_potentional_items: []
    }
    items.value.push(newCartItem)
    saveCartInLocalStorage()
  }

  function removeFromCart(eventPack: any) {
    const index = items.value.findIndex((item) => item.id === eventPack.id);
    if (index > -1) {
        items.value.splice(index, 1);
        saveCartInLocalStorage()
    }
  }

  function removePotentialItemFromCart(potentionalItemId: string, eventPack: any) {
		const cartItem = isPotentialItemInCart.value(potentionalItemId)
		const selectedPotentionalItems = cartItem["selected_potentional_items"];
		const selectedPotentialItemIndex = selectedPotentionalItems.findIndex(
			(obj: any) => obj.activityId === potentionalItemId
		);
		if (selectedPotentialItemIndex > -1) {
			selectedPotentionalItems.splice(selectedPotentialItemIndex, 1);
			if (selectedPotentionalItems.length == 0 ) {
				removeFromCart(eventPack)
        const eventBookingStore = useEventBookingStore()
        if (useCartStore().isEmpty && eventBookingStore.getBookingSteps[eventBookingStore.currentBookingStep].id == STEPS.PAYMENT.id) {
          eventBookingStore.goToSelectionStep()
        }
			} else {
        saveCartInLocalStorage()
      }
		}
	}

  function clear() {
    items.value.length = 0
    saveCartInLocalStorage()
  }

  const isNumber = (value: any) => {
		return typeof value === "number" && !Number.isNaN(value);
	}

  function setCurrency(newCurrency: string) {
    const itemsCopy = items.value.reduce((acc, curr) => [...acc, curr],[])
    clear()
    currency.value = newCurrency
    const eventBookingStore = useEventBookingStore()
    const availableItemsById = eventBookingStore.bookingItemsById
    itemsCopy.forEach((item: any) => {
      const eventPack = availableItemsById[item.id]
      if (eventPack && isNumber(eventPack.prices[currency.value])) { // check if eventPaack exists and is same currency as selected
        addToCart(eventPack)
        setItemCount(cartItemsById.value[item.id], item.count)
        setItemSelectedPotential(cartItemsById.value[item.id], item.selected_potentional_items)
      }
    })
  }

  function setDefaultSelectedCurrency () {
    const defaultSelectedCurrency = availableCurrencies.value.length ? availableCurrencies.value[0] : null
    if (defaultSelectedCurrency) {
      setCurrency(defaultSelectedCurrency.id)
    }
  }

  function setItemCount(item: any, count: number) {
    item.count = count < item.maxQty ? count : item.maxQty
  }

  function setItemSelectedPotential(item: any, potentials: any[]) {
    item.selected_potentional_items = potentials.reduce((acc, curr) => [...acc, curr],[])
    saveCartInLocalStorage()
  }

  function incrementCount(item: any) {
    const cartItem = cartItemsById.value[item.id]
    if (cartItem.count < item.maxQty) {
      cartItem.count += 1
      saveCartInLocalStorage()
    }
  }

  function decrementCount(item: any) {
    const cartItem = cartItemsById.value[item.id]
    if (cartItem.count > item.minQty) {
      cartItem.count -= 1
      saveCartInLocalStorage()
    }
  }

  function saveCartInLocalStorage() {
    const eventStore = useEventStore()
    const userStore = useUserStore()
    localStorage.setItem('cart', JSON.stringify({
      eventId: eventStore.event?.id,
      userId: userStore.user?.id || 'GUEST',
      items: items.value,
      currency: getCurrency.value,
    }))
  }

  return { 
    getItems, isEmpty, cartItemsById, getCartTotal, getCartPartialTotal, isActivityInCart, 
    getCurrency, getCurrencySymbol, isPotentialItemInCart,
    getInvalidItemsWithPotentials, availableCurrencies,
    addToCart, removeFromCart, removePotentialItemFromCart, clear, setCurrency, setDefaultSelectedCurrency,
    incrementCount, decrementCount,
    saveCartInLocalStorage }
})