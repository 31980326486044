import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, Ref, StyleValue, inject, onUnmounted, onMounted } from 'vue'



export default /*@__PURE__*/_defineComponent({
  __name: 'StarComponent',
  props: {
    modelValue: { type: Number, required: true },
    max: { type: Number, required: false },
    name: { type: String, required: false },
    readonly: { type: Boolean, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

// define emits
const emit = __emit;

// define proxyValue for modelValue to emit update:modelValue
const value: ComputedRef<number> = computed(() => props.modelValue);
const max: ComputedRef<number> = computed(() => props.max || 5);
const name: ComputedRef<string> = computed(() => props.name || 'rating');
const readonly: ComputedRef<boolean> = computed(() => props.readonly || false);

function updateInput(val: string): void {
	emit("update:modelValue", parseInt(val, 10))
}


const __returned__ = { props, emit, value, max, name, readonly, updateInput }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})