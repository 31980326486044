import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "payment-details-label" }
const _hoisted_2 = { class: "payment-details-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "payment-details",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.copyText($setup.value)))
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString($setup.label) + ":", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString($setup.value), 1 /* TEXT */),
          _createElementVNode("i", {
            class: _normalizeClass(["fa-solid icon", { 'fa-copy': !$setup.copied, 'fa-check': $setup.copied }])
          }, null, 2 /* CLASS */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}