<template>
  <div class="loader-wrp">
    <div class="loader"></div>
    <div class="loader_box">
      <img :src="require('@/assets/logos/logo_spin.gif')" alt="logo-spin">
    </div>
    <div class="wrapper">
      <div class="waves-block">
          <div class="waves wave-1"></div>
          <div class="waves wave-2"></div>
          <div class="waves wave-3"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loader-wrp {
  width: 100%;
  height: 500px;
  margin: 120px 0px;
  z-index: 9999;
}

.loader_box {
  height: 120px;
  width: 93px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader_box img {
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  /* background: transparent; */
  /* border: 3px solid #3c3c3c; */
  border-radius: 50%;
  text-align: center;
  line-height: 0;
  font-family: sans-serif;
  font-size: 20px;
  color: #006eff;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #006eff;
  animation: animateC 5s linear infinite;
  /* box-shadow: 0 0 20px rgba(0,0,0,.5); */
  display: none;
}

.wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.waves {
  position: absolute;
  width: 500px;
  height: 500px;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -252px;
  bottom: -240px;
  z-index: -1;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}


@media (max-width: 575px) {
    .loader_box {
        height: 100px;
        width: 100px;
        transform: translate(-50%, -83%);
    }

    .waves {
      width: auto;
      height: auto;
    }
}
</style>

