import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.bookingEnabled)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "btn btn-outline",
        disabled: $setup.isDisabled,
        style: _normalizeStyle($setup.style),
        onClick: $setup.handleClick
      }, _toDisplayString($setup.label), 13 /* TEXT, STYLE, PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}