import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileFloatingFooter',
  props: {
    color: { type: String, required: true },
    backgroundColor: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const color: ComputedRef<string> = computed(() => props.color);
const backgroundColor: ComputedRef<string> = computed(() => props.backgroundColor);


const __returned__ = { props, color, backgroundColor }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})