<script setup lang="ts">

</script>
<template>
    <div>
        <div class="container">
            <div class="row">
                    <div class="col-12">
                        <!-- Page title -->
                        <div class="my-5">
                            <h3>My Profile</h3>
                            <hr>
                        </div>
                        <!-- Form START -->
                        <form class="file-upload">
                            <div class="row mb-5 gx-5">
                                <!-- Upload profile -->
                                <div class="col-md-4">
                                    <div class="bg-secondary-soft px-4 py-5 rounded">
                                        <div class="row g-3">
                                            <h4 class="mb-4 mt-0">Upload your profile photo</h4>
                                            <div class="text-center">
                                                <!-- Image upload -->
                                                <div class="square position-relative display-2 mb-3">
                                                    <i class="fas fa-fw fa-user position-absolute top-50 start-50 translate-middle text-secondary"></i>
                                                </div>
                                                <!-- Button -->
                                                <input type="file" id="customFile" name="file" hidden="">
                                                <label class="btn btn-success-soft btn-block" for="customFile">Upload</label>
                                                <button type="button" class="btn btn-danger-soft">Remove</button>
                                                <!-- Content -->
                                                <p class="text-muted mt-3 mb-0"><span class="me-1">Note:</span>Minimum size 300px x 300px</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Contact detail -->
                                <div class="col-md-8">
                                    <div class="bg-secondary-soft px-4 py-5 rounded">
                                        <div class="row g-3">
                                            <h4 class="mb-4 mt-0">Contact detail</h4>
                                            <!-- First Name -->
                                            <div class="col-md-6">
                                                <!-- <label class="form-label float-left">First Name *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="First name" v-model="firstName"> -->
                                                <label class="form-label float-left">First Name *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="First name" value="Admin">
                                            </div>
                                            <!-- Last name -->
                                            <div class="col-md-6">
                                                <!-- <label class="form-label float-left">Last Name *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Last name" v-model="lastName"> -->
                                                <label class="form-label float-left">Last Name *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Last name" value="Admin">
                                            </div>
                                            <!-- Phone number -->
                                            <div class="col-md-6">
                                                <label class="form-label float-left">Phone number *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Phone number" value="(333) 000 555">
                                            </div>
                                            <!-- Mobile number -->
                                            <div class="col-md-6">
                                                <label class="form-label float-left">Mobile number *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Phone number" value="+91 9852 8855 252">
                                            </div>
                                            <!-- Email -->
                                            <div class="col-md-6">
                                                <label for="inputEmail4" class="form-label float-left">Email *</label>
                                                <input type="email" class="form-control" id="inputEmail4" value="example@homerealty.com">
                                            </div>
                                            <!-- Skype -->
                                            <div class="col-md-6">
                                                <label class="form-label float-left">Skype *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Phone number" value="Scaralet D">
                                            </div>
                                        </div> <!-- Row END -->
                                    </div>
                                </div>
                                
                            </div> <!-- Row END -->
            
                            <!-- Social media detail -->
                            <!-- <div class="row mb-5 gx-5"> -->
                                <!-- <div class="col-xxl-6 mb-5 mb-xxl-0">
                                    <div class="bg-secondary-soft px-4 py-5 rounded">
                                        <div class="row g-3">
                                            <h4 class="mb-4 mt-0">Social media detail</h4>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fab fa-fw fa-facebook me-2 text-facebook"></i>Facebook *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Facebook" value="http://www.facebook.com">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fab fa-fw fa-twitter text-twitter me-2"></i>Twitter *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Twitter" value="http://www.twitter.com">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fab fa-fw fa-linkedin-in text-linkedin me-2"></i>Linkedin *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Linkedin" value="http://www.linkedin.com">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fab fa-fw fa-instagram text-instagram me-2"></i>Instagram *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Instragram" value="http://www.instragram.com">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fas fa-fw fa-basketball-ball text-dribbble me-2"></i>Dribble *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Dribble" value="http://www.dribble.com">
                                            </div>
                                            <div class="col-md-6">
                                                <label class="form-label"><i class="fab fa-fw fa-pinterest text-pinterest"></i>Pinterest *</label>
                                                <input type="text" class="form-control" placeholder="" aria-label="Pinterest" value="http://www.pinterest.com">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- change password -->
                                <!-- <div class="col-xxl-6">
                                    <div class="bg-secondary-soft px-4 py-5 rounded">
                                        <div class="row g-3">
                                            <h4 class="my-4">Change Password</h4>
                                            <div class="col-md-6">
                                                <label for="exampleInputPassword1" class="form-label">Old password *</label>
                                                <input type="password" class="form-control" id="exampleInputPassword1">
                                            </div>
                                            <div class="col-md-6">
                                                <label for="exampleInputPassword2" class="form-label">New password *</label>
                                                <input type="password" class="form-control" id="exampleInputPassword2">
                                            </div>
                                            <div class="col-md-12">
                                                <label for="exampleInputPassword3" class="form-label">Confirm Password *</label>
                                                <input type="password" class="form-control" id="exampleInputPassword3">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            <!-- </div>  -->
                            <!-- Row END -->
                            <!-- button -->
                            <div class="gap-3 d-md-flex justify-content-md-end text-center">
                                <button type="button" class="btn btn-danger btn-lg">Cancel</button>
                                <button type="button" class="btn btn-primary btn-lg">Save</button>
                            </div>
                        </form> <!-- Form END -->
                    </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

.float-left {
    float: left !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.file-upload .square {
    height: 250px;
    width: 250px;
    margin: auto;
    vertical-align: middle;
    border: 1px solid #e5dfe4;
    background-color: #fff;
    border-radius: 5px;
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(208, 212, 217, 0.5) !important;
}

.btn-success-soft {
    color: #28a745;
    background-color: rgba(40, 167, 69, 0.1);
}

.btn-danger-soft {
    color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
}

.bg-secondary-soft {
    background-color: rgba(208, 212, 217, 0.1) !important;
}

.rounded {
    border-radius: 5px !important;
}
</style>
