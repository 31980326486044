import { defineComponent as _defineComponent } from 'vue'
import { ComputedRef, StyleValue, computed, ref, Ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import DropdownComponent, { OptionProps } from '@/design-system/DropdownComponent.vue' 
import { useEventStore } from '@/store/event-store';
import { useCartStore } from '@/store/cart-store';
import { useEventBookingStore } from '@/store/booking-store';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingNavbar',
  props: {
    prevNavigationHidden: { type: Boolean, required: false },
    nextNavigationHidden: { type: Boolean, required: false },
    prevNavigationDisabled: { type: Boolean, required: false },
    nextNavigationDisabled: { type: Boolean, required: false },
    editCurrencyDisabled: { type: Boolean, required: false },
    sessionTime: { type: String, required: false }
  },
  emits: ["next", "prev"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const eventStore = useEventStore()
const cartStore = useCartStore()
const eventBookingStore = useEventBookingStore()

const { t } = useI18n()

const props = __props;

const selectedCurrency: Ref<OptionProps | null> = ref(null) // TODO use currency from cartStore

// define emits
const emit = __emit;

const prevNavigationHidden: ComputedRef<boolean> = computed(() => props.prevNavigationHidden);
const nextNavigationHidden: ComputedRef<boolean> = computed(() => props.nextNavigationHidden);
const prevNavigationDisabled: ComputedRef<boolean> = computed(() => props.prevNavigationDisabled);
const nextNavigationDisabled: ComputedRef<boolean> = computed(() => props.nextNavigationDisabled);
const editCurrencyDisabled: ComputedRef<boolean> = computed(() => props.editCurrencyDisabled);
const sessionTime: ComputedRef<string> = computed(() => props.sessionTime || '');

onMounted(() => {
  selectedCurrency.value = {
    id: cartStore.getCurrency,
    label: cartStore.getCurrency,
  }
})

function onCurrencyChange(option: OptionProps | null) {
    cartStore.setCurrency(option?.id || '')
}

const hideTotal: ComputedRef<boolean> = computed(() => {
  return eventBookingStore.currentBookingStep === 0 && eventStore.event?.process != 'BOOKING' || eventBookingStore.currentBookingStep === 2 || !!eventBookingStore.defaultBookingItem 
});

const buttonStyle: ComputedRef<StyleValue> = computed(() => {
  return {
    "color": eventStore.eventStyle.buttonTXT,
    "background-color": eventStore.eventStyle.buttonBG,
  };
});

const headerStyle: ComputedRef<StyleValue> = computed(() => {
  return {
    "color": eventStore.eventStyle.headerTXT,
    "background-color": eventStore.eventStyle.headerBG,
  };
});

const next = () => {
  emit("next");
}

const prev = () => {
  emit("prev");
}

const __returned__ = { eventStore, cartStore, eventBookingStore, t, props, selectedCurrency, emit, prevNavigationHidden, nextNavigationHidden, prevNavigationDisabled, nextNavigationDisabled, editCurrencyDisabled, sessionTime, onCurrencyChange, hideTotal, buttonStyle, headerStyle, next, prev, DropdownComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})