import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from 'vue';
import { useCartStore } from '@/store/cart-store';
import { useEventStore } from '@/store/event-store'
import { useEventBookingStore } from '@/store/booking-store';
import ConfirmDialog from '@/design-system/ConfirmDialog.vue';
import CartItem from './CartItem.vue';
import { useI18n } from 'vue-i18n';

export default /*@__PURE__*/_defineComponent({
  __name: 'ShoppingCart',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const cartStore = useCartStore()
const eventStore = useEventStore()
const eventBookingStore = useEventBookingStore()

const confirmClearCartDialogRef: Ref<typeof ConfirmDialog | null> = ref(null)

const UserclearCart = async () => {
    if (eventStore.event?.process !== 'BOOKING') {
      eventBookingStore.goToPrevBookingStep()
    } else {
        eventBookingStore.goToBookingStep(0)
    }
    try {
        await eventBookingStore.clearCart()
    } catch (error) {
        console.log(error);
    }
}

const __returned__ = { t, cartStore, eventStore, eventBookingStore, confirmClearCartDialogRef, UserclearCart, ConfirmDialog, CartItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})