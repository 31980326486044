import { Ref, computed, ref } from 'vue';
import { useInterval } from './useInterval';
import moment from 'moment';

const SESSION_TIME = 600 // 10 minutes in seconds
const WARNING_TIME = 90  // 1.5 minutes in seconds
const EXTRA_TIME   = 300 // 5 minutes in seconds

export function useSessionCountdown(params: {
  onRunningOutOfTime: () => void,
  onTimeout: () => void,
}) {

  const sessionTimeCountDown: Ref<number> = ref(SESSION_TIME)

  const sessionTime = computed(() => {
    return moment.utc(sessionTimeCountDown.value*1000).format('mm:ss');
  })

  const { start, stop } = useInterval(() => {
    if (sessionTimeCountDown.value == WARNING_TIME) {
      try {
        params.onRunningOutOfTime()
      } catch (e) {
        console.log('Error: ', e)
      }
    }
    if (sessionTimeCountDown.value == 0) {
      params.onTimeout()
      stop()
    } else {
      sessionTimeCountDown.value -= 1;
    }
  })

  const addMoreTime = () => {
    sessionTimeCountDown.value = EXTRA_TIME
  }

  const resetSessionTimer = () => {
    sessionTimeCountDown.value = SESSION_TIME
  }

  const startSessionTimer = () => {
    resetSessionTimer()
    start()
  }

  const resumeSessionTimer = () => {
    start()
  }

  const stopSessionTimer = () => {
    stop()
  }

  return { startSessionTimer, resumeSessionTimer, stopSessionTimer, sessionTime, addMoreTime };
}