import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-header"
}
const _hoisted_2 = { class: "dialog-header-title" }
const _hoisted_3 = { class: "dialog-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    ($setup.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dialog-component-popup-glass",
          style: _normalizeStyle({ zIndex: 1999 + (100*$setup.numberOfOpenDialogs) }),
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.onClose()))
        }, [
          _createElementVNode("div", {
            class: "dialog-component",
            style: _normalizeStyle({
                    color: `${$setup.dialogColorStyle.primaryColor}`,
                    backgroundColor: `${$setup.dialogColorStyle.secondaryColor}`,
                    border: `2px solid ${$setup.dialogColorStyle.primaryColor}`,
                    maxWidth: $setup.width,
                    minWidth: '400px',
                    zIndex: 2000 + (100*$setup.numberOfOpenDialogs),
                }),
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
          }, [
            (!$setup.hideHeader)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString($setup.title), 1 /* TEXT */),
                  _createElementVNode("button", {
                    style: _normalizeStyle({ color: $setup.buttonColorStyle.primaryColor, backgroundColor: $setup.buttonColorStyle.secondaryColor, border: `1px solid ${$setup.buttonColorStyle.primaryColor}`}),
                    class: "close-button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClose()))
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", {
                      class: "fa fa-times",
                      "aria-hidden": "true"
                    }, null, -1 /* HOISTED */)
                  ]), 4 /* STYLE */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            (!$setup.hideFooter && !_ctx.$slots.footer)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "dialog-footer",
                  style: _normalizeStyle({borderTop: `1px solid ${$setup.dialogColorStyle.primaryColor}`})
                }, [
                  _createElementVNode("button", {
                    style: _normalizeStyle({
                        color: `${$setup.buttonColorStyle.primaryColor}`,
                        backgroundColor: `${$setup.buttonColorStyle.secondaryColor}`,
                        border: `1px solid ${$setup.buttonColorStyle.primaryColor}`}),
                    class: "dialog-button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onClose()))
                  }, _toDisplayString($setup.closeButtonLabel), 5 /* TEXT, STYLE */)
                ], 4 /* STYLE */))
              : _createCommentVNode("v-if", true),
            (!$setup.hideFooter && _ctx.$slots.footer)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "dialog-footer",
                  style: _normalizeStyle({borderTop: `1px solid ${$setup.dialogColorStyle.primaryColor}`})
                }, [
                  _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ], 4 /* STYLE */))
              : _createCommentVNode("v-if", true)
          ], 4 /* STYLE */)
        ], 4 /* STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}