import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "form-container",
    name: $setup.name,
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}