import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref, onMounted, ComputedRef } from 'vue';
	import { useToast } from "vue-toastification";
	import BookingNavbar from '../BookingNavbar.vue'
	import { useUserStore } from '@/store/user-store'
	import { useCartStore } from '@/store/cart-store';
	import { useEventStore } from "@/store/event-store";
	import { useEventBookingStore } from "@/store/booking-store";
	import DialogComponent from '@/design-system/DialogComponent.vue'
	import { useI18n } from 'vue-i18n';
	import moment from "moment";

	
export default /*@__PURE__*/_defineComponent({
  __name: 'stepSelection',
  setup(__props, { expose: __expose }) {
  __expose();

  const eventBookingStore = useEventBookingStore()
	const userStore = useUserStore()
	const eventStore = useEventStore()
	const cartStore = useCartStore()
	const { t } = useI18n();

	const activeEventPack: Ref<any> = ref(null)
	const ModalImage = ref(null)
	const imageDialog: Ref<any> = ref(null)
	const potentialsDialog: Ref<any> = ref(null)
	const showMoreDialogRef: Ref<{ [key: string]: any }> = ref({})

	const timeSlotsBooked: ComputedRef<any> = computed(() => {
		const weekSlots = {
			'Monday': {},
			'Tuesday': {},
			'Wednesday': {},
			'Thursday': {},
			'Friday': {},
			'Saturday': {},
			'Sunday': {},
		}
		cartStore.getItems.forEach((event: any) => {
			let selectedPotentionalItems = cartStore.cartItemsById[event.id]['selected_potentional_items'];
			selectedPotentionalItems.forEach((potentionalItem: any) => {
				const { startTimeSlot, endTimeSlot, weekDay } = potentionalItem
				for (let i = startTimeSlot; i < endTimeSlot; i++) {
					weekSlots[weekDay][i] = true
				}
			})
		})
		return weekSlots
	})

  const bodyTextColorStyle = computed(() => ({
		'color': eventStore.eventStyle.bodyTXT
	}))

	const borderStyle = computed(() => ({
		'border': `1px solid ${eventStore.eventStyle.selectedTXT}`
	}))

	const selectedActivityStyle = computed(() => ({
		'color': eventStore.eventStyle.bodyBG,
		'background-color': eventStore.eventStyle.bodyTXT,
	}))

	const activityStyle = computed(() => ({
		'color': eventStore.eventStyle.bodyTXT,
		'background-color': eventStore.eventStyle.bodyBG,
	}))

	const eventButtonStyle = computed(() => ({
		"color": eventStore.eventStyle.buttonTXT,
		"background-color": eventStore.eventStyle.buttonBG,
	}))

	const selectedPotentionalItems = computed(() => {
		const activeEventPackId = activeEventPack.value?.id;
		return activeEventPackId && cartStore.cartItemsById[activeEventPackId]
			? cartStore.cartItemsById[activeEventPackId]["selected_potentional_items"]
			: [];
	});

	const selectedMilongaItems = computed(() => {
		return selectedPotentionalItems.value.filter((item: any) => item.milonga);
	});

	const selectedWorkshopItems = computed(() => {
		return selectedPotentionalItems.value.filter((item: any) => item.workshop);
	});

	const reachedMilongaLimit = computed(() => {
		return selectedMilongaItems.value.length >= activeEventPack.value.milongaLimit;
	});

	const reachedWorkshopLimit = computed(() => {
		return selectedWorkshopItems.value.length >= activeEventPack.value.workshopLimit;
	});

	const extraAddedMilonga = computed(() => {
		return (reachedMilongaLimit.value && (selectedMilongaItems.value.length - activeEventPack.value.milongaLimit)) || 0;
	});

	const extraAddedWorkshop = computed(() => {
		return (reachedWorkshopLimit.value && (selectedWorkshopItems.value.length - activeEventPack.value.workshopLimit)) || 0;
	});

	const selectedExtraItems = computed(() => {
		return extraAddedMilonga.value + extraAddedWorkshop.value;
	});

	const reachedExtraActivitiesLimit = computed(() => {
		return selectedExtraItems.value >= activeEventPack.value.activityLimit;
	});

	const activeEventPackPotentials = computed(() => {
		return activeEventPack.value.potentials.reduce((acc: any, curr: any) => {
			const { startTimeSlot, endTimeSlot, startTime, endTime } = splitTime(curr.time)
			acc.push({
				...curr,
				startTime,
				endTime,
				weekDay: moment.unix(curr.date.seconds).utc().format('dddd'),
				startTimeSlot,
				endTimeSlot,
			})
			return acc
		}, [])
	})

	const isPotentialItemInOtherEventPack = computed(() => {
		return (potentialItem: any, eventPackId: any) => {
			let cartItem = cartStore.isPotentialItemInCart(potentialItem.activityId)
			if (cartItem && cartItem.id !== eventPackId) {
				return cartItem
			} else {
				return null
			}
		}
	})

	const isSelectedInActiveEventPack = computed(() => {
		return (potentialItem: any) => {
			let cartItem = cartStore.cartItemsById[activeEventPack.value.id]
			return cartItem && cartItem.selected_potentional_items.findIndex(
				(obj: any) => obj.activityId === potentialItem.activityId
			) !== -1
		}
	})

	onMounted(() => {
		window.scrollTo(0,0);
	})

	const toggleEventItemImageDialog = (img: any) => {
		imageDialog.value?.open('image-dialog');
		ModalImage.value = img;
	};

	const openShowMoreModal = (eventpackId: string) => {
		showMoreDialogRef.value[eventpackId].open()
	}

	const prev = () => {
		eventBookingStore.goToPrevBookingStep();
	};

	const add_to_cart = (selectedEvent: any) => {
		if (selectedEvent?.potentials?.length) {
			openPotentialItemModal(selectedEvent)
		} else {
			cartStore.addToCart(selectedEvent);
		}
	};

	const openPotentialItemModal = (selectedEvent: any) => {
		activeEventPack.value = selectedEvent;
		potentialsDialog.value.open()
	};

	const splitTimeHoursMinutes = (time: string) => {
		return {
			hour: time.split(':')[0],
			minutes: time.split(':')[1],
		}
	}

	const splitTime = (time: string) => {
		const times = time.split(' - ')
		const startTime = splitTimeHoursMinutes(times[0])
		const endTime = splitTimeHoursMinutes(times[1])

		return {
			startTimeSlot:  Math.floor(Number.parseInt(startTime.hour) * 60/5 + Number.parseInt(startTime.minutes) / 5),
			endTimeSlot: Math.floor(Number.parseInt(endTime.hour) * 60/5 + Number.parseInt(endTime.minutes) / 5),
			startTime,
			endTime,
		}
	}

	const overlapsTime = (potentialItemBeingAdded: any) => {
		const { startTimeSlot, endTimeSlot, weekDay } = potentialItemBeingAdded
		let endTimeSlotAux = endTimeSlot
		let timeSlotUsed = false
		if (endTimeSlot < startTimeSlot) { // normalize to avoid times that go to the next day and break overlap validation
			endTimeSlotAux = Math.floor(23 * 60/5 + 59 / 5)
		}
		for (let i = startTimeSlot; i < endTimeSlotAux; i++) {
			if (timeSlotsBooked.value[weekDay][i]) {
				timeSlotUsed = true
				break
			}
		}
		if (!timeSlotUsed) {
			return false
		} else {
			return true
		}
	}

	const togglePotentialToCart = (potentionalItem: any) => {
		if (potentionalItem.disabled || isPotentialItemInOtherEventPack.value(potentionalItem, activeEventPack.value.id)) {
			return
		}

		// check if potential item is in cart to decide if it add or remove
		if (cartStore.isPotentialItemInCart(potentionalItem.activityId)) {
			// remove item from cart
			cartStore.removePotentialItemFromCart(potentionalItem.activityId, activeEventPack.value)
		} else if (overlapsTime(potentionalItem)) {
			useToast().error(t('TimeOverlap'));
		} else {
			// add item to cart
			addPotentialItemToCart(potentionalItem)
		}
		cartStore.saveCartInLocalStorage()
	}

	function addPotentialItemToCart(potentialItemBeingAdded: any) {
		const Toast = useToast();
		let cartItem = cartStore.cartItemsById[activeEventPack.value.id]
		if (!cartItem) {
			cartStore.addToCart(activeEventPack.value);
		}
		cartItem = cartStore.cartItemsById[activeEventPack.value.id]
		let selectedPotentionalItems = cartItem["selected_potentional_items"];
		if (potentialItemBeingAdded.milonga) {
			if (reachedMilongaLimit.value && reachedExtraActivitiesLimit) {
				Toast.error(t("toast_translation.max_activities"));
			} else {
				selectedPotentionalItems.push(potentialItemBeingAdded);
			}
		} else {
			if (reachedWorkshopLimit.value && reachedExtraActivitiesLimit) {
				Toast.error(t("toast_translation.max_activities"));
			} else {
				selectedPotentionalItems.push(potentialItemBeingAdded);
			}
		}
	}

	const next = async () => {
		const Toast = useToast();

		if (cartStore.getInvalidItemsWithPotentials.length) {
			Toast.error('Fill required data.');
			return;
		}
		try {
			if (eventStore.event?.process !== 'BOOKING' || eventBookingStore.sessionId) {
				await eventBookingStore.updateSession();
			}
			if (eventStore.event?.process === 'BOOKING' && eventBookingStore.sessionId) {
				await eventBookingStore.updateSession();
			}
			eventBookingStore.goToNextBookingStep();
		} catch (error: any) {
			if (error.code === 601) {
				Toast.error(t("toast_translation.itemsoldOut"));
			} else {
				Toast.error(error.message);
			}
		}
	};

const __returned__ = { eventBookingStore, userStore, eventStore, cartStore, t, activeEventPack, ModalImage, imageDialog, potentialsDialog, showMoreDialogRef, timeSlotsBooked, bodyTextColorStyle, borderStyle, selectedActivityStyle, activityStyle, eventButtonStyle, selectedPotentionalItems, selectedMilongaItems, selectedWorkshopItems, reachedMilongaLimit, reachedWorkshopLimit, extraAddedMilonga, extraAddedWorkshop, selectedExtraItems, reachedExtraActivitiesLimit, activeEventPackPotentials, isPotentialItemInOtherEventPack, isSelectedInActiveEventPack, toggleEventItemImageDialog, openShowMoreModal, prev, add_to_cart, openPotentialItemModal, splitTimeHoursMinutes, splitTime, overlapsTime, togglePotentialToCart, addPotentialItemToCart, next, BookingNavbar, DialogComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})