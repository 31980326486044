import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'
import { useEventStore } from './store/event-store';
import { useRouter } from 'vue-router';
import Navbar from '@/components/LandingPage/Navbar.vue';
import Footer from '@/components/LandingPage/Footer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const eventStore = useEventStore();
const router = useRouter();

const isPaymentLinkPage = computed(() => router.currentRoute.value.name === 'payment-link-sumup');
const isEmbeddedEventPage = computed(() => router.currentRoute.value.name?.toString()?.toLowerCase().startsWith('embedded'));
const isReviewEventPage = computed(() => router.currentRoute.value.name === 'reviewEvent');
const showHeaderAndFooter = computed(() => {
  return !(isPaymentLinkPage.value || isReviewEventPage.value || isEmbeddedEventPage.value) && !eventStore.isEmbeddedEventMode;
});

const __returned__ = { eventStore, router, isPaymentLinkPage, isEmbeddedEventPage, isReviewEventPage, showHeaderAndFooter, Navbar, Footer }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})