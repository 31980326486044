import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "google-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleLogin = _resolveComponent("GoogleLogin")!

  return (_openBlock(), _createElementBlock("div", {
    class: "login-form-container",
    style: _normalizeStyle({
        'color': $setup.defaultColors.bodyTXT,
        'background-color': $setup.defaultColors.background,
    })
  }, [
    _createVNode($setup["HorizontalLine"], {
      text: $setup.t('login_with_pots_account'),
      style: _normalizeStyle({
      '--primary-color': $setup.defaultColors.bodyTXT,
      paddingTop: '0',
    })
    }, null, 8 /* PROPS */, ["text", "style"]),
    _createVNode($setup["FormComponent"], {
      ref: "formRef",
      name: "LoginForm"
    }, {
      default: _withCtx(() => [
        _createVNode($setup["FormItemInput"], {
          modelValue: $setup.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
          email: "",
          placeholder: $setup.t('registration_page.email'),
          style: _normalizeStyle({
                '--primary-color': $setup.defaultColors.bodyTXT,
                '--secondary-color': $setup.defaultColors.background,
            })
        }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
        _createElementVNode("div", null, [
          _createVNode($setup["PasswordInput"], {
            modelValue: $setup.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.password) = $event)),
            "no-validation": "",
            placeholder: $setup.t('registration_page.password'),
            style: _normalizeStyle({
                  '--primary-color': $setup.defaultColors.bodyTXT,
                  '--secondary-color': $setup.defaultColors.background,
              })
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
          _createElementVNode("span", {
            style: _normalizeStyle({'color': $setup.defaultColors?.bodyTXT, textAlign: 'right', display: 'block', marginTop: '.5rem'}),
            class: "forget-password-label",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.toggleForgotPassword()))
          }, _toDisplayString($setup.t('buyer_details.forget_pwd')), 5 /* TEXT, STYLE */)
        ]),
        _createElementVNode("button", {
          class: "primary-button",
          style: _normalizeStyle($setup.primaryButtonStyle),
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.signInToTango()))
        }, _toDisplayString($setup.t('button.sign_to_point_of_tango')), 5 /* TEXT, STYLE */)
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */),
    _createVNode($setup["HorizontalLine"], {
      text: $setup.t('or'),
      style: _normalizeStyle({
      '--primary-color': $setup.defaultColors.bodyTXT,
    })
    }, null, 8 /* PROPS */, ["text", "style"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_GoogleLogin, {
        "logout-button": true,
        callback: $setup.googleLoginCallback
      })
    ]),
    _createVNode($setup["HorizontalLine"], {
      text: $setup.t('buyer_details.dont_account'),
      style: _normalizeStyle({
      '--primary-color': $setup.defaultColors.bodyTXT,
    })
    }, null, 8 /* PROPS */, ["text", "style"]),
    _createElementVNode("button", {
      class: "secondary-button",
      style: _normalizeStyle($setup.secondaryButtonStyle),
      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.openSignUpForm()))
    }, _toDisplayString($setup.t('sign_up')), 5 /* TEXT, STYLE */),
    _createVNode($setup["ResetPasswordDialog"], {
      ref: "resetPasswordDialogRef",
      email: $setup.email
    }, null, 8 /* PROPS */, ["email"])
  ], 4 /* STYLE */))
}