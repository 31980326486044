import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "carousel-caption-container" }
const _hoisted_2 = { class: "carousel-caption-title font-lora" }
const _hoisted_3 = { class: "carousel-item-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode($setup["Carousel"], {
      autoplay: $setup.events.length == 1 ? 0 : 5000,
      "items-to-show": 1,
      "pause-autoplay-on-hover": "",
      "wrap-around": true,
      class: "carousel-item-height"
    }, {
      addons: _withCtx(() => [
        ($setup.events.length > 1)
          ? (_openBlock(), _createBlock($setup["Navigation"], { key: 0 }))
          : _createCommentVNode("v-if", true)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.events, (event) => {
          return (_openBlock(), _createBlock($setup["Slide"], {
            key: event.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { path: '/event/' + $setup.getEventUrlId(event) } ,
                target: "_blank",
                style: {"width":"100%"},
                class: "carousel-item-height"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["CoverImage"], {
                    image: event.bannerUrl,
                    title: 'Event image'
                  }, null, 8 /* PROPS */, ["image"]),
                  _createVNode(_Transition, { persisted: "" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(event.name), 1 /* TEXT */)
                        ])
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, $setup.mounted]
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["autoplay"]), [
      [_vShow, !$setup.loading && $setup.events?.length]
    ]),
    _withDirectives(_createVNode($setup["CoverImage"], {
      image: require('@/assets/carousel1.jpg'),
      title: 'Event image',
      class: "carousel-item-height"
    }, null, 8 /* PROPS */, ["image"]), [
      [_vShow, !$setup.loading && $setup.events?.length === 0]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512 /* NEED_PATCH */), [
      [_vShow, $setup.loading]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}