import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref, onMounted } from 'vue';
import SignUpLoginForm from '@/components/SignUpLoginForm.vue';
import ShoppingCart from '@/components/event/ShoppingCart.vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/store/user-store'
import { useMainStore } from '@/store/main-store'
import { useCartStore } from '@/store/cart-store';
import { useEventStore } from '@/store/event-store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'
import DialogComponent from '@/design-system/DialogComponent.vue';
import colours from '@/json/defaultColor.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'Navbar',
  setup(__props, { expose: __expose }) {
  __expose();

const { t, locale: i18nLocale  } = useI18n();
const eventStore = useEventStore()
const userStore = useUserStore()
const cartStore = useCartStore()
const mainStore = useMainStore()
const router = useRouter()

const languageOptionsOpen = ref(false)
const profileMenuOpen = ref(false)
const showSignUpInModal = ref(false)
const loginModalDialog: Ref<typeof DialogComponent | null>  = ref(null)
const cartDialog: Ref<typeof DialogComponent | null>  = ref(null)
const joinCommunityDialog: Ref<typeof DialogComponent | null>  = ref(null)

const activeClassObjectLandingPage = computed(() => ({
  'background-color': '#47B4AA',
}));

const isEventPageOpened = computed(() => router.currentRoute.value.matched[0]?.name === 'EventIndex');

const toggleLanguageDropdown = () => {
  languageOptionsOpen.value = !languageOptionsOpen.value;
  profileMenuOpen.value = false
};

const toogleProfileMenu = () => {
  profileMenuOpen.value = !profileMenuOpen.value;
  languageOptionsOpen.value = false
};

const toggleCreateEventDropdown = () => {
  languageOptionsOpen.value = false
  profileMenuOpen.value = false
  joinCommunityDialog.value?.open()
}

const openSignUp = () => {
  showSignUpInModal.value = true;
  loginModalDialog.value?.open()
};

const openSignIn = () => {
  showSignUpInModal.value = false;
  loginModalDialog.value?.open()
};

const modalToggleClose = () => {
  showSignUpInModal.value = false;
  loginModalDialog.value?.close()
};

const logout = async () => {
  const Toast = useToast();
  toogleProfileMenu()
  userStore.logout();
  Toast.success('Successfully logged out'); // Adjust toast message as necessary

  loginModalDialog.value?.close()

  if (isEventPageOpened.value) {
    await router.push({ path: `/event/${eventStore.eventUrlId}` });
    await eventStore.reloadEvent();
  }
};


const __returned__ = { t, i18nLocale, eventStore, userStore, cartStore, mainStore, router, languageOptionsOpen, profileMenuOpen, showSignUpInModal, loginModalDialog, cartDialog, joinCommunityDialog, activeClassObjectLandingPage, isEventPageOpened, toggleLanguageDropdown, toogleProfileMenu, toggleCreateEventDropdown, openSignUp, openSignIn, modalToggleClose, logout, SignUpLoginForm, ShoppingCart, DialogComponent, get colours() { return colours } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})