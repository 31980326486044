import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (['OPTION'].includes($props.type))
    ? (_openBlock(), _createBlock($setup["FormItemRadioButton"], {
        key: 0,
        modelValue: $setup.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
        required: $props.required,
        options: $setup.options,
        placeholder: $props.label,
        disabled: $props.disabled,
        style: _normalizeStyle({
            '--primary-color': $props.inputStyle.primaryColor,
            '--secondary-color': $props.inputStyle.secondaryColor,
        }),
        onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($setup.value)))
      }, null, 8 /* PROPS */, ["modelValue", "required", "options", "placeholder", "disabled", "style"]))
    : ($props.type == 'LONG_TEXT')
      ? (_openBlock(), _createBlock($setup["FormItemTextarea"], {
          key: 1,
          modelValue: $setup.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.value) = $event)),
          required: $props.required,
          placeholder: $props.label,
          disabled: $props.disabled,
          style: _normalizeStyle({
            '--primary-color': $props.inputStyle.primaryColor,
            '--secondary-color': $props.inputStyle.secondaryColor,
        }),
          onChange: _cache[3] || (_cache[3] = ($event: any) => ($setup.onChange($setup.value)))
        }, null, 8 /* PROPS */, ["modelValue", "required", "placeholder", "disabled", "style"]))
      : ($props.type == 'DATE')
        ? (_openBlock(), _createBlock($setup["FormItemDatePicker"], {
            key: 2,
            modelValue: $setup.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.value) = $event)),
            required: $props.required,
            placeholder: $props.label,
            disabled: $props.disabled,
            style: _normalizeStyle({
            '--primary-color': $props.inputStyle.primaryColor,
            '--secondary-color': $props.inputStyle.secondaryColor,
        }),
            onChange: _cache[5] || (_cache[5] = ($event: any) => ($setup.onChangeDatePicker($setup.value)))
          }, null, 8 /* PROPS */, ["modelValue", "required", "placeholder", "disabled", "style"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createCommentVNode(" types == ['SHORT_TEXT', 'CITY', 'ADDRESS', 'POSTCODE']\" "),
            _createVNode($setup["FormItemInput"], {
              modelValue: $setup.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.value) = $event)),
              required: $props.required,
              placeholder: $props.label,
              disabled: $props.disabled,
              style: _normalizeStyle({
            '--primary-color': $props.inputStyle.primaryColor,
            '--secondary-color': $props.inputStyle.secondaryColor,
        }),
              phone: $props.type == 'PHONE',
              pattern:  $props.type == 'PHONE' ? $setup.PHONE_REGEX : undefined,
              onChange: _cache[7] || (_cache[7] = ($event: any) => ($setup.onChange($setup.value)))
            }, null, 8 /* PROPS */, ["modelValue", "required", "placeholder", "disabled", "style", "phone", "pattern"])
          ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}