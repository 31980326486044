import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, onUnmounted, reactive, ref, toRef, watch } from 'vue'
    import { LANDING_CONTAINER_WIDTH } from './values'
    import { EventsData } from '@/model/EventsData'
    import EventDateTime from '@/components/EventDateTime.vue';
    import CardImage from '../CardImage.vue';

    interface Markers {
        position: {
            lat: number
            lng: number
        }
        name: string
        id: string
        from: number
        to: number
        region: string
        country: string
        coverUrl: string
    }

    interface MapCoordinate {
        lat: number
        lng: number
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleMap',
  props: {
    mapEvents: { type: [Array, null], required: true },
    singleSelectedEvent: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

    const prop = __props
    const events = toRef(prop, 'mapEvents')
    const singleSelectedEvent = toRef(prop, 'singleSelectedEvent')
    let markers = ref < Markers[] > ([])
    const openedMarkerID = ref < string | null > (null)
    let center = reactive < MapCoordinate > ({ lat: 51.093048, lng: 6.84212 })
    //let zoom = reactive < Number | null >(null);
    let zoom = ref < number | null >(null);

    function bindMapData() {
        if (events.value !== null) {
            markers.value = []
            events.value?.forEach((item: EventsData) => {
                markers.value.push({
                    position: {
                        lat: item?.location?.latitude ?? 0,
                        lng: item?.location?.longitude ?? 0
                    },
                    name: item?.name,
                    id: item?.id,
                    from: item?.from?.seconds,
                    to: item?.to?.seconds,
                    region: item?.region,
                    country:item?.country,
                    coverUrl: item?.coverUrl
                })
            })
            if (markers.value.length > 1) {
                center = { lat: markers.value[0].position.lat, lng: markers.value[0].position.lng }
            }
        } 
    }

    const scrollTarget = ref(null);

    function openMarker(location: any,id: string | null) {
        openedMarkerID.value = id
        if(openedMarkerID.value != null) {
            zoom.value = 12; 
        } else {
            zoom.value = 5;
        }
    }

    const map_width = ref('')

    function resizeHandler() {
        const width = document.body.clientWidth
        if (width < parseInt(LANDING_CONTAINER_WIDTH.replace('px', ''), 10)) {
            map_width.value = `${width - 30}px`
        } else {
            map_width.value = LANDING_CONTAINER_WIDTH
        }
    }

    const getEventUrlId = (event: any) => {
        return event.region ? `${event.country}-${event.id}` : event.id
    }

    watch(events, () => {
        bindMapData()
    })

    watch(singleSelectedEvent, (item: any) => {
        if (item) {
            center = { lat: item.location.latitude, lng: item.location.longitude }
            openedMarkerID.value = item.id;
            openMarker('watch', item.id);
        }
    })

    onMounted(() => {
        // zoom = 7;
        openMarker(null,null);
        window.addEventListener('resize', resizeHandler)
        resizeHandler()
        bindMapData()
    })

    onUnmounted(() => {
        window.removeEventListener('resize', resizeHandler)
    })

const __returned__ = { prop, events, singleSelectedEvent, get markers() { return markers }, set markers(v) { markers = v }, openedMarkerID, get center() { return center }, set center(v) { center = v }, get zoom() { return zoom }, set zoom(v) { zoom = v }, bindMapData, scrollTarget, openMarker, map_width, resizeHandler, getEventUrlId, EventDateTime, CardImage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})