import { defineStore } from 'pinia'
import { ref, computed, Ref } from 'vue'
import axios from 'axios'
import { useEventBookingStore } from './booking-store'
import { useEventStore } from './event-store'

export const useUserStore = defineStore('user', () => {
  const user: Ref<any> = ref(null)
  const signInMethod: Ref<string> = ref('')
  const incompleteSignUp = ref<boolean>(false);
  const googleId = ref<string | null>(null);
  const googleCredential = ref<string | null>(null);

  const isLoggedIn = computed(() => !!user.value)

  const isGuestUser = computed(() => user.value?.isGuest)

  init()

  function init() {
    user.value = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null
  }

  function setUserId(userId: string) {
    user.value.id = userId
  }


  function setUser(userData: any) {
    user.value = userData
    user.value.id = userData.id || userData.localId
    localStorage.setItem('user', JSON.stringify(user.value))
  }

  function setGuestUser(userData: any) {
    userData.isGuest = true
    setUser(userData)
  }

  async function login(userData: any) {
    const payload = {
      ...userData,
      'timeZoneId': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'language': process.env.VUE_APP_LOCALE_LANG
    }
    try {
      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}users/login`, payload)
      signInMethod.value = 'PASSWORD';
      const user = response.data.results
      if (user.registered) {
        setUser(user);
      } else {
        setGuestUser(user)
      }
      const eventStore = useEventStore()
      if (eventStore.event) {
        await eventStore.reloadEvent();
      }
      if (eventStore.event?.process != 'BOOKING' ) {
        const eventBookingStore = useEventBookingStore()
        eventBookingStore.reset()
      }
      return user
    } catch (error: any) {
      console.error('Error in login: ', error)
      throw new Error('Bad Request')
    }
  }

  async function googleLogin(googleUser: any) {
    const userData = {
      'email': null,
      'password': null,
      'deviceId': "",
      'userId': "",
      'signInMethod': 'GOOGLE',
      'token': null,
      'idToken': googleUser.credential,
      'secret': null,
      'accessToken': null,
      'providerId': 'google.com',
      'nonce': null,
    }
    const user = await login(userData);
    console.log('Successful google login. User: ', user );
    signInMethod.value = 'GOOGLE';
    if (!user.registered) {
      incompleteSignUp.value = true
      googleId.value = user.localId;
      googleCredential.value = googleUser.credential;
    } else {
      incompleteSignUp.value = false
    }
    return user
  }

  async function facebookLogin(facebookUser: any) {
    const user_data = {
      'email': null,
      'password': null,
      'deviceId': "",
      'userId': "",
      'signInMethod': 'FACEBOOK',
      'token': null,
      'idToken': null,
      'secret': null,
      'accessToken': facebookUser.authResponse,
      'providerId': 'facebook.com',
      'nonce': null,
    }
    const user = await login(user_data);
    console.log('Successful facebook login. User: ', user );
    signInMethod.value = 'FACEBOOK';
    // TODO check if uesr is registered
    return user
  }

  function logout() {
    user.value = null
    localStorage.removeItem('user')
    const eventBookingStore = useEventBookingStore()
    eventBookingStore.reset()
  }

  async function register(registerData: any) {
    const params = registerData.signInMethod == 'GOOGLE' ? {signUpType: 'GOOGLE' } : {}
    try {
        const response = await axios.post(
            `${process.env.VUE_APP_BASE_URL}users`,
            registerData,
            { params }
        )
        return response.data.results
    } catch (error: any) {
        console.error("Register user error: ", error);
        throw error
    }
  }

  return {
    user, incompleteSignUp, 
    isLoggedIn, isGuestUser,
    login, googleLogin, facebookLogin, register, logout, setUserId, setGuestUser, setUser }
})