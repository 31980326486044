import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-card" }
const _hoisted_2 = { class: "user-photo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "profile-background",
      style: _normalizeStyle({ backgroundImage: `url('${$setup.profile.coverUrl || $setup.profile.logoUrl}')` })
    }, null, 4 /* STYLE */),
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode(" User Photo (displayed in a circle) "),
      _createElementVNode("img", {
        src: $setup.profile.logoUrl,
        alt: "User Photo",
        class: "img-fluid rounded-circle"
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createCommentVNode(" User Details "),
      _createElementVNode("h3", null, _toDisplayString($setup.profile.name), 1 /* TEXT */),
      _createElementVNode("p", null, _toDisplayString($setup.profile.description), 1 /* TEXT */),
      _createElementVNode("a", {
        class: "btn btn-primary",
        onClick: $setup.follow
      }, _toDisplayString($setup.loadingIsFollow ? '... ' : ($setup.isFollowed ? 'Following' : 'Follow' )), 1 /* TEXT */)
    ])
  ]))
}