import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logos/mercado.png'
import _imports_1 from '@/assets/logos/wu.png'
import _imports_2 from '@/assets/logos/link_payment.png'
import _imports_3 from '@/assets/logos/arbitrum-logo.png'
import _imports_4 from '@/assets/logos/BSC-logo.png'


const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "product-cart-details-box" }
const _hoisted_3 = { class: "check-out-product-title" }
const _hoisted_4 = { class: "cart-container" }
const _hoisted_5 = { class: "currency-symbol-margin" }
const _hoisted_6 = { class: "currency-symbol-margin" }
const _hoisted_7 = ["placeholder", "readonly"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "error-message-container"
}
const _hoisted_10 = { class: "error-message" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "sub-total discount"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "currency-symbol-margin" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "currency-symbol-margin" }
const _hoisted_17 = {
  key: 1,
  class: "sub-total discount"
}
const _hoisted_18 = { class: "currency-symbol-margin" }
const _hoisted_19 = { class: "sub-total" }
const _hoisted_20 = { class: "currency-symbol-margin" }
const _hoisted_21 = { class: "sub-total" }
const _hoisted_22 = { class: "currency-symbol-margin" }
const _hoisted_23 = { class: "accordion-item-row" }
const _hoisted_24 = { class: "accordion-item-header" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = {
  key: 2,
  src: _imports_0,
  height: "30"
}
const _hoisted_28 = {
  key: 3,
  src: _imports_1,
  height: "30"
}
const _hoisted_29 = {
  key: 4,
  src: _imports_2,
  height: "30"
}
const _hoisted_30 = {
  key: 5,
  src: _imports_3,
  height: "30"
}
const _hoisted_31 = {
  key: 6,
  src: _imports_4,
  height: "30"
}
const _hoisted_32 = {
  key: 7,
  class: "fa-regular fa-credit-card",
  style: {"font-size":"26px"}
}
const _hoisted_33 = {
  key: 8,
  class: "fa-solid fa-building-columns",
  style: {"font-size":"26px"}
}
const _hoisted_34 = {
  key: 9,
  class: "fa-brands fa-paypal",
  style: {"font-size":"26px"}
}
const _hoisted_35 = {
  key: 10,
  class: "fa-solid fa-money-bill-transfer",
  style: {"font-size":"26px"}
}
const _hoisted_36 = {
  key: 11,
  class: "fa-brands fa-stripe-s",
  style: {"font-size":"26px"}
}
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "payment-btn1" }
const _hoisted_39 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading || $setup.gatewaysLoading)
      ? (_openBlock(), _createBlock($setup["PotsLoader"], { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              style: _normalizeStyle($setup.paymentDetailsContainer)
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", {
                  style: _normalizeStyle($setup.cartTxtObj)
                }, _toDisplayString($setup.t('checkout.checkout_details')), 5 /* TEXT, STYLE */),
                (!$setup.eventBookingStore.defaultBookingItem)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "clear-cart-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.cartDialogRef?.open()))
                    }, _toDisplayString('See cart')))
                  : _createCommentVNode("v-if", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cartStore.getItems, (cartItem) => {
                  return (_openBlock(), _createBlock($setup["CartItem"], {
                    key: cartItem.id,
                    "cart-item": cartItem,
                    "color-style": {
                            primaryColor: $setup.eventStore.eventStyle.bodyTXT,
                            secondaryColor: $setup.eventStore.eventStyle.background,
                        },
                    onOnRemove: $setup.handleRemoveFromCartEvent
                  }, null, 8 /* PROPS */, ["cart-item", "color-style"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createElementVNode("div", {
                class: "check-out-total",
                style: _normalizeStyle($setup.cartDetailBottomborder)
              }, [
                _createElementVNode("div", {
                  class: "sub-total",
                  style: _normalizeStyle($setup.cartTxtObj)
                }, [
                  _createElementVNode("p", null, _toDisplayString($setup.t('checkout.sub_total')), 1 /* TEXT */),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                    _createElementVNode("span", null, _toDisplayString($setup.cartStore.getCartTotal), 1 /* TEXT */)
                  ])
                ], 4 /* STYLE */),
                _createElementVNode("div", {
                  class: "sub-total discount",
                  style: _normalizeStyle($setup.cartTxtObj)
                }, [
                  _createElementVNode("p", null, _toDisplayString($setup.t('checkout.discount')), 1 /* TEXT */),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                    _createTextVNode(_toDisplayString($setup.eventBookingStore.promoDiscount ? $setup.eventBookingStore.promoDiscount?.toFixed(2) : '0.00'), 1 /* TEXT */)
                  ])
                ], 4 /* STYLE */),
                _createElementVNode("div", null, [
                  _createElementVNode("div", {
                    class: "apply-code",
                    style: _normalizeStyle($setup.applycodeBorder)
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.eventBookingStore.promoCode) = $event)),
                      type: "text",
                      placeholder: $setup.t('checkout.promo_code'),
                      readonly: !!$setup.eventBookingStore.promoDiscount,
                      onInput: _cache[2] || (_cache[2] = ($event: any) => ($setup.invalidPromoCodeMessage = ''))
                    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_7), [
                      [_vModelText, $setup.eventBookingStore.promoCode]
                    ]),
                    (!$setup.eventBookingStore.promoDiscount)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          style: _normalizeStyle($setup.eventButtonStyle),
                          disabled: !$setup.eventBookingStore.promoCode,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.applyPromoCode()))
                        }, _toDisplayString($setup.t('checkout.apply')), 13 /* TEXT, STYLE, PROPS */, _hoisted_8))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          style: _normalizeStyle($setup.eventButtonStyle)
                        }, _cache[8] || (_cache[8] = [
                          _createElementVNode("i", {
                            class: "fa fa-check",
                            "aria-hidden": "true"
                          }, null, -1 /* HOISTED */)
                        ]), 4 /* STYLE */))
                  ], 4 /* STYLE */),
                  ($setup.invalidPromoCodeMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString($setup.invalidPromoCodeMessage), 1 /* TEXT */),
                        _createElementVNode("span", {
                          class: "close-error-message-button",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.invalidPromoCodeMessage = ''))
                        }, _cache[9] || (_cache[9] = [
                          _createElementVNode("i", {
                            class: "fa fa-times",
                            "aria-hidden": "true"
                          }, null, -1 /* HOISTED */)
                        ]))
                      ]))
                    : _createCommentVNode("v-if", true),
                  ($setup.eventBookingStore.promoDiscount)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "remove-promocode-button",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.clearPromocode()))
                        }, _toDisplayString($setup.t('checkout.removeCode')), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                ($setup.paymentMethodDeltaP || $setup.paymentMethodDeltaD)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("p", {
                        style: _normalizeStyle($setup.cartTxtObj)
                      }, "Fees ", 4 /* STYLE */),
                      _createElementVNode("p", {
                        style: _normalizeStyle($setup.cartTxtObj)
                      }, [
                        ($setup.paymentMethodDeltaP)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                              _createElementVNode("span", _hoisted_14, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                              _createTextVNode(" " + _toDisplayString($setup.paymentMethodDeltaP?.toFixed(2)), 1 /* TEXT */)
                            ]))
                          : _createCommentVNode("v-if", true),
                        ($setup.paymentMethodDeltaD)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                              _createElementVNode("span", _hoisted_16, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                              _createTextVNode(" " + _toDisplayString($setup.paymentMethodDeltaD?.toFixed(2)), 1 /* TEXT */)
                            ]))
                          : _createCommentVNode("v-if", true)
                      ], 4 /* STYLE */)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventBookingStore.isCryptoPayment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("p", {
                        style: _normalizeStyle($setup.cartTxtObj)
                      }, "Crypto rate", 4 /* STYLE */),
                      _createElementVNode("p", {
                        style: _normalizeStyle($setup.cartTxtObj)
                      }, [
                        _createElementVNode("span", null, [
                          _createElementVNode("span", _hoisted_18, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                          _createTextVNode(" " + _toDisplayString(($setup.eventBookingStore.getCryptoRate).toFixed(5)), 1 /* TEXT */)
                        ])
                      ], 4 /* STYLE */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 4 /* STYLE */),
              _createElementVNode("div", {
                class: "final-total",
                style: _normalizeStyle($setup.cartTxtObj)
              }, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", null, _toDisplayString($setup.t('checkout.total')), 1 /* TEXT */),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                    _createTextVNode(_toDisplayString(($setup.total_amt) ? $setup.total_amt?.toFixed(2) : '0.00'), 1 /* TEXT */)
                  ])
                ])
              ], 4 /* STYLE */),
              ($setup.cartStore.getCartTotal != $setup.cartStore.getCartPartialTotal)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "final-total",
                    style: _normalizeStyle($setup.cartTxtObj)
                  }, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("p", null, _toDisplayString($setup.t('checkout.partial_total')), 1 /* TEXT */),
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
                        _createTextVNode(_toDisplayString(($setup.partial_total_amt) ? $setup.partial_total_amt?.toFixed(2) : '0.00'), 1 /* TEXT */)
                      ])
                    ])
                  ], 4 /* STYLE */))
                : _createCommentVNode("v-if", true)
            ], 4 /* STYLE */),
            _createCommentVNode("end "),
            _createCommentVNode(" select any one payment method "),
            ($setup.total_amt != 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle($setup.paymentDetailsContainer),
                  class: "payment-method-container"
                }, [
                  _createElementVNode("h5", {
                    style: _normalizeStyle($setup.cartTxtObj)
                  }, _toDisplayString($setup.t('checkout.payment_method')), 5 /* TEXT, STYLE */),
                  _createVNode($setup["AccordionComponent"], {
                    class: "accordion-container",
                    radio: "",
                    "default-index": $setup.gateways.length == 1 ? 0 : NaN,
                    style: _normalizeStyle($setup.applycodeBorder)
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.gateways, (gateway) => {
                        return (_openBlock(), _createBlock($setup["AccordionItemComponent"], {
                          key: gateway.method,
                          value: gateway.id,
                          style: _normalizeStyle({ 'background': $setup.eventStore.eventStyle.bodyBG }),
                          onChange: $setup.handlePaymentMethodSelectionChange
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("span", _hoisted_23, [
                              _createElementVNode("span", _hoisted_24, [
                                (gateway.method !== 'CRYPTO')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString($setup.t('payment.'+`${gateway.method}`)), 1 /* TEXT */))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString($setup.t('payment.'+`${gateway.method}`) + ` (${gateway.cryptoDetails?.mainNet})`), 1 /* TEXT */)),
                                (gateway.method == 'MERCADO_PAGO')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_27))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'WU')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_28))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'LINK')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_29))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'CRYPTO' && gateway.cryptoDetails?.mainNet == 'ARBITRUM')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_30))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'CRYPTO' && gateway.cryptoDetails?.mainNet == 'BSC')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_31))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'SUM_UP')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'BANK')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_33))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'PAYPAL')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_34))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'DIRECT')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_35))
                                  : _createCommentVNode("v-if", true),
                                (gateway.method == 'STRIPE')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_36))
                                  : _createCommentVNode("v-if", true)
                              ])
                            ])
                          ]),
                          default: _withCtx(() => [
                            (($setup.componentMap as Map)[gateway.method])
                              ? (_openBlock(), _createBlock(_resolveDynamicComponent(($setup.componentMap as Map)[gateway.method]), _mergeProps({
                                  key: 0,
                                  ref_for: true
                                }, { 'paymentMethodData': gateway }), null, 16 /* FULL_PROPS */))
                              : (_openBlock(), _createElementBlock("div", _hoisted_37, " Missing implementation of payment method component "))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "style"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["default-index", "style"])
                ], 4 /* STYLE */))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", {
            class: "mt-3 footer-sticky",
            style: _normalizeStyle([{"width":"100%"}, {'--backgroundColor': $setup.eventStore.eventStyle.background}])
          }, [
            _createVNode($setup["BookingNavbar"], {
              "session-time": $setup.sessionTime,
              "next-navigation-hidden": true,
              onPrev: _cache[7] || (_cache[7] = ($event: any) => ($setup.prev()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("button", {
                    style: _normalizeStyle($setup.eventButtonStyle),
                    disabled: $setup.total_amt != 0 && !$setup.gatewaySelected || !$setup.cartStore.getItems.length,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.confirmBooking()))
                  }, [
                    _createElementVNode("span", null, _toDisplayString($setup.t('checkout.place_order')), 1 /* TEXT */)
                  ], 12 /* STYLE, PROPS */, _hoisted_39)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["session-time"])
          ], 4 /* STYLE */)
        ])),
    ($setup.sumUpGateway)
      ? (_openBlock(), _createBlock($setup["SumUpPaymentDialog"], {
          key: 2,
          ref: "sumUpPaymentDialog",
          "payment-method-data": $setup.sumUpGateway,
          onSuccess: $setup.next,
          onClose: $setup.resumeSessionTimer
        }, null, 8 /* PROPS */, ["payment-method-data", "onClose"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ConfirmDialog"], {
      ref: "confirmDialogRef",
      title: $setup.t('checkout.runningOutOfTime'),
      message: $setup.t('checkout.needMoretime', { sessionTime: $setup.sessionTime }),
      "dialog-color-style": {
            primaryColor: $setup.eventStore.eventStyle.bodyTXT,
            secondaryColor: $setup.eventStore.eventStyle.bodyBG,
        },
      "button-color-style": {
            primaryColor: $setup.eventStore.eventStyle.buttonTXT,
            secondaryColor: $setup.eventStore.eventStyle.buttonBG,
        },
      "confirm-button-label": $setup.t('button.yes'),
      "cancel-button-label": $setup.t('button.no'),
      onConfirm: $setup.addMoreTime,
      onCancel: $setup.cancelPayment
    }, null, 8 /* PROPS */, ["title", "message", "dialog-color-style", "button-color-style", "confirm-button-label", "cancel-button-label", "onConfirm"]),
    _createVNode($setup["DialogComponent"], {
      ref: "cartDialogRef",
      title: $setup.t('checkout.cart'),
      "hide-footer": true,
      "dialog-color-style": {
          primaryColor: $setup.eventStore.eventStyle.bodyTXT,
          secondaryColor: $setup.eventStore.eventStyle.bodyBG,
      },
      "button-color-style": {
          primaryColor: $setup.eventStore.eventStyle.buttonTXT,
          secondaryColor: $setup.eventStore.eventStyle.buttonBG,
      }
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ShoppingCart"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "dialog-color-style", "button-color-style"])
  ], 64 /* STABLE_FRAGMENT */))
}