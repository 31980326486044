import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, Ref } from 'vue';
import { useToast } from 'vue-toastification'
import FormItemInput from '@/design-system/FormItemInput.vue';
import DialogComponent from '@/design-system/DialogComponent.vue';
import FormComponent from '@/design-system/FormComponent.vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios'
import { useMainStore } from '@/store/main-store';
import colours from '@/json/defaultColor.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordDialog',
  props: {
    email: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {

const { t } = useI18n();
const mainStore = useMainStore()

const props = __props;

const logged_email = ref(props.email);
const resetPasswordFormRef: Ref<typeof FormComponent | null> = ref(null)
const resetPasswordDialogRef: Ref<typeof DialogComponent | null> = ref(null)
const defaultColors = computed(() => colours.colours)
const primaryButtonStyle = computed(() => ({
  color: defaultColors.value.buttonTXT,
  backgroundColor: defaultColors.value.buttonBG,
}))

// Function to reset password
async function resetPasswordMethod() {
  const Toast = useToast();
  const isValid = resetPasswordFormRef.value?.validate();
  if (isValid) {
    try {
      const email = logged_email.value
      const language = mainStore.languageSelected
      const response = await axios.get(`${process.env.VUE_APP_BASE_URL}users/resetPassword/${email}?language=${language}`)
      if (response.data.responseCode == 200) {
        Toast.success(t('toast_translation.emailSend'));
        resetPasswordDialogRef.value?.close()
      } else {
        Toast.error(t('toast_translation.error_mesg'));
      }
    } catch (error) {
      Toast.error(t('toast_translation.error_mesg'));
    }
  }
}

__expose({
  open: () => {
    resetPasswordDialogRef.value?.open()
  },
  close: () => {
    resetPasswordDialogRef.value?.close()
  }
})


const __returned__ = { t, mainStore, props, logged_email, resetPasswordFormRef, resetPasswordDialogRef, defaultColors, primaryButtonStyle, resetPasswordMethod, FormItemInput, DialogComponent, FormComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})