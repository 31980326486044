import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { ref: "card" }
const _hoisted_2 = { style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 1rem',
                } }
const _hoisted_3 = { class: "font-lora event-title" }
const _hoisted_4 = {
  key: 0,
  style: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row-reverse'
                }
}
const _hoisted_5 = { class: "carousel-container" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  style: {"padding":"0 1rem"}
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  style: {"min-height":"300px","border-top-right-radius":"9px","border-top-left-radius":"9px","overflow":"hidden","display":"flex","align-items":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "events",
        style: _normalizeStyle([{"padding-top":"1rem","margin-bottom":"1rem"}, {
                display: 'flex',
                flexDirection: 'column'
            }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */),
          _createElementVNode("div", {
            role: "button",
            class: "hide-on-single-card-mobile",
            style: {"text-decoration":"underline","font-weight":"600","font-size":"18px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.allEventsDialog?.open()))
          }, _toDisplayString($setup.t('seeAll')), 1 /* TEXT */)
        ]),
        ($setup.showBegginersFilter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode($setup["CheckboxComponent"], {
                modelValue: $setup.showBegginersOnly,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showBegginersOnly) = $event)),
                label: $setup.t('begginers_only')
              }, null, 8 /* PROPS */, ["modelValue", "label"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        ($setup.eventData.length)
          ? (_openBlock(), _createBlock($setup["Carousel"], {
              key: 0,
              modelValue: $setup.currentSlide,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.currentSlide) = $event)),
              "items-to-show": 1,
              "snap-align": 'start',
              "wrap-around": false,
              breakpoints: $setup.breakpoints
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventData, (item) => {
                  return (_openBlock(), _createBlock($setup["Slide"], {
                    key: item.id,
                    style: {"padding":"0 0 0 1rem","min-height":"300px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["EventCard"], {
                        image: item.coverUrl,
                        title: item.name,
                        date: item.from.seconds,
                        to_date: item.to.seconds,
                        location: $setup.useTrimText(item.address),
                        domain: item?.domain,
                        item_id: item.id,
                        country: item.country,
                        region: item?.region || '',
                        process: item.process,
                        event: item,
                        onGoToMap: $setup.handleGoToMapEvent
                      }, null, 8 /* PROPS */, ["image", "title", "date", "to_date", "location", "domain", "item_id", "country", "region", "process", "event"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "breakpoints"]))
          : _createCommentVNode("v-if", true),
        ($setup.eventData.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "navigation-button navigation-prev",
                onClick: $setup.prev
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "fa fa-angle-left" }, null, -1 /* HOISTED */)
              ])),
              _createElementVNode("button", {
                class: "navigation-button navigation-next",
                onClick: $setup.next
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fa fa-angle-right" }, null, -1 /* HOISTED */)
              ]))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      (!$setup.eventData.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.t('emptyData')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */),
    _createVNode($setup["DialogComponent"], {
      ref: "allEventsDialog",
      title: $props.title,
      width: '90vw'
    }, {
      default: _withCtx(() => [
        ($setup.eventData?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode($setup["CardContainer"], null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventData, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      style: {"width":"400px"}
                    }, [
                      _createVNode($setup["EventCard"], {
                        image: item.coverUrl,
                        title: item.name,
                        date: item.from.seconds,
                        to_date: item.to.seconds,
                        price: `Desde ${item.edition}`,
                        location: $setup.useTrimText(item.address),
                        item_id: item.id,
                        country: item.country,
                        domain: item?.domain,
                        region: item?.region || '',
                        process: item.process,
                        maplocation: item,
                        eventfavourite: item.favourite,
                        event: item
                      }, null, 8 /* PROPS */, ["image", "title", "date", "to_date", "price", "location", "item_id", "country", "domain", "region", "process", "maplocation", "eventfavourite", "event"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($setup.t('emptyData')), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title"])
  ], 64 /* STABLE_FRAGMENT */))
}