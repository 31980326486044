import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, onMounted, watch } from 'vue'


export type OptionProps = {
  id: string;
  label: string;
  icon?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownComponent',
  props: {
    modelValue: { type: null, required: true },
    options: { type: Array, required: true },
    placeholder: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    color: { type: String, required: false },
    backgroundColor: { type: String, required: false },
    selectedColor: { type: String, required: false },
    searchPlaceholder: { type: String, required: false },
    filterable: { type: Boolean, required: false },
    noBorders: { type: Boolean, required: false },
    padding: { type: Boolean, required: false }
  },
  emits: ["update:modelValue", "change", "focus", "blur"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

_useCssVars(_ctx => ({
  "60426ad2-color": (color.value),
  "60426ad2-backgroundColor": (backgroundColor.value)
}))

const props = __props;

// define emits
const emit = __emit;

const dropdownList = ref<HTMLDivElement | null>(null);
const input = ref<HTMLInputElement | null>(null);
const queryInput = ref<HTMLInputElement | null>(null);
const queryInputMobile = ref<HTMLInputElement | null>(null);
const isMobile = ref(false);
const isDropdownOpen = ref(false);
const isExpandUp = ref(false);
const query = ref('');
const currentSelectedIndex = ref(0);
const mouseDownOnItem = ref(false);
const filterInputFocused = ref(false)

// define proxyValue for modelValue to emit update:modelValue
const value: ComputedRef<T | null> = computed(() => props.modelValue);
const options: ComputedRef<T[]> = computed(() => props.options);
const placeholder: ComputedRef<string> = computed(() => props.placeholder || '');
const disabled: ComputedRef<boolean> = computed(() => props.disabled);
const color: ComputedRef<string> = computed(() => props.color || '#333');
const backgroundColor: ComputedRef<string> = computed(() => props.backgroundColor || '#fff');
const selectedColor: ComputedRef<string> = computed(() => props.selectedColor || '#f0f0f0');
const searchPlaceholder: ComputedRef<string> = computed(() => props.searchPlaceholder || '');
const isFilterable: ComputedRef<boolean> = computed(() => props.filterable);
const noBorders: ComputedRef<boolean> = computed(() => props.noBorders);
const padding: ComputedRef<boolean> = computed(() => props.padding);

const filteredOptions: ComputedRef<T[]> = computed(() => {
  return !query.value ? options.value : options.value.filter((value: OptionProps, index: number, array: OptionProps[]) => value.label.toLowerCase().includes(query.value.toLowerCase()))
});

onMounted(() => {
  checkExpandUp()
  console.log({ noBorders })
})

function toggleDropdown () {
    if (disabled.value) {
      return
    }
    if (document.body.clientWidth < 750) {
      isMobile.value = true
      isDropdownOpen.value = !isDropdownOpen.value;
      query.value = ''
      queryInputMobile.value?.focus()
    } else {
      isMobile.value = false
      isDropdownOpen.value = !isDropdownOpen.value;
      query.value = ''
      if (isDropdownOpen.value) {
        setTimeout(() => {
          checkExpandUp()
          queryInput.value?.focus()
        })
      }
    }
};

function selectOption (option: T | null) {
    console.log('Option selected: ', option?.id)
    currentSelectedIndex.value = 0
    emit("update:modelValue", option);
    emit("change", option);
    close();
};

function checkExpandUp () {
    const dropdownRect = dropdownList?.value?.getBoundingClientRect();
    isExpandUp.value = dropdownRect ? dropdownRect.bottom > window.innerHeight : false;
};

function close() {
  isDropdownOpen.value = false
  isExpandUp.value = false
  input.value?.focus()
};

function handleMouseDownOnItem () {
  mouseDownOnItem.value = true
}

function handleMouseUpOnItem () {
  mouseDownOnItem.value = false
}

function handleKeyDownOnFilter(event: KeyboardEvent) {
  console.log('Key up: ', event)
  if (disabled.value) {
    return
  }
  let key = event.key
  if (key == 'ArrowDown') {
    if (currentSelectedIndex.value < (filteredOptions.value.length - 1)) {
      currentSelectedIndex.value++
    }
    return
  }
  if (key == 'ArrowUp') {
    if (currentSelectedIndex.value > 0) {
      currentSelectedIndex.value--
    }
  }
  if (key == 'Enter') {
    const option = filteredOptions.value[currentSelectedIndex.value]
    if (option) {
      selectOption(option)
    }
  }
  if (key == 'Escape') {
    close()
  }
}

function handleKeyDownOnInput(event: KeyboardEvent) {
  console.log('Key up: ', event)
  if (disabled.value) {
    return
  }
  let key = event.key
  if (isExpandUp.value) {
    if (key == 'ArrowUp') {
      key = 'ArrowDown'
    } else if (event.key == 'ArrowDown') {
      key = 'ArrowUp'
    }
  }
  if (key == 'ArrowDown') {
    toggleDropdown()
  }
  if (key == 'ArrowUp') {
    close()
  }
  if (key == 'Escape') {
    close()
  }
  if (key == 'Backspace') {
    selectOption(null)
  }
}

function handleBlurOnFilter () {
  input.value?.focus()
  filterInputFocused.value = false
}

function handleFocusOnFilter() {
  filterInputFocused.value = true
}

function onFocus () {
  emit("focus");
}

function onBlur () {
  setTimeout(() =>  {
    if (!filterInputFocused.value) emit("blur")
  }, 0)
}

function focus() {
  input.value?.focus()
}

__expose({
  focus
})


const __returned__ = { props, emit, dropdownList, input, queryInput, queryInputMobile, isMobile, isDropdownOpen, isExpandUp, query, currentSelectedIndex, mouseDownOnItem, filterInputFocused, value, options, placeholder, disabled, color, backgroundColor, selectedColor, searchPlaceholder, isFilterable, noBorders, padding, filteredOptions, toggleDropdown, selectOption, checkExpandUp, close, handleMouseDownOnItem, handleMouseUpOnItem, handleKeyDownOnFilter, handleKeyDownOnInput, handleBlurOnFilter, handleFocusOnFilter, onFocus, onBlur, focus }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})