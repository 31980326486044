import countriesData from '@/json/countries.json';
import { type OptionProps } from '@/design-system/DropdownComponent.vue'

export function useCountriesOptions() {
  const countriesDropdownOptions = countriesData.countries.reduce((acc: OptionProps[], curr: any) => {
        acc.push({
            label: curr.name,
            icon: require(`@/assets/flags/${curr?.flag}.png`) ,
            id: curr.code
        })
        return acc
    }, [])

  function getCountryOptionById (id: string) {
    return countriesDropdownOptions.filter((item) => item.id == id)[0]
  }

  return { countriesDropdownOptions, getCountryOptionById };
}