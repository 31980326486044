import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, ref, onMounted } from 'vue'
import FormItemInput from '@/design-system/FormItemInput.vue';
import FormItemDatePicker from '@/design-system/FormItemDatePicker.vue';
import FormItemTextarea from '@/design-system/FormItemTextarea.vue';
import FormItemRadioButton, { OptionProps } from '@/design-system/FormItemRadioButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionFormItem',
  props: {
    modelValue: {
        type: String,
        default: ''
    },
    disabled: Boolean,
    required: Boolean,
    label: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: ''
    },
    options: {
        type: Array,
        default: null
    },
    inputStyle: {
        type: Object,
        default: () => {}
    },
},
  emits: ["update:modelValue", "change"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

// define emits
const emit = __emit;

const PHONE_REGEX = new RegExp(/^(?:\+?(\d{1,3}))?[-.●]?((\d{3})?[-.●]?(\d{3})[-.●]?(\d{4,6}))$/)

// define proxyValue for modelValue to emit update:modelValue
const modelValue: ComputedRef<string> = computed(() => {
  return props.modelValue;
});
const options: ComputedRef<OptionProps[]> = computed(() => {
  return props.options ? props.options.map((option: any) => {
    return {
        id: option,
        label: option,
    }
  }): []
});


function onChange(value: string) {
    emit("update:modelValue", value);
    emit("change", value);
}

function onChangeDatePicker(value: string) {
    
    function parseDate(dateString: string) {
        console.log('raw Date: ', dateString)
        if (!dateString) return ''
        const date = dateString.split('-')
        console.log('date: ', date)
        const day = date[2]
        const month = date[1]
        const year = date[0]

        return `${day}/${month}/${year}`;
    }
    const formattedDate = parseDate(value)
    console.log('Formatted Date: ', formattedDate)
    onChange(formattedDate)
}

const value = ref('')

onMounted(() => {
    value.value = modelValue.value
})

const __returned__ = { props, emit, PHONE_REGEX, modelValue, options, onChange, onChangeDatePicker, value, FormItemInput, FormItemDatePicker, FormItemTextarea, FormItemRadioButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})