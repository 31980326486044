import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Loading... "))
      : ($setup.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.error), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode($setup["ProfileCard"], {
              profile: ($setup.profile as ProfilesInterface)
            }, null, 8 /* PROPS */, ["profile"])
          ]))
  ]))
}