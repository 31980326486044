import { defineComponent as _defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';

export default /*@__PURE__*/_defineComponent({
  __name: 'StripeMethodSection',
  props: {
    paymentMethodData: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t } = useI18n();




const __returned__ = { t }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})