<script setup>
    import { computed, onMounted, ref } from 'vue';
    import moment from "moment";
    import { useI18n } from "vue-i18n";
    const { t } = useI18n();

    const props = defineProps({
        date: {
            type: Number,
            required: true,
        },
        endDate: {
            type: Number,
            required: false,
            default: () => null
        },
        
    });

    const date = computed(() => moment.unix(props.date).utc())
    const endDate = computed(() => props.endDate ? moment.unix(props.endDate).utc() : null)
    const endsSameDay = computed(() => {
      if (endDate.value) {
        const dayNumber = date.value.format('D')
        const month = t('month_short.' + date.value.format('MMM'))
        const year = date.value.format('YYYY')
        const endDayNumber = endDate.value.format('D')
        const endMonth = t('month_short.' + endDate.value.format('MMM'))
        const endYear = endDate.value.format('YYYY')
        return year == endYear && month == endMonth && dayNumber == endDayNumber
      } else {
        return false
      }
    })

    const oneLineDateTimeFormat = (date, endDate)=> {
      const dayOfweek = t('week_day_short.' + date.format('ddd'))
      const dayNumber = date.format('D')
      const month = t('month_short.' + date.format('MMM'))
      const currentYear = new Date().getFullYear().toString()
      const formattedYear = date.format('YYYY')
      const year = (currentYear === formattedYear) ? '' : ` ${formattedYear}`
      const hours = date.format('HH:mm')

      const time = hours + (endDate ? ` - ${endDate.format('HH:mm')}` : '')
      return `${dayOfweek} ${dayNumber} ${month}${year}, ${time}`
    }

</script>

<template>
  <div class="date-container">
    <div v-if="!endDate">
      <i class="fa-solid fa-calendar-days calendar-icon"></i>
      <span>{{ oneLineDateTimeFormat(date) }}</span>
      <div class="empty-line">
        &nbsp;
      </div>
    </div>
    <div v-else-if="endsSameDay">
      <i class="fa-solid fa-calendar-days calendar-icon"></i>
      <span>{{ oneLineDateTimeFormat(date, endDate) }}</span>
      <div class="empty-line">
        &nbsp;
      </div>
    </div>
    <div v-else>
      <div>
        <i class="fa-solid fa-calendar-days calendar-icon"></i>
        <span>{{ oneLineDateTimeFormat(date) }}</span>
      </div>
      <div>
        <i class="fa-solid fa-calendar-days calendar-icon red-icon"></i>
        <span>{{ oneLineDateTimeFormat(endDate) }}</span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.date-container {
  display: flex;
  margin-bottom: 1rem;
  text-align: left;
}

.calendar-icon {
  margin-right: .5rem;
  font-size: 18px;
}

.red-icon {
  color: #ff4949;
}

.empty-line {
  height: 24px;
}
</style>