import { defineComponent as _defineComponent } from 'vue'
import router from './../../../router'
    import { ref, onMounted, watchEffect, computed, ComputedRef, Ref } from 'vue';
    import { useToast } from 'vue-toastification'
    import { useI18n } from 'vue-i18n'
    import { useUserStore } from '@/store/user-store'
    import CardImage from '../../CardImage.vue'
    import EventDateTime from '@/components/EventDateTime.vue';
    import { useCartStore } from '@/store/cart-store';
    import { useEventBookingStore } from '@/store/booking-store';
    import ConfirmDialog from '@/design-system/ConfirmDialog.vue';
    import axios from 'axios'
    import { useMainStore } from '@/store/main-store'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'EventCard',
  props: {
        item_id: {
            type: [Number, String],
            required: true
        },
        title: {
            type: String,
            required: true
        },
        domain: {
            type: String,
            required: false
        },
        country: {
            type: String,
            required: true
        },
        region: {
            type: String,
            required: true
        },
        date: {
            type: Number,
            required: true
        },
        to_date: {
            type: Number,
            required: false
        },
        location: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: false
        },
        process: {
            type: String,
            required: true
        },
        event: {
            type: Object,
            required: true
        }
    },
  emits: ["goToMap"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

    const userStore = useUserStore()
    const cartStore = useCartStore()
    const mainStore = useMainStore()
    const Toast = useToast()
    const { t } = useI18n()

    let redirectObj = {
        clicked_item_id: null,
        country: null,
        region: null,
        domain: null,
    }

    const props = __props;

    // define emits
    const emit = __emit;

    const mounted = ref(false)
    const processingToggleFavorite = ref(false)
    const confirmClearCartDialogRef: Ref<typeof ConfirmDialog | null>  = ref(null)

    const unmarkedFavImage = require('@/assets/logos/black_fav.png');
    const markedFavImage = require('@/assets/logos/green_fav-remove.png');

    const startDateTime: ComputedRef<number> = computed(() => props.event?.from?.seconds || 0);
    const bookingsStartDateTime: ComputedRef<number> = computed(() => props.event?.bookings?.seconds || 0);
    const bookingsOpenDateTime: ComputedRef<number> = computed(() => props.event?.open?.seconds || 0);
    const bookingsEndDateTime: ComputedRef<number> = computed(() => props.event?.close?.seconds || Infinity);
    const eventEndsDateTime: ComputedRef<number> = computed(() => props.event?.to?.seconds || Infinity);
    const event: ComputedRef<any> = computed(() => props.event);

    const isBookSoon: ComputedRef<boolean> = computed(() => {
        const today = Math.floor(Date.now() / 1000)
        return today < bookingsStartDateTime.value || today < bookingsOpenDateTime.value
    })

    const isBookNow: ComputedRef<boolean> = computed(() => {
        const today = Math.floor(Date.now() / 1000)
        return bookingsStartDateTime.value < today && bookingsOpenDateTime.value < today && today < bookingsEndDateTime.value
    })

    const isTicketsAvailableAtTheDoor: ComputedRef<boolean> = computed(() => {
        const today = Math.floor(Date.now() / 1000)
        return bookingsEndDateTime.value < today && today < eventEndsDateTime.value
    })


    const bookButonLabel: ComputedRef<String> = computed(() => {
        if (!startDateTime.value || !bookingsEndDateTime.value) {
            return t('book_now').toUpperCase()
        } else {
            if (isBookSoon.value) {
                return t('bookSoon')
            } else {
                if (isBookNow.value || isTicketsAvailableAtTheDoor.value) {
                    return t('book_now').toUpperCase()
                } else {
                    return ''
                }
            }
        }
    });

    const isBookingButtonDisplayed: ComputedRef<Boolean> = computed(() => {
        const today = Math.floor(Date.now() / 1000)
        return today < eventEndsDateTime.value
    });

    const clicked = async (data: any) => {
        console.log('Clicked event')
        const { item_id, country, region, domain } = data;
        redirectObj = {
            clicked_item_id: item_id, 
            country, 
            region,
            domain
        }
        const eventUrlId = region ? `${country}-${item_id}` : item_id
        if (!cartStore.isEmpty && (localStorage.getItem('eventId') == item_id)) {
            router.push({ path: `/event/${eventUrlId}` });
        } else {
            if (!cartStore.isEmpty) {
                confirmClearCartDialogRef.value?.open('confirmClearCart')
            } else {
                const newTabUrl = router.resolve({ path: '/event/' + eventUrlId });
                window.open(newTabUrl.href, '_blank');
            }
        }
    }

    const clearCart = async () => {
        const eventUrlId = redirectObj.region ? `${redirectObj.country}-${redirectObj.clicked_item_id}` : redirectObj.clicked_item_id
        router.push({ path: `/event/${eventUrlId}` })

        localStorage.removeItem("eventId");
        useEventBookingStore().reset()
    }

    const handleMapClick = () => {
        emit('goToMap', event.value)
    }

    const addFavorite = async () => {
        if (processingToggleFavorite.value) {
            return
        }
        if (userStore.isLoggedIn) {
            let favoriteObj = {
                userId: userStore.user?.id || null,
                eventId: event.value.id,
                country: mainStore.homeCountryCode || null,
                eventLogoUrl: event.value.logoUrl,
                eventName: event.value.name,
                eventTo: event.value.to,
                favourite: !event.value.favourite
            }
            processingToggleFavorite.value = true
            const response = await axios.post(`${process.env.VUE_APP_BASE_URL}interaction/favourites`, favoriteObj)
            processingToggleFavorite.value = false
            if (response.data.responseCode == 200) {
                event.value.favourite = !event.value.favourite
                if (event.value.favourite) {
                    Toast.success(t('toast_translation.add_to_favorite'));
                } else {
                    Toast.warning(t('toast_translation.remove_to_favorite'));
                }
            } else {
                console.log("Error");
            }
        } else {
            Toast.error(t('toast_translation.login_first'));
        }
    }

    onMounted(() => {
        setTimeout(() => mounted.value = true, Math.random() * (500 - 200) + 200)
    })


const __returned__ = { userStore, cartStore, mainStore, Toast, t, get redirectObj() { return redirectObj }, set redirectObj(v) { redirectObj = v }, props, emit, mounted, processingToggleFavorite, confirmClearCartDialogRef, unmarkedFavImage, markedFavImage, startDateTime, bookingsStartDateTime, bookingsOpenDateTime, bookingsEndDateTime, eventEndsDateTime, event, isBookSoon, isBookNow, isTicketsAvailableAtTheDoor, bookButonLabel, isBookingButtonDisplayed, clicked, clearCart, handleMapClick, addFavorite, CardImage, EventDateTime, ConfirmDialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})