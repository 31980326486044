import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DialogComponent"], {
    ref: "resetPasswordDialogRef",
    "hide-footer": "",
    "dialog-color-style": {
				primaryColor: $setup.defaultColors.bodyTXT,
				secondaryColor: $setup.defaultColors.background,
		}
  }, {
    default: _withCtx(() => [
      _createVNode($setup["FormComponent"], {
        ref: "resetPasswordFormRef",
        name: "resetPasswordForm"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FormItemInput"], {
            modelValue: $setup.logged_email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.logged_email) = $event)),
            email: "",
            placeholder: $setup.t('registration_page.email'),
            required: "",
            style: _normalizeStyle({
                '--primary-color': $setup.defaultColors.bodyTXT,
                '--secondary-color': $setup.defaultColors.background,
            })
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "style"]),
          _createElementVNode("button", {
            class: "primary-button",
            style: _normalizeStyle($setup.primaryButtonStyle),
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.resetPasswordMethod()))
          }, _toDisplayString($setup.t('buyer_details.reset_pwd')), 5 /* TEXT, STYLE */)
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dialog-color-style"]))
}