import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, watch, ref, Ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification'
import colours from '@/json/defaultColor.json'
import VFacebookLogin from 'vue-facebook-login-component-next';
import InputDropdown from '@/design-system/InputDropdown.vue';
import PasswordInput from '@/design-system/PasswordInput.vue';
import FormItemInput from '@/design-system/FormItemInput.vue';
import { useUserStore } from '@/store/user-store'
import { useCountriesOptions } from '@/composable/useCountriesOptions';
import FormComponent from '@/design-system/FormComponent.vue';
import { useI18n } from 'vue-i18n';
import LoginForm from './LoginForm.vue';
import HorizontalLine from '@/design-system/HorizontalLine.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignUpLoginForm',
  props: {
    showSignUp: { type: Boolean, required: true },
    isPopup: { type: Boolean, required: false }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();
const userStore = useUserStore();

const props = __props;

// define emits
const emit = __emit;

const firstName = ref('');
const lastName = ref('');
const email = ref('');
const confirm_email = ref('');
const confirm_password = ref('');
const register_password = ref('');
const signInMethod = ref('')
const Profileschecked = ref<string[]>([]);
const selectednewsLetters = ref(true);
const acceptterm_condition = ref(false);
const selectedCountryOption = ref<any>(null); // Adjust type as per actual data structure
const selectedResidencyOption = ref<any>(null); // Adjust type as per actual data structure
const isSignUp = ref<boolean | null>(null);
const googlePhotoUrl = ref<string | null>(null);
const googleId = ref<string | null>(null);
const googleCredential = ref<string | null>(null);
const registrationFormRef: Ref<typeof FormComponent | null> = ref(null)

const showSignUp: ComputedRef<boolean> = computed(() => props.showSignUp);
const isPopup: ComputedRef<boolean> = computed(() => props.isPopup)
const countriesDropdownOptions = computed(() => useCountriesOptions().countriesDropdownOptions);
const isLoggedIn = computed(() => userStore.isLoggedIn);
const isSignUpMode = computed(() => {
    return isSignUp.value !== null ? isSignUp.value : showSignUp.value
});
const incompleteSignUp = computed(() => userStore.incompleteSignUp)
const defaultColors = computed(() => colours.colours)
const primaryButtonStyle = computed(() => ({
  color: defaultColors.value.buttonTXT,
  backgroundColor: defaultColors.value.buttonBG,
}))
const secondaryButtonStyle = computed(() => ({
  color: defaultColors.value.buttonBG,
  backgroundColor: defaultColors.value.buttonTXT,
}))

watch(isLoggedIn, () => {
    fillFormWithLoggedInUserData()
})

onMounted(() => {
    fillFormWithLoggedInUserData()
})


// Function to fill form with logged-in user data
function fillFormWithLoggedInUserData() {
  if (userStore.isLoggedIn) {
    firstName.value = userStore.user.firstName;
    lastName.value = userStore.user.lastName;
    email.value = userStore.user.email;
    confirm_email.value = userStore.user.email;
    selectedCountryOption.value = useCountriesOptions().getCountryOptionById(userStore.user?.nationality);
    selectedResidencyOption.value = useCountriesOptions().getCountryOptionById(userStore.user?.residency);
  }
}

// Function to handle checkbox change for terms and conditions
function handleCheckboxChangeTerm() {
  if (!acceptterm_condition.value) {
    const toast = useToast();
    toast.error('You must check the Term and conditions');
  }
}

// Function to handle Google login callback
async function googleLoginCallback(googleUser: any) {
  try {
    const user = await userStore.googleLogin(googleUser);
    return user
  } catch (error) {
    const Toast = useToast();
    Toast.error(t('toast_translation.error_mesg_signInUSer'));
  }
}

function switchToSignUp() {
  isSignUp.value = true
}

function switchToLogin() {
  isSignUp.value = false
}

// Function to close modal and emit close event
function closeForm() {
  emit('close');
}

// Function to sign up using Tango
async function SingUpWithTango() {
  const isValid = registrationFormRef.value?.validate();
  const Toast = useToast();
  if (!isValid) {
    Toast.error(t('toast_translation.fill_the_data'));
  } else {
    try {
      const register_data = {
        'name': firstName.value,
        'surname': lastName.value,
        'email': email.value,
        'username': register_password.value ? register_password.value : googleId.value,
        'birthCountry': selectedCountryOption.value?.id,
        'profile': 'DANCER',
        'resCountry': selectedResidencyOption.value.id,
        'role': null,
        'profiles': Profileschecked.value,
        'signInMethod': signInMethod.value,
        'newsLatter': selectednewsLetters.value,
        'photoUrl': googlePhotoUrl.value || null
      }
      await userStore.register(register_data);
      // condition manage if register with google direct login
      if (googleId.value) {
        const user_data = {
          'signInMethod': 'GOOGLE',
          'idToken': googleCredential.value,
          'providerId': 'google.com',
        }
        await userStore.login(user_data);
      } else {
        console.log('Do login because is user/pass login');
        // login method
        let user_data_login = {
          'email': email.value,
          'password': register_password.value,
        }
        await userStore.login(user_data_login);
      }
    } catch (error: any) {
      if (error.response.data.responseCode === 303) {
        console.error(error.response.data.message);
        Toast.error(t('toast_translation.error_email_exists'));
      } else {
        console.error(error.message);
        Toast.error(t('toast_translation.error_mesg'));
      }
    }
  }
}


const __returned__ = { t, userStore, props, emit, firstName, lastName, email, confirm_email, confirm_password, register_password, signInMethod, Profileschecked, selectednewsLetters, acceptterm_condition, selectedCountryOption, selectedResidencyOption, isSignUp, googlePhotoUrl, googleId, googleCredential, registrationFormRef, showSignUp, isPopup, countriesDropdownOptions, isLoggedIn, isSignUpMode, incompleteSignUp, defaultColors, primaryButtonStyle, secondaryButtonStyle, fillFormWithLoggedInUserData, handleCheckboxChangeTerm, googleLoginCallback, switchToSignUp, switchToLogin, closeForm, SingUpWithTango, InputDropdown, PasswordInput, FormItemInput, FormComponent, LoginForm, HorizontalLine }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})