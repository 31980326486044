import { defineStore } from 'pinia'
import { ref, computed, Ref } from 'vue'
import ProfilesService, { ProfilesTypesEnum } from '@/services/profiles.service'
import { ProfileModel } from '@/model/ProfileModel'

export const useProfileStore = defineStore('profiles', () => {
  const loading = ref(false)
  const error = ref('')
  const profiles: Ref<Array<ProfileModel>> = ref([])

  const isEmptyProfiles = computed(() => !!profiles.value.length)

  async function fetchProfiles(type: ProfilesTypesEnum) {
    loading.value = true
    try {
      const response = await ProfilesService.fetchProfiles(type)
      profiles.value.length = 0
      response.forEach((item: any) => profiles.value.push(item))
      loading.value = false
      return response
    } catch (e) {
      error.value = 'Sorry, there was an error trying to load profiles'
      loading.value = false
    }
    
  }

  return { loading, profiles, isEmptyProfiles, fetchProfiles }
})