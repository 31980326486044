import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, StyleValue, ref, inject, onUnmounted, onMounted } from 'vue'
import FormItem from './FormItem.vue'
import { useI18n } from 'vue-i18n';

export type OptionProps = {
  id: string;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormItemRadioButton',
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: true },
    required: { type: Boolean, required: true },
    disabled: { type: Boolean, required: false },
    options: { type: Array, required: true },
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true }
  },
  emits: ["update:modelValue", "change"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { t } = useI18n();

const { addFormItem, removeFormItem } = inject('FormComponent', () => {}) as any

const props = __props;

const visited = ref(false)
const forcedValidation = ref(false)
const errorMessage = ref('')
const focused = ref(false)
const itemId = ref(null)

// define emits
const emit = __emit;

onMounted(() => {
  if (addFormItem) {
    itemId.value = addFormItem({
      validate: () => {
        forcedValidation.value = true
        return validate()
      }
    })
  }
})

onUnmounted(() => {
  if (removeFormItem) {
    removeFormItem(itemId.value)
  }
})

// define proxyValue for modelValue to emit update:modelValue
// const value: ComputedRef<string> = computed(() => props.modelValue);

const value = computed({
  get () {
    return props.modelValue
  },
  set (val) {
    emit('update:modelValue', val)
  }
})
const isRequired: ComputedRef<boolean> = computed(() => props.required);

const options: ComputedRef<T[]> = computed(() => props.options);

function handleInputChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement)?.value)
  emit('change', (event.target as HTMLInputElement)?.value)
  validate()
}


function validate () {
  errorMessage.value = ''
  if (!value.value && isRequired.value) {
    errorMessage.value += t('ThisFieldIsRequired')
  }
  return !errorMessage.value
}

function onBlur() {
  visited.value = true
  focused.value = false
  validate()
}

function onFocus() {
  focused.value = true
}

__expose({
  validate: () => {
    forcedValidation.value = true
    validate()
  }
})


const __returned__ = { t, addFormItem, removeFormItem, props, visited, forcedValidation, errorMessage, focused, itemId, emit, value, isRequired, options, handleInputChange, validate, onBlur, onFocus, FormItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})