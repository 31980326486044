import { withModifiers as _withModifiers, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-item-password--container" }
const _hoisted_2 = ["value", "type", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["FormItem"], {
    style: _normalizeStyle($props.style),
    "is-required": $setup.isRequired,
    placeholder: $props.placeholder,
    "error-message": ($setup.forcedValidation || $setup.visited) && $setup.errorMessage || '',
    "hide-label": !($setup.focused && !$setup.hasValue || $setup.hasValue),
    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleFormItemClick()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          ref: "inputRef",
          value: $setup.value,
          type: $setup.inputType,
          placeholder: $setup.focused ? '' : `${$props.placeholder}${ $setup.isRequired ? ' *' : ''}`,
          disabled: $props.disabled,
          style: _normalizeStyle($props.style),
          class: "form-item-input",
          onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["space"])),
          onBlur: $setup.onBlur,
          onFocus: $setup.onFocus,
          onInput: $setup.handleInputChange
        }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_2),
        (!$props.disabled && $setup.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "eye-icon--button",
              onClick: $setup.toggleShow
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["fas eye-icon", { 'fa-eye-slash': !$setup.isShowPassword, 'fa-eye': $setup.isShowPassword }])
              }, null, 2 /* CLASS */)
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "is-required", "placeholder", "error-message", "hide-label"]))
}