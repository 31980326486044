import { defineComponent as _defineComponent } from 'vue'
import { computed, StyleValue } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalLine',
  props: {
    text: { type: String, required: true },
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const text = computed(() => props.text)

const __returned__ = { props, text }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})