import { defineComponent as _defineComponent } from 'vue'
import CarouselComponent from '@/components/LandingPage/Carousel/CarouselComponent.vue'
    import Events from '@/components/LandingPage/Event/Events.vue'
    import Categories from '@/components/LandingPage/Event/Categories.vue'
    import GoogleMap from '@/components/LandingPage/GoogleMap.vue'

    import {
        fetchAllEvents,
        Sections
    } from '@/services/events.service'
    import { computed, onMounted, ref, watch } from 'vue'
    import { EventsData } from '@/model/EventsData'
    import { EventsRequest } from '@/model/events/EventsRequest'
    import { RegionFilter } from '@/interfaces'
    import Filters from '@/components/LandingPage/Carousel/Filters.vue'
    import moment, { Moment } from 'moment'
    import { useEventStore } from '@/store/event-store'
    import { useI18n } from 'vue-i18n'
    import { useUserStore } from '@/store/user-store'
    import { useMainStore } from '@/store/main-store'
import { CampaignModel } from '@/model/CampaignModel'
    
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    location: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

    const userStore = useUserStore()
    const mainStore = useMainStore()
    const eventStore = useEventStore()
    const { t, locale: i18nLocale  } = useI18n();

    const props = __props

    const location = computed <string> (() => props.location || '')

    const regularEvents = ref < EventsData[] > ([])
    const sponsoredEvents = ref < CampaignModel[] > ([])
    const sectionsById = ref < Map<string, EventsData[]> | null > (null)
    const sections = ref < Sections[] > ([])
    const highlightedEvents = ref < EventsData[] > ([])
    const begginerEvents = ref < EventsData[] > ([])
    const newEvents = ref < EventsData[] > ([])
    const isLoading = ref < boolean > (true)
    const isProcessing = ref < boolean > (false)
    const isInternational = ref < boolean > (true)

    const monthFilter = ref < string > ('')
    const countryFilter = ref < string > ('')
    const regionFilter = ref < string > ('')

    const carouselRef = ref <any> (null)

    const carouselHeight = ref <number>(0)
    // const carouselHeight = computed(() => carouselRef.value?.getBoundingClientRect().height / 2 || 0)

    const payload = ref < EventsRequest > ({
        month: monthFilter.value,
        country: countryFilter.value,
        region: regionFilter.value,
        userId: userStore.user?.id
    })

    const getAllEvents = async () => {
        console.log('getAllEvents(): payload = ', payload.value)
        isProcessing.value = true
        const data = await fetchAllEvents(payload.value); //international
        regularEvents.value = data.regularEvents;
        sponsoredEvents.value = data.campaigns;
        sectionsById.value = data.sectionsById;
        sections.value = data.sections
        isProcessing.value = false
    }

    async function regionChanged(filter: RegionFilter) {
        payload.value.country = filter.country
        payload.value.region = filter.region

        isInternational.value = filter.country === 'Internacional'

        await  getAllEvents()
        updateCarouselHeight()
        isLoading.value = false
    }

    async function monthChanged(month: string, type: string) {

        if (month == 'Invalid date') {
            payload.value.month = ''
            isInternational.value = true;
            await getAllEvents()
            updateCarouselHeight()
            isLoading.value = false
        } else {
            payload.value.month = month
            if (type === 'month') {
                await getAllEvents()
                updateCarouselHeight()
                isLoading.value = false
            }
        }
    }

    function dayChanged() {
        isInternational.value = false;
    }

    onMounted(async () => {
        eventStore.setEmbeddedMode(false)
        if (!location.value) {
            // await Promise.all([getAllEvents(), getHighlightedEvents()])
            isLoading.value = true
            await getAllEvents()
            isLoading.value = false
            updateCarouselHeight()
        }
    })

    function updateCarouselHeight() {
        carouselHeight.value = carouselRef.value?.getBoundingClientRect().height || 0
        console.log('Carousel height: ', carouselHeight.value)
    }

    const selectedEventInMap = ref(null)

    const dayInString = computed < string > (() => {
        if (isInternational.value) {
            return t('internationalEventLabel1');
        } else {
            if (mainStore.selectedDay) {
                const time = mainStore.selectedDay
                const day = t(time.format('dddd'))
                const dateFormat = i18nLocale.value === 'en' ? 'Do' : 'D'
                const date = t(time.format(dateFormat))
                const month = t(time.format('MMMM'))
                const todayOrEmpty = `${moment().day() === time.day() ? `${t('Today')}, ` : ''}`
                return `${t('internationalEventLabel')} - ${todayOrEmpty} ${day}, ${date} ${t('of')} ${month}`
            } else {
                return t('internationalEventLabel1');; // Never used?
            }
        }
    })

    const filteredSectionById = computed < Map< string, EventsData[] > > (() => {
        const filteredSectionByIdMap = new Map< string, EventsData[] >()
        sectionsById.value?.forEach((events: EventsData[], sectionId: string) => {
            let filteredEvents: EventsData[] = []
            if (mainStore.eventSearchQuery == '') {
                filteredEvents = [...events]
            } else {
                filteredEvents = events.filter((item: EventsData) =>
                    item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())
                )
            }
            if (!isInternational.value) {
                filteredEvents = filteredEvents?.filter((item) => {
                    const eventDate = moment.unix(item.from.seconds)
                    return moment(mainStore.selectedDay).isSame(eventDate, 'day')
                })
            }

            filteredSectionByIdMap.set(sectionId, filteredEvents)
        });
        return filteredSectionByIdMap
    })

    const filteredRegularEvents = computed < EventsData[] > (() => {
        let events: EventsData[] = regularEvents.value as EventsData[];
        if ((isInternational.value) && (mainStore.eventSearchQuery == '')) {
            return events
        }

        if (mainStore.eventSearchQuery !== '') {
            events = events?.filter((item: EventsData) =>
                item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())
            )
        }

        return events?.filter((item) => {
            const eventDate = moment.unix(item.from.seconds)
            return moment(mainStore.selectedDay).isSame(eventDate, 'day')
        })
    })

    const favoriteRegularEvents = computed < EventsData[] > (() => {
        return regularEvents.value?.filter((item: EventsData) => {
                return item.favourite === true
            }) as EventsData[]
    })

    //add to favorites
    const filteredFavoriteRegularEvents = computed < EventsData[] > (() => {
        if (mainStore.eventSearchQuery !== '')
            return favoriteRegularEvents.value?.filter((item: EventsData) => item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())) as EventsData[]
        return favoriteRegularEvents.value as EventsData[]
    })

    const filteredHighlightedEvents = computed < EventsData[] > (() => {
        if (mainStore.eventSearchQuery !== '')
            return highlightedEvents.value?.filter((item: EventsData) => item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())) as EventsData[]
        return highlightedEvents.value as EventsData[]
    })

    const filteredBegginnerEvents = computed < EventsData[] > (() => {
        if (mainStore.eventSearchQuery !== '')
            return begginerEvents.value?.filter((item: EventsData) => item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())) as EventsData[]
        return begginerEvents.value as EventsData[]
    })

    const filteredNewEvents = computed < EventsData[] > (() => {
        if (mainStore.eventSearchQuery !== '')
            return newEvents.value?.filter((item: EventsData) =>
                item.name.toLowerCase().includes(mainStore.eventSearchQuery.toLowerCase())
            ) as EventsData[]

        return newEvents.value as EventsData[]
    })

    const mapEvents = computed(() => {
        return [...(filteredRegularEvents.value || []), ...(filteredHighlightedEvents.value || []), ...(filteredBegginnerEvents.value || []), ...(filteredNewEvents.value || []), ...(filteredFavoriteRegularEvents.value || []) ]
    })

    watch(userStore.isLoggedIn, async () => {
        await getAllEvents()
        updateCarouselHeight()
    });

    const mapRefEl = ref<HTMLElement | null>(null)

    function handleGoToMapEvent(event: any) {
        console.log('Go to Map: ', event)
        selectedEventInMap.value = event

        // Calculate the desired scroll position (adjust the value as needed)
        const desiredPosition = document.body.scrollHeight - 900;
        // Scroll to the desired position
        window.scrollTo(0, desiredPosition);
    }

    const filteredItems = computed(() => {
        return ['SOCIAL', ...sections.value].reduce((acc, curr) => [...acc, ...(filteredSectionById.value.get(curr) || [])], new Array<EventsData>())
    })

const __returned__ = { userStore, mainStore, eventStore, t, i18nLocale, props, location, regularEvents, sponsoredEvents, sectionsById, sections, highlightedEvents, begginerEvents, newEvents, isLoading, isProcessing, isInternational, monthFilter, countryFilter, regionFilter, carouselRef, carouselHeight, payload, getAllEvents, regionChanged, monthChanged, dayChanged, updateCarouselHeight, selectedEventInMap, dayInString, filteredSectionById, filteredRegularEvents, favoriteRegularEvents, filteredFavoriteRegularEvents, filteredHighlightedEvents, filteredBegginnerEvents, filteredNewEvents, mapEvents, mapRefEl, handleGoToMapEvent, filteredItems, CarouselComponent, Events, GoogleMap, Filters }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})