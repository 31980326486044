import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "container event-grid" }
const _hoisted_3 = { class: "picture" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-content" }
const _hoisted_6 = {
  class: "card-title",
  style: {"height":"24px"}
}
const _hoisted_7 = { class: "card-content" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "header-action-title" }
const _hoisted_12 = { class: "card-content" }
const _hoisted_13 = { class: "contact-media-list" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["href", "title"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["href", "title"]
const _hoisted_18 = { key: 2 }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "hide-on-mobile" }
const _hoisted_21 = { key: 3 }
const _hoisted_22 = ["href"]
const _hoisted_23 = { class: "hide-on-mobile" }
const _hoisted_24 = { key: 4 }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "hide-on-mobile" }
const _hoisted_27 = { class: "card-title" }
const _hoisted_28 = { class: "header-action-title" }
const _hoisted_29 = { class: "card-content" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "card-title" }
const _hoisted_32 = { class: "card-content" }
const _hoisted_33 = { class: "card-title" }
const _hoisted_34 = { class: "card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.event)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["CoverImage"], {
              image: $setup.event.coverUrl,
              title: 'Event cover image'
            }, null, 8 /* PROPS */, ["image"])
          ]),
          _createElementVNode("div", {
            class: "time card-grid",
            style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
          }, [
            _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "card-icon" }, [
              _createElementVNode("i", { class: "fa-solid fa-clock" })
            ], -1 /* HOISTED */)),
            _createElementVNode("h5", _hoisted_4, _toDisplayString($setup.t('event_details.time')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["EventDateTime"], {
                date: $setup.fromDate,
                "end-date": $setup.toDate
              }, null, 8 /* PROPS */, ["date", "end-date"])
            ])
          ], 4 /* STYLE */),
          _createElementVNode("div", {
            class: "location card-grid",
            style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("h5", {
              class: "card-icon",
              style: {"height":"24px"}
            }, [
              _createElementVNode("i", { class: "fa-solid fa-location-dot" })
            ], -1 /* HOISTED */)),
            _createElementVNode("h5", _hoisted_6, _toDisplayString($setup.t('event_details.location')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_7, [
              ($setup.event.location)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: `https://www.google.com/maps/search/?api=1&query=${$setup.event.location.latitude}%2C${$setup.event.location.longitude}`,
                    style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
                  }, _toDisplayString($setup.event?.address), 13 /* TEXT, STYLE, PROPS */, _hoisted_8))
                : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($setup.event?.address), 1 /* TEXT */))
            ])
          ], 4 /* STYLE */),
          _createElementVNode("div", {
            class: "contact card-grid",
            style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
          }, [
            _cache[7] || (_cache[7] = _createElementVNode("h5", { class: "card-icon" }, [
              _createElementVNode("i", { class: "fa-solid fa-message" })
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h5", _hoisted_11, _toDisplayString($setup.t('contact_us')), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("ul", _hoisted_13, [
                ($setup.eventStore.instagramUrl)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                      _createElementVNode("a", {
                        href: $setup.eventStore.instagramUrl,
                        title: $setup.eventStore.instagramUrl,
                        target: "_blank",
                        rel: "noopener noreferrer nofollow",
                        style: _normalizeStyle({'color': $setup.eventStore.eventStyle.selectedTXT})
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("i", { class: "fa-brands fa-instagram" }, null, -1 /* HOISTED */)
                      ]), 12 /* STYLE, PROPS */, _hoisted_15)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventStore.facebookUrl)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                      _createElementVNode("a", {
                        href: $setup.eventStore.facebookUrl,
                        title: $setup.eventStore.facebookUrl,
                        target: "_blank",
                        rel: "noopener noreferrer nofollow",
                        style: _normalizeStyle({'color': $setup.eventStore.eventStyle.selectedTXT})
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "fa-brands fa-facebook" }, null, -1 /* HOISTED */)
                      ]), 12 /* STYLE, PROPS */, _hoisted_17)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventStore.linkUrl)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                      _createElementVNode("a", {
                        href: $setup.eventStore.linkUrl,
                        target: "_blank",
                        rel: "noopener noreferrer nofollow",
                        style: _normalizeStyle({'color': $setup.eventStore.eventStyle.selectedTXT})
                      }, [
                        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa-solid fa-globe" }, null, -1 /* HOISTED */)),
                        _createElementVNode("span", _hoisted_20, _toDisplayString($setup.eventStore.linkLabel), 1 /* TEXT */)
                      ], 12 /* STYLE, PROPS */, _hoisted_19)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventStore.event.email)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_21, [
                      _createElementVNode("a", {
                        href: 'mailto:'+$setup.eventStore.event.email,
                        style: _normalizeStyle({'color': $setup.eventStore.eventStyle.selectedTXT})
                      }, [
                        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-regular fa-envelope" }, null, -1 /* HOISTED */)),
                        _createElementVNode("span", _hoisted_23, _toDisplayString($setup.eventStore.event.email), 1 /* TEXT */)
                      ], 12 /* STYLE, PROPS */, _hoisted_22)
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.eventStore.event.phone)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_24, [
                      _createElementVNode("a", {
                        href: `tel:${$setup.eventStore.event.phone}`,
                        style: _normalizeStyle({'color': $setup.eventStore.eventStyle.selectedTXT})
                      }, [
                        _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa-solid fa-phone" }, null, -1 /* HOISTED */)),
                        _createElementVNode("span", _hoisted_26, _toDisplayString($setup.eventStore.event.phone), 1 /* TEXT */)
                      ], 12 /* STYLE, PROPS */, _hoisted_25)
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ], 4 /* STYLE */),
          _createElementVNode("div", {
            class: "details card-grid",
            style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("h5", { class: "card-icon" }, [
              _createElementVNode("i", { class: "fa-solid fa-info-circle" })
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("h5", _hoisted_28, _toDisplayString($setup.t('event_details.details')), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", {
                class: "newlineStringStyle",
                innerHTML: $setup.sanitizeContent(($setup.eventStore.showDescriptionInEnglish && $setup.event?.descriptionENG) ? $setup.event?.descriptionENG : $setup.event?.description)
              }, null, 8 /* PROPS */, _hoisted_30)
            ])
          ], 4 /* STYLE */),
          ($setup.shouldDisplayTicketsSection)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "tickets card-grid",
                style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("h5", { class: "card-icon" }, [
                  _createElementVNode("i", { class: "fa-solid fa-id-card" })
                ], -1 /* HOISTED */)),
                _createElementVNode("h5", _hoisted_31, _toDisplayString($setup.t('event_details.tickets')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_32, [
                  _createVNode($setup["BookButton"], {
                    "event-url-id": $setup.eventUrlId,
                    style: _normalizeStyle({'color': $setup.eventStore.eventStyle.buttonTXT ,  'background-color': $setup.eventStore.eventStyle.buttonBG }),
                    event: $setup.event
                  }, null, 8 /* PROPS */, ["event-url-id", "style", "event"])
                ])
              ], 4 /* STYLE */))
            : _createCommentVNode("v-if", true),
          ($setup.organisersDetails?.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "organizers card-grid",
                style: _normalizeStyle($setup.eventCardColorAndBackgroundStyle)
              }, [
                _cache[10] || (_cache[10] = _createElementVNode("h5", { class: "card-icon" }, [
                  _createElementVNode("i", { class: "fa-solid fa-users" })
                ], -1 /* HOISTED */)),
                _createElementVNode("h5", _hoisted_33, _toDisplayString($setup.t('event_details.organizers')), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_34, [
                  _createVNode($setup["CardContainer"], null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.organisersDetails, (organizer, index) => {
                        return (_openBlock(), _createBlock($setup["SmallProfileCard"], {
                          key: index,
                          style: _normalizeStyle($setup.styleObject),
                          "cover-image-url": organizer?.pictureUrl,
                          name: organizer.fullName,
                          "country-flag-image-url": require(`@/assets/flags/${organizer.flag}.png`),
                          "country-name": organizer.country_full_name,
                          "text-color": $setup.eventStore.eventStyle.bodyTXT
                        }, null, 8 /* PROPS */, ["style", "cover-image-url", "name", "country-flag-image-url", "country-name", "text-color"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ], 4 /* STYLE */))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}