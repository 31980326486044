import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = {
  key: 0,
  class: "country-label"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg",
  alt: "",
  class: "country-flag"
}
const _hoisted_5 = {
  key: 1,
  class: "cover-image"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 6,
  class: "btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.countryFlagImageUrl || $setup.countryName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($setup.countryFlagImageUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $setup.countryFlagImageUrl,
                class: "country-flag"
              }, null, 8 /* PROPS */, _hoisted_3))
            : (_openBlock(), _createElementBlock("img", _hoisted_4)),
          _createElementVNode("span", {
            style: _normalizeStyle({'color': $setup.textColor})
          }, _toDisplayString($setup.countryName), 5 /* TEXT, STYLE */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.coverImageUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode($setup["CoverImage"], {
            image: $setup.coverImageUrl,
            title: 'Profile cover image',
            height: '200px',
            delayed: ""
          }, null, 8 /* PROPS */, ["image"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.avatarImageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: $setup.avatarImageUrl,
          alt: "Profile Image",
          class: "profile-img"
        }, null, 8 /* PROPS */, _hoisted_6))
      : _createCommentVNode("v-if", true),
    ($setup.profileId)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          style: _normalizeStyle({'color': $setup.textColor}),
          class: "name cursor-pointer",
          onClick: $setup.clickHandler
        }, _toDisplayString($setup.name), 5 /* TEXT, STYLE */))
      : (_openBlock(), _createElementBlock("div", {
          key: 4,
          style: _normalizeStyle({'color': $setup.textColor}),
          class: "name"
        }, _toDisplayString($setup.name), 5 /* TEXT, STYLE */)),
    ($setup.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 5,
          style: _normalizeStyle({'color': $setup.textColor}),
          class: "description"
        }, _toDisplayString($setup.description), 5 /* TEXT, STYLE */))
      : _createCommentVNode("v-if", true),
    ($setup.showFollowButton)
      ? (_openBlock(), _createElementBlock("button", _hoisted_7, "Follow"))
      : _createCommentVNode("v-if", true)
  ]))
}