import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "navbar-left" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "main-one p-1" }
const _hoisted_4 = { class: "navbar-middle" }
const _hoisted_5 = {
  key: 0,
  class: "total-box"
}
const _hoisted_6 = { class: "total-value" }
const _hoisted_7 = { style: {"margin-right":".5rem"} }
const _hoisted_8 = {
  key: 1,
  class: "sessiontime-slot"
}
const _hoisted_9 = { class: "navbar-right" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "main-one p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "navbar-container",
    style: _normalizeStyle($setup.headerStyle)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!$setup.prevNavigationHidden)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn",
            disabled: $setup.prevNavigationDisabled || $setup.eventBookingStore.isLoading,
            style: _normalizeStyle($setup.buttonStyle),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.prev()))
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("i", {
              class: "fa fa-arrow-left",
              "aria-hidden": "true"
            }, null, -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_3, _toDisplayString($setup.t("button.prev_btn")), 1 /* TEXT */)
          ], 12 /* STYLE, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!$setup.hideTotal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString($setup.cartStore.getCurrencySymbol), 1 /* TEXT */),
              _createTextVNode(" " + _toDisplayString($setup.cartStore.getCartTotal), 1 /* TEXT */)
            ]),
            ($setup.cartStore.availableCurrencies.length > 1)
              ? (_openBlock(), _createBlock($setup["DropdownComponent"], {
                  key: 0,
                  modelValue: $setup.selectedCurrency,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedCurrency) = $event)),
                  options: $setup.cartStore.availableCurrencies,
                  class: "currency-selector",
                  color: $setup.eventStore.eventStyle.buttonTXT,
                  "background-color": $setup.eventStore.eventStyle.buttonBG,
                  "selected-color": $setup.eventStore.eventStyle.selectedTXT,
                  disabled: $setup.editCurrencyDisabled,
                  padding: "",
                  onChange: $setup.onCurrencyChange
                }, null, 8 /* PROPS */, ["modelValue", "options", "color", "background-color", "selected-color", "disabled"]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.sessionTime)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString($setup.sessionTime), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (!$setup.nextNavigationHidden)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn right-nxt-btn",
            disabled: $setup.nextNavigationDisabled || $setup.eventBookingStore.isLoading,
            style: _normalizeStyle($setup.buttonStyle),
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.next()))
          }, [
            _createElementVNode("span", _hoisted_11, _toDisplayString($setup.t("button.next")), 1 /* TEXT */),
            _cache[4] || (_cache[4] = _createElementVNode("i", {
              class: "fa fa-arrow-right",
              "aria-hidden": "true"
            }, null, -1 /* HOISTED */))
          ], 12 /* STYLE, PROPS */, _hoisted_10))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
    ])
  ], 4 /* STYLE */))
}