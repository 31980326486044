import { defineStore } from 'pinia'
import { ref, computed, ComputedRef, Ref } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/store/user-store'
import moment from 'moment'
import colours from '@/json/defaultColor.json'
import { useMainStore } from '@/store/main-store'

export const useEventStore = defineStore('event', () => {
  const event: Ref<any> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const embeddedEventMode: Ref<boolean> = ref(false)

  const isLoading = computed(() => loading.value)
  const getRegistrationForm = computed(() => {
    return (event.value?.registrationForm || [])
      .filter((item: any) => !item.disabled) // Disabled items (hidden) are filtered
      .sort((a: any, b: any) => a.order - b.order)
  })

  const savedRegistrationFormAnswersById = computed(() => {
    const userStore = useUserStore()
    if (!userStore.isLoggedIn) {
      return {}
    }
    const answers = event.value?.extendedSchedule?.answers || []
    return answers.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr.answer
        return acc 
    }, {})
  })

  const mandatoryQuestions = computed(() => {
    return getRegistrationForm.value.filter((item: any) => item.mandatory)
  })

  const mandatoryQuestionsById = computed(() => {
    return mandatoryQuestions.value.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr
        return acc
    }, {})
  })

  const questionsById = computed(() => {
    return getRegistrationForm.value.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr
        return acc
    }, {})
  })

  const getRole = computed(() => event.value.role || 'NONE')
  const eventUrlId  = computed(() => event.value?.region ? `${event.value?.country}-${event.value?.id}` : `${event.value?.id}`)

  const from = computed(() => moment.unix(event.value.from?.seconds).format("DD/MM/YYYY"))
  const to = computed(() => moment.unix(event.value.to?.seconds).format("DD/MM/YYYY"))
  const bookingShowStatus = computed(() => event.value.show)
  const isBookingEnabled = computed(() => {
    const today = Math.floor(Date.now() / 1000)
    const isBetweenRegistrationTime = (event.value.open?.seconds || Infinity) <= today && today < (event.value.close?.seconds || 0)
    return isBetweenRegistrationTime && event.value.process != 'LISTED'
  })
  const isEmbeddedEventMode: ComputedRef<boolean> = computed(() => { // FIX THIS
    return embeddedEventMode.value
  })

  const eventStyle = computed(() => ({
    headerBG: event.value?.headerBG ? '#' + event.value?.headerBG?.substring(4) : colours.colours.headerBG,
    headerTXT: event.value?.headerTXT ? '#' + event.value?.headerTXT?.substring(4) : colours.colours.headerTXT,
    buttonTXT: event.value?.buttonTXT ? '#' + event.value?.buttonTXT?.substring(4) : colours.colours.buttonTXT,
    buttonBG: event.value?.buttonBG ? '#' + event.value?.buttonBG?.substring(4) : colours.colours.buttonBG,
    bodyBG: event.value?.bodyBG ? '#' + event.value?.bodyBG?.substring(4) : colours.colours.bodyBG,
    bodyTAB: event.value?.bodyTAB ? '#' + event.value?.bodyTAB?.substring(4) : colours.colours.bodyTAB,
    bodyTXT: event.value?.bodyTXT ? '#' + event.value?.bodyTXT?.substring(4) : colours.colours.bodyTXT,
    selectedTXT: event.value?.selectedTXT ? '#' + event.value?.selectedTXT?.substring(4) : colours.colours.selectedTXT,
    background: event.value?.background ? '#' + event.value?.background?.substring(4) : colours.colours.background,
  }))

  const bodyTextColorStyle = computed(() => ({ 'color': eventStyle.value.bodyTXT }))
  const buttonTextColorStyle = computed(() => ({ 'color': eventStyle.value.buttonTXT }))
  const buttonBackgroundColorStyle = computed(() => ({ 'background-color': eventStyle.value.buttonBG }))
  const selectedButtonTextColorStyle = computed(() => ({ 'color': eventStyle.value.buttonBG }))
  const selectedButtonBackgroundColorStyle = computed(() => ({ 'background-color': eventStyle.value.buttonTXT }))
  const borderColorStyle = computed(() => ({ 'border-color': eventStyle.value.bodyTXT }))
  const selectedBorderColorStyle = computed(() => ({ 'border-color': eventStyle.value.selectedTXT }))
  const buttonStyle = computed(() => ({
    ...buttonTextColorStyle.value,
    ...buttonBackgroundColorStyle.value,
  }))
  const selectedButtonStyle = computed(() => ({
    ...buttonTextColorStyle.value,
    ...buttonBackgroundColorStyle.value,
  }))

  const coverUrl = computed(() => event.value.coverUrl || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg')
  const logoUrl = computed(() => event.value.logoUrl || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg')

  const artists = computed(() => event.value.extendedSchedule?.performers || [])
  const djs = computed(() => event.value.extendedSchedule?.djs || [])
  const programme = computed(() => event.value.extendedSchedule?.programme || [])
  const visiblePerformers = computed(() => {
    return artists.value.filter((performer: any) => {
        return performer.show == true
    })
  })
  const visibleDjs = computed(() => {
      return djs.value.filter((dj: any) => {
          return dj.show == true
      })
  })

  const userPaymentStatus = computed(() => event.value.extendedSchedule?.status)
  const getEventIdRequestBody = computed(() => {
    const eventCountry = event.value.country
    const eventRegion = event.value.region
    const eventId = {
            eventId: event.value.id,
            ...(eventCountry && eventRegion && { country: eventCountry, region: eventRegion }),
    }
    return eventId
  })

  const showDescriptionInEnglish = computed(() => {
    const mainStore = useMainStore()
    const language = mainStore.languageSelected
    return language !== event.value?.locale
  })

  const linkUrl = computed(() => {
    const link = event.value.link;
    if (!link) {
      return null;
    } else {
      return link.includes('https://') ? link : `https://${link}`;
    }
  })
  
  const linkLabel = computed(() => linkUrl.value?.split('https://')[1] || '')
  
  const instagramUrl = computed(() => {
    const instagram = event.value.instagram;
    if (!instagram) {
      return null;
    } else {
      return instagram.includes('https://www.instagram.com/') ? instagram : `https://www.instagram.com/${instagram}`;
    }
  })
  
  const instagramLabel = computed(() => instagramUrl.value?.split('https://www.instagram.com/')[1] || '')
  
  const facebookUrl = computed(() => {
    const facebook = event.value.facebook;
    if (!facebook) {
      return null;
    } else {
      return facebook.includes('https://www.facebook.com/') ? facebook : `https://www.facebook.com/${facebook}`;
    }
  })
  
  const facebookLabel = computed(() => facebookUrl.value?.split('https://www.facebook.com/')[1] || '')

  function setEvent(value: any) {
    event.value= value
    localStorage.setItem('eventId', value?.id);
  }

  function setEmbeddedMode(value: boolean) {
    console.log('Set embedded mode: ', value)
    embeddedEventMode.value = value
  }

  async function loadEvent(id: string, country: string) {
    loading.value = true
    try {
        const userStore = useUserStore()
        const userId = userStore.user?.id
        const params = userId ? { userId } : {};
        const url = `${process.env.VUE_APP_BASE_URL}events/full/${country ? `${country}/` : ''}${id}`;
        const response =  await axios.get(url, { params });
        loading.value = false
        setEvent(response?.data?.results)
        return response;
    } catch (error) {
        loading.value = false
        console.log(error);
    }
  }

  async function reloadEvent() {
    const userStore = useUserStore()
    const id = event.value.id
    const country = event.value.region ? event.value.country : ''
    const userId = userStore.user?.id
    const params = userId ? { userId } : {};
    const url = `${process.env.VUE_APP_BASE_URL}events/full/${country ? `${country}/` : ''}${id}`;
    const response =  await axios.get(url, { params });
    setEvent(response?.data?.results)
    return response?.data?.results;
  }

  async function updateEventColors(colors: any) {
    const eventId = getEventIdRequestBody.value
    const payload = {
        eventId,
        headerBG: colors.headerBG,
        headerTXT: colors.headerTXT,
        bodyBG: colors.bodyBG,
        bodyTXT: colors.bodyTXT,
        bodyTAB: colors.bodyTAB,
        buttonBG: colors.buttonBG,
        buttonTXT: colors.buttonTXT,
        selectedTXT: colors.selectedTXT,
        background: colors.background,
        coverUrl: event.value.coverUrl,
        logoUrl: event.value.logoUrl,
        bannerUrl: event.value.bannerUrl,
        backgroundUrl: event.value.backgroundUrl,
        showTitle: event.value.showTitle,
    }
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}events/visual`, payload)
    if(response.data.responseCode == 200) {
        reloadEvent()
        return response.data
    } else {
        throw new Error('Error saving. Please contact support.')
    }
  }

  return {
    event,
    showDescriptionInEnglish,
    isLoading, from, to, bookingShowStatus, isBookingEnabled, eventStyle, coverUrl, logoUrl, 
    artists, djs, programme, visiblePerformers, visibleDjs,
    linkLabel, linkUrl, facebookLabel, facebookUrl, instagramLabel, instagramUrl,
    buttonStyle,
    userPaymentStatus, getRegistrationForm, savedRegistrationFormAnswersById, getRole, eventUrlId,
    mandatoryQuestions, mandatoryQuestionsById, questionsById,
    isEmbeddedEventMode, getEventIdRequestBody, setEmbeddedMode,
    loadEvent, reloadEvent, updateEventColors,
  }
})