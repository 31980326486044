import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container p-1" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_4 = {
  key: 2,
  class: "review-form-container"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "review-form-item-column" }
const _hoisted_8 = { class: "review-form-item" }
const _hoisted_9 = { class: "form-item-label" }
const _hoisted_10 = { class: "review-form-item" }
const _hoisted_11 = { class: "form-item-label" }
const _hoisted_12 = { class: "review-form-item" }
const _hoisted_13 = { class: "form-item-label" }
const _hoisted_14 = { class: "review-form-item" }
const _hoisted_15 = { class: "form-item-label" }
const _hoisted_16 = { class: "review-form-item" }
const _hoisted_17 = { class: "form-item-label" }
const _hoisted_18 = { class: "review-form-item" }
const _hoisted_19 = { class: "form-item-label" }
const _hoisted_20 = { class: "review-form-item-column" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { class: "review-form-item" }
const _hoisted_23 = { class: "review-form-item-column" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "review-form-item" }
const _hoisted_26 = { class: "form-footer" }
const _hoisted_27 = {
  key: 1,
  class: "submit-error"
}
const _hoisted_28 = {
  key: 2,
  class: "submit-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ((!$setup.formLoaded || $setup.isLoading) && !$setup.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode($setup["PotsLoader"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.errorMessage), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.formLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            ($setup.event)
              ? (_openBlock(), _createElementBlock("header", _hoisted_5, [
                  _createElementVNode("img", {
                    src: $setup.event.image
                  }, null, 8 /* PROPS */, _hoisted_6),
                  _createElementVNode("h1", null, _toDisplayString($setup.event.name), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("h3", null, _toDisplayString($setup.t('review_page.subtitle-feedback-appreciated')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString($setup.t('review_page.location')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.location,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.location) = $event)),
                  name: "location",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString($setup.t('review_page.organisation')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.organisation,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.organisation) = $event)),
                  name: "organisation",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString($setup.t('review_page.level')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.level,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.level) = $event)),
                  name: "level",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString($setup.t('review_page.artist')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.artist,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.artist) = $event)),
                  name: "artist",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("span", _hoisted_17, _toDisplayString($setup.t('review_page.dj')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.dj,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.dj) = $event)),
                  name: "dj",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, _toDisplayString($setup.t('review_page.benefit')) + ": ", 1 /* TEXT */),
                _createVNode($setup["StarComponent"], {
                  modelValue: $setup.benefit,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.benefit) = $event)),
                  name: "benefit",
                  class: "form-item-value"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("span", null, _toDisplayString($setup.t('review_page.comments')) + ": ", 1 /* TEXT */),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.comments) = $event)),
                class: "comments",
                placeholder: $setup.t('review_page.comment_placeholder')
              }, null, 8 /* PROPS */, _hoisted_21), [
                [_vModelText, $setup.comments]
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.share) = $event)),
                type: "checkbox",
                class: "checkbox"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelCheckbox, $setup.share]
              ]),
              _createElementVNode("span", null, _toDisplayString($setup.t('review_page.share')), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("span", null, _toDisplayString($setup.t('review_page.fullname')) + ": ", 1 /* TEXT */),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.name) = $event)),
                type: "text",
                disabled: $setup.anonymous
              }, null, 8 /* PROPS */, _hoisted_24), [
                [_vModelText, $setup.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.anonymous) = $event)),
                type: "checkbox",
                class: "checkbox"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelCheckbox, $setup.anonymous]
              ]),
              _createElementVNode("span", null, _toDisplayString($setup.t('review_page.anonymous')), 1 /* TEXT */)
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("footer", _hoisted_26, [
        ($setup.formLoaded)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "submit-button",
              onClick: _cache[10] || (_cache[10] = ($event: any) => ($setup.submit()))
            }, _toDisplayString($setup.t('review_page.send')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.submitError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString($setup.submitError), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.submitSuccess)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString($setup.submitSuccess), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createVNode($setup["PotsFooter"])
  ], 64 /* STABLE_FRAGMENT */))
}